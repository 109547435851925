import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { toastSuccess, toastError } from 'utils/Toaster';
import { adminAccountStateType } from 'types';
import {
  getAccounts,
  lockAccount,
  unlockAccount,
  deleteAccount,
  updateAccountFeatures,
} from 'services/admin';
import AdminAccounts from '.';

function AdminAccountsContainer(props) {
  const {
    adminAccountState: { busy, accounts },
  } = props;

  useEffect(() => {
    if (!busy) {
      getAccounts();
    }
  }, []);

  const onLockAccount = (accountId) => {
    lockAccount(accountId)
      .then(() => {
        getAccounts();
        toastSuccess(
          'Translation.Feature.Admin.Accounts.LockAccountSuccess.toastLabel',
          'ACCOUNT LOCKED'
        );
      })
      .catch((error) => {
        toastError(
          'Translation.Feature.Admin.Accounts.LockAccountFailed.toastLabel',
          'LOCK ACCOUNT FAILED'
        );
      });
  };

  const onUnlockAccount = (accountId) => {
    unlockAccount(accountId)
      .then(() => {
        getAccounts();
        toastSuccess(
          'Translation.Feature.Admin.Accounts.UnlockAccountSuccess.toastLabel',
          'ACCOUNT UNLOCKED'
        );
      })
      .catch((error) => {
        toastError(
          'Translation.Feature.Admin.Accounts.UnlockAccountFailed.toastLabel',
          'UNLOCK ACCOUNT FAILED'
        );
      });
  };

  const onDeleteAccount = (accountId) => {
    deleteAccount(accountId)
      .then(() => {
        getAccounts();
        toastSuccess(
          'Translation.Feature.Admin.Accounts.DeleteAccountSuccess.toastLabel',
          'ACCOUNT DELETED'
        );
      })
      .catch((error) => {
        toastError(
          'Translation.Feature.Admin.Accounts.DeleteAccountFailed.toastLabel',
          'DELETE ACCOUNT FAILED'
        );
      });
  };

  const onSaveAccountFeatures = (accountId, accountFeatures) => {
    updateAccountFeatures(accountId, accountFeatures)
      .then(() => {
        getAccounts();
        toastSuccess(
          'Translation.Feature.Admin.Accounts.UpdateAccountFeaturesSuccess.toastLabel',
          'ACCOUNT FEATURES UPDATED'
        );
      })
      .catch((error) => {
        toastError(
          'Translation.Feature.Admin.Accounts.UpdateAccountFeaturesFailed.toastLabel',
          'UPDATE ACCOUNT FEATURES FAILED'
        );
      });
  };

  return (
    <AdminAccounts
      accounts={accounts}
      busy={busy}
      onLockAccount={onLockAccount}
      onUnlockAccount={onUnlockAccount}
      onDeleteAccount={onDeleteAccount}
      onSaveAccountFeatures={onSaveAccountFeatures}
    />
  );
}

AdminAccountsContainer.propTypes = {
  adminAccountState: adminAccountStateType.isRequired, // eslint-disable-line react/no-unused-prop-types
};

const mapStateToProps = (state) => ({
  adminAccountState: state.admin.adminAccount,
});

export default connect(mapStateToProps)(AdminAccountsContainer);
