import React from 'react';
import { Navbar } from 'features';
import clsx from 'clsx';
import styles from './asSubRoutePage.module.scss';

const asSubRoutePage = (WrappedComponent, props, fullWidth) => {
  return (
    <div className={styles.root}>
      <Navbar className={styles.navbar} />
      <div className={clsx(styles.subPageContainer, fullWidth && styles.fullWidth)}>
        <WrappedComponent {...props} />
      </div>
    </div>
  );
};

export default asSubRoutePage;
