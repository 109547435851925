import React from 'react';
import clsx from 'clsx';
import { useIntl, FormattedMessage } from 'react-intl';
import { NUMBER_FORMAT } from 'assets';
import { Quote } from 'features';
import {
  quoteType,
  languageType,
  bool,
  string,
  number,
  func,
  arrayOf,
  translationSubjectType,
  translationDocumentType,
} from 'types';
import { H3, S1, Button } from 'components';
import styles from './SelectQuote.module.scss';

function SelectQuote(props) {
  const {
    className,
    quotes,
    busy,
    onLogin,
    onSelectQuote,
    sourceLanguage,
    targetLanguages,
    wordCount,
    translationSubject,
    onGoBack,
    userLoggedIn,
    documents,
  } = props;
  const classNames = clsx(className, styles.root);
  const intl = useIntl();

  const renderQuotes = () => {
    if (busy || quotes.length === 0) {
      return [
        <Quote
          key="3"
          className={styles.quote}
          quote={{ translationType: 'standard' }}
          selectQuote={userLoggedIn}
        />,
        <Quote
          key="1"
          className={styles.quote}
          quote={{ translationType: 'professional' }}
          selectQuote={userLoggedIn}
        />,
        <Quote
          key="2"
          className={styles.quote}
          quote={{ translationType: 'pro express' }}
          selectQuote={userLoggedIn}
        />,
      ];
    }

    const sortedQuotes = [
      quotes.find((q) => q.translationType.toLowerCase() === 'standard'),
      quotes.find((q) => q.translationType.toLowerCase() === 'professional'),
      quotes.find((q) => q.translationType.toLowerCase() === 'pro express'),
    ];

    return sortedQuotes.map((quote) => {
      return (
        <Quote
          key={quote.translationType}
          className={styles.quote}
          quote={quote}
          selectQuote={userLoggedIn}
          onSelectQuote={onSelectQuote}
          showLogin={!userLoggedIn}
          onLogin={onLogin}
        />
      );
    });
  };

  const getSubHeaderTextAndValues = () => {
    const textAndValues = {
      values: {
        b: (...chunks) => <b>{chunks}</b>,
        numberOfDocuments: documents.length,
        wordCount: wordCount ? intl.formatNumber(wordCount, NUMBER_FORMAT) : '#',
        sourceLanguage: intl.formatMessage({ id: sourceLanguage.label }),
        targetLanguages: intl.formatList(
          targetLanguages.map((tl) => intl.formatMessage({ id: tl.label })),
          { type: 'conjunction' }
        ),
      },
    };

    if (translationSubject.value !== '1') {
      textAndValues.values.subject = intl.formatMessage({ id: translationSubject.label });
      if (documents.length !== 1) {
        textAndValues.defaultMessage =
          'Translation of <b>{numberOfDocuments}</b> documents with <b>{wordCount}</b> words and with subject, {subject}, from <b>{sourceLanguage}</b> to <b>{targetLanguages}</b>.';
        textAndValues.id = 'Translation.Feature.OrderSummary.NumberOfDocuments.Plural.label';
      } else {
        textAndValues.defaultMessage =
          'Translation of <b>{numberOfDocuments}</b> document with <b>{wordCount}</b> words and with subject, {subject}, from <b>{sourceLanguage}</b> to <b>{targetLanguages}</b>.';
        textAndValues.id = 'Translation.Feature.OrderSummary.NumberOfDocuments.Singular.label';
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (documents.length !== 1) {
        textAndValues.defaultMessage =
          'Translation of <b>{numberOfDocuments}</b> documents with <b>{wordCount}</b> words from <b>{sourceLanguage}</b> to <b>{targetLanguages}</b>.';
        textAndValues.id =
          'Translation.Feature.OrderSummary.NumberOfDocuments.Plural.WithoutSubject.label';
      } else {
        textAndValues.defaultMessage =
          'Translation of <b>{numberOfDocuments}</b> document with <b>{wordCount}</b> words from <b>{sourceLanguage}</b> to <b>{targetLanguages}</b>.';
        textAndValues.id =
          'Translation.Feature.OrderSummary.NumberOfDocuments.Singular.WithoutSubject.label';
      }
    }

    return textAndValues;
  };

  const subHeaderTextAndValues = getSubHeaderTextAndValues();

  const renderInformationContainer = () => {
    return (
      <div className={styles.informationInnerContainer}>
        <H3 className={styles.header} bold upperCase defaultText="Quote details">
          Translation.Feature.SelectQuote.QuoteDetails.label
        </H3>
        <S1 className={styles.subHeader} translate={false}>
          <FormattedMessage
            id={subHeaderTextAndValues.id}
            defaultMessage={subHeaderTextAndValues.defaultMessage}
            values={subHeaderTextAndValues.values}
          />
        </S1>
        <Button
          className={styles.backButton}
          preLeftArrow
          onClick={onGoBack}
          defaultText="Go back and Edit"
        >
          Translation.Feature.SelectQuote.GoBackAndEdit.Button.label
        </Button>
      </div>
    );
  };

  return (
    <div>
      {renderInformationContainer()}
      <div className={classNames}>{renderQuotes()}</div>
    </div>
  );
}

SelectQuote.propTypes = {
  className: string,
  busy: bool.isRequired,
  quotes: arrayOf(quoteType).isRequired,
  onLogin: func.isRequired,
  onSelectQuote: func.isRequired,
  sourceLanguage: languageType,
  targetLanguages: arrayOf(languageType),
  wordCount: number,
  translationSubject: translationSubjectType,
  onGoBack: func.isRequired,
  userLoggedIn: bool.isRequired,
  documents: arrayOf(translationDocumentType).isRequired,
};

SelectQuote.defaultProps = {
  className: null,
  sourceLanguage: null,
  targetLanguages: null,
  wordCount: null,
  translationSubject: null,
};

export default SelectQuote;
