import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { H3, S1, Link } from 'components';
import { verifyEmail } from 'services/account';
import { number, string, historyType, sessionStateType } from 'types';
import styles from './EmailVerifiedPage.module.scss';

function EmailVerifiedPage(props) {
  const { history, id, verificationKey, sessionState } = props;
  const { userLoggedIn } = sessionState;
  const [emailVerified, setEmailVerified] = useState(null);

  useEffect(() => {
    if (!isNaN(id) && verificationKey) {
      verifyEmail(id, verificationKey)
        .then(() => {
          setEmailVerified(true);
        })
        .catch(error => {
          setEmailVerified(false);
        });
    }
  }, [id, verificationKey]);

  if (!id || isNaN(id) || !verificationKey) {
    history.push('/');
  }

  const onProceedToLogin = () => {
    history.push('/login');
  };

  const onProceedToGetQuote = () => {
    history.push('/get-quotes');
  };

  if (emailVerified === false) {
    history.push('/');
  }
  if (emailVerified === null) {
    return null;
  }

  return (
    <div className={styles.root}>
      <H3 className={styles.header} bold upperCase defaultText="Email verification">
        Translation.Page.EmailVerification.header
      </H3>
      <S1 className={styles.paragraph} defaultText="Thank you for verifying your email address.">
        Translation.Page.EmailVerification.Paragraph.label
      </S1>
      <S1 inline defaultText="Please proceed to">
        Translation.Page.EmailVerification.Paragraph2.label
      </S1>
      &nbsp;
      {userLoggedIn ? (
        <Link className={styles.link} onClick={onProceedToGetQuote} defaultText="Get quote">
          Translation.Page.EmailVerification.GetQuote.link
        </Link>
      ) : (
        <Link className={styles.link} onClick={onProceedToLogin} defaultText="Login">
          Translation.Page.EmailVerification.Login.link
        </Link>
      )}
    </div>
  );
}

const mapStateToProps = state => ({
  sessionState: state.session
});

EmailVerifiedPage.propTypes = {
  history: historyType.isRequired,
  id: number.isRequired,
  verificationKey: string.isRequired,
  sessionState: sessionStateType.isRequired
};

export default connect(mapStateToProps)(EmailVerifiedPage);
