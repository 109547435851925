import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getOrderDetails, changeOrderDocumentStatus } from 'services/admin';
import {
  number,
  string,
  func,
  quoteConfigurationStateType,
  translationItemType,
  arrayOf,
} from 'types';
import { deleteOrderDocument, getQuoteConfiguration, uploadDocument } from 'services/configuration';
import { toastSuccess, toastError } from 'utils/Toaster';
import AdminOrderDetails from '.';

function AdminOrderDetailsContainer(props) {
  const { orderId, className, onClosed, quoteConfiguration, orderStatuses } = props;
  const [orderDetails, setOrderDetails] = useState();

  const deleteDocument = (documentId) => {
    deleteOrderDocument(orderId, documentId)
      .then((response) => {
        getOrderDetails(orderId).then((response) => {
          setOrderDetails(response.data);
        });
        toastSuccess(
          'Translation.Feature.AdminOrderDetails.DeleteDocumentSuccess.toastLabel',
          'DOCUMENT DELETED SUCCESSFULLY'
        );
      })
      .catch((error) => {
        toastError(
          'Translation.Feature.AdminOrderDetails.DeleteDocumentFailed.toastLabel',
          'FAILED TO DELETE DOCUMENT'
        );
      });
  };

  const changeDocumentStatus = ({ orderId, documentId, reviewed }) => {
    changeOrderDocumentStatus(orderId, documentId, reviewed)
      .then((response) => {
        getOrderDetails(orderId).then((response) => {
          setOrderDetails(response.data);
        });
        toastSuccess(
          reviewed
            ? 'Translation.Feature.AdminOrderDetails.MarkDocumentAsUnreviewedSuccess.toastLabel'
            : 'Translation.Feature.AdminOrderDetails.MarkDocumentAsReviewedSuccess.toastLabel',
          reviewed
            ? 'DOCUMENT SUCCESSFULLY MARKED AS UNREVIEWED'
            : 'DOCUMENT SUCCESSFULLY MARKED AS REVIEWED'
        );
      })
      .catch((error) => {
        toastError(
          reviewed
            ? 'Translation.Feature.AdminOrderDetails.MarkDocumentAsUnreviewedFailed.toastLabel'
            : 'Translation.Feature.AdminOrderDetails.MarkDocumentAsReviewedFailed.toastLabel',
          reviewed ? 'FAILED TO MARK DOCUMENT AS UNREVIEWED' : 'FAILED TO MARK DOCUMENT AS REVIEWED'
        );
      });
  };

  const addDocument = (
    addDocumentLanguage,
    addDocumentOriginalFile,
    addDocumentTranslationFile,
    callback
  ) => {
    uploadDocument(
      orderId,
      addDocumentOriginalFile.value,
      addDocumentLanguage.value,
      addDocumentTranslationFile
    )
      .then((response) => {
        getOrderDetails(orderId).then((response) => {
          setOrderDetails(response.data);
        });
        callback(true);
        toastSuccess(
          'Translation.Feature.AdminOrderDetails.UploadDocumentSuccess.toastLabel',
          'DOCUMENT UPLOADED SUCCESSFULLY'
        );
      })
      .catch((error) => {
        callback(false);
        toastError(
          'Translation.Feature.AdminOrderDetails.UploadDocumentFailed.toastLabel',
          'FAILED TO UPLOAD DOCUMENT'
        );
      });
  };

  useEffect(() => {
    if (quoteConfiguration.sourceLanguages.length === 0 && !quoteConfiguration.busy) {
      getQuoteConfiguration();
    }
  }, [quoteConfiguration]);

  useEffect(() => {
    if (orderId) {
      getOrderDetails(orderId).then((response) => {
        setOrderDetails(response.data);
      });
    }
  }, [orderId]);

  const _onClosed = () => {
    setOrderDetails(null);
    onClosed();
  };

  let languages = [];

  if (
    quoteConfiguration &&
    quoteConfiguration.targetLanguages &&
    orderDetails &&
    orderDetails.order
  ) {
    languages =
      quoteConfiguration.targetLanguages.filter(
        (tg) => orderDetails.order.targetLanguages.indexOf(tg.value) !== -1
      ) ?? [];
  }

  return (
    <AdminOrderDetails
      className={className}
      orderDetails={orderDetails}
      orderId={orderId}
      onClosed={_onClosed}
      onDeleteDocument={deleteDocument}
      onChangeDocumentStatus={changeDocumentStatus}
      onAddDocument={addDocument}
      languages={languages}
      orderStatuses={orderStatuses}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    quoteConfiguration: state.configuration.quote,
  };
};

AdminOrderDetailsContainer.propTypes = {
  className: string,
  orderId: number,
  onClosed: func.isRequired,
  quoteConfiguration: quoteConfigurationStateType.isRequired,
  orderStatuses: arrayOf(translationItemType).isRequired,
};

AdminOrderDetailsContainer.defaultProps = {
  className: null,
  orderId: null,
};

export default connect(mapStateToProps)(AdminOrderDetailsContainer);
