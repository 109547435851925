import React from 'react';
import { string, func } from 'types';
import clsx from 'clsx';
import { B1, Button } from 'components';
import styles from './RegisterConfirmation.module.scss';

function RegisterConfirmation(props) {
  const { className, onConfirm } = props;
  const classNames = clsx(className, styles.root);

  return (
    <div className={classNames}>
      <B1 defaultText="Please check your email from Reword containing a registration link.">
        Translation.Feature.Register.RegisterConfirmation.header
      </B1>
      <Button className={styles.confirmButton} onClick={onConfirm} defaultText="Ok">
        Translation.Feature.Register.RegisterConfirmation.Button.label
      </Button>
    </div>
  );
}

RegisterConfirmation.propTypes = {
  className: string,
  onConfirm: func.isRequired
};

RegisterConfirmation.defaultProps = {
  className: null
};

export default RegisterConfirmation;
