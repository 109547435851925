import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { historyType, sessionStateType } from 'types';
import { login } from 'services/session';
import Login from '.';

function LoginContainer(props) {
  const { history, sessionState, ...other } = props;
  const { loginFailed } = sessionState;

  const onForgotPassword = () => {
    history.push('/forgot-password');
  };

  const onLogin = (email, password) => {
    login(email, password)
      .then(() => {
        history.push('/get-quotes');
      })
      .catch(error => {});
  };

  return (
    <Login
      {...other}
      onForgotPassword={onForgotPassword}
      onLogin={onLogin}
      loginFailed={loginFailed}
    />
  );
}

LoginContainer.propTypes = {
  history: historyType.isRequired,
  sessionState: sessionStateType.isRequired
};

const mapStateToProps = state => ({
  sessionState: state.session
});

export default connect(mapStateToProps)(withRouter(LoginContainer));
