import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

// middleware
import logger from 'redux-logger';

// reducers
import orderReducer from 'services/order/reducer';
import adminReducer from 'services/admin/reducer';
import accountReducer from 'services/account/reducer';
import sessionReducer from 'services/session/reducer';
import configurationReducer from 'services/configuration/reducer';

const combinedReducers = combineReducers({
  admin: adminReducer,
  order: orderReducer,
  account: accountReducer,
  session: sessionReducer,
  configuration: configurationReducer
});

const middleware = process.env.NODE_ENV !== 'production' ? [logger] : [];

const store = configureStore({
  reducer: combinedReducers,
  middleware,
  devTools: process.env.NODE_ENV !== 'production'
});

export default store;
