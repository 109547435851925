import { createAction } from '@reduxjs/toolkit';

export default {
  registerAccountRequest: createAction('account/registerAccountRequest'),
  registerAccountSuccess: createAction('account/registerAccountSuccess'),
  registerAccountFailed: createAction('account/registerAccountFailed'),
  createAccountRequest: createAction('account/createAccountRequest'),
  createAccountSuccess: createAction('account/createAccountSuccess'),
  createAccountFailed: createAction('account/createAccountFailed'),
  forgotPasswordRequest: createAction('account/forgotPasswordRequest'),
  forgotPasswordSuccess: createAction('account/forgotPasswordSuccess'),
  forgotPasswordFailed: createAction('account/forgotPasswordFailed'),
  resetPasswordRequest: createAction('account/resetPasswordRequest'),
  resetPasswordSuccess: createAction('account/resetPasswordSuccess'),
  resetPasswordFailed: createAction('account/resetPasswordFailed')
};
