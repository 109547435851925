import React, { useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import { gsap } from 'gsap';
import { string, func, bool, arrayOf, timezoneType, shape } from 'types';
import clsx from 'clsx';
import { Modal } from 'components';
import {
  Account,
  AccountDetails,
  RegistrationConfirmation,
  RegistrationFailed
} from './components';
import styles from './CreateAccount.module.scss';

function CreateAccount(props) {
  const { className, onRegister, onConfirm, busy, timezones, createAccountError } = props;
  const intl = useIntl();
  const classNames = clsx(className, styles.root);
  const [showModal, setShowModal] = useState(false);
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const accountDetailsContainerRef = useRef(null);
  const accountContainerRef = useRef(null);
  const timeline = gsap.timeline({ paused: true });
  const accountInformation = {};

  const onNextStep = account => {
    accountInformation.account = account;
    slide();
  };

  const onPreviousStep = () => {
    slide(true);
  };

  const _onRegister = details => {
    accountInformation.details = details;
    // setShowModal(true);
    onRegister({ ...accountInformation.account, ...accountInformation.details });
  };

  const slide = (goToPrevious = false) => {
    if (!goToPrevious) {
      timeline
        .to(accountContainerRef.current, 0.5, {
          opacity: 0,
          display: 'none',
          transform: 'translateX(-50%)'
        })
        .to(accountDetailsContainerRef.current, 0.5, {
          opacity: 1,
          display: 'block',
          transform: 'translateX(0%)',
          delay: 0
        });
    } else {
      timeline
        .to(accountDetailsContainerRef.current, 0.5, {
          opacity: 0,
          display: 'none',
          transform: 'translateX(50%)'
        })
        .to(accountContainerRef.current, 0.5, {
          opacity: 1,
          display: 'block',
          transform: 'translateX(0%)',
          delay: 0
        });
    }

    timeline.play();
  };

  return (
    <div className={classNames}>
      <div ref={accountContainerRef} className={styles.account}>
        <Account className={styles.account} onNextStep={onNextStep} timezones={timezones} />
      </div>
      <div ref={accountDetailsContainerRef} className={styles.accountDetails}>
        <AccountDetails
          onRegister={_onRegister}
          onPreviousStep={onPreviousStep}
          // onShowAgreement={() => setShowTermsAndConditions(true)}
        />
      </div>
      <Modal
        isOpen={showModal}
        onClosed={() => onConfirm(createAccountError)}
        title={
          createAccountError
            ? 'Translation.Feature.CreateAccountFailed.Modal.title'
            : 'Translation.Feature.CreateAccount.Modal.title'
        }
        defaultTitle={createAccountError ? 'Registration failed' : 'Registration successful'}
        hideOnBackDropClick={false}
        busy={busy}
      >
        {createAccountError ? (
          <RegistrationFailed
            onConfirm={() => setShowModal(false)}
            bodyTranslationKey={createAccountError.translationKey}
            bodyDefaultText={createAccountError.defaultText}
          />
        ) : (
          <RegistrationConfirmation onConfirm={() => setShowModal(false)} />
        )}
      </Modal>
      {/* <Modal
        className={styles.termsAndConditionsModal}
        isOpen={showTermsAndConditions}
        defaultTitle="Terms and conditions and Quality policy"
        title="Translation.Feature.CreateAccount.AccountDetails.Agreement.Modal.title"
        hideOnBackDropClick={false}
      >
        <div className={styles.termsAndConditionsModalContentContainer}>
          <div className={styles.termsAndConditionsModalTextContainer}>
            {!!intl.messages[
              'Translation.Feature.CreateAccount.AccountDetails.Agreement.Modal.text'
            ] && (
              <FormattedMessage
                id="Translation.Feature.CreateAccount.AccountDetails.Agreement.Modal.text"
                defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a default text."
                values={{
                  b: (...chunks) => <b>{chunks}</b>,
                  NL: <br />,
                  nl: <br />,
                  Nl: <br />
                }}
              />
            )}
            {!!intl.messages[
              'Translation.Feature.CreateAccount.AccountDetails.Agreement.Modal2.text'
            ] && (
              <FormattedMessage
                id="Translation.Feature.CreateAccount.AccountDetails.Agreement.Modal2.text"
                defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a default text."
                values={{
                  b: (...chunks) => <b>{chunks}</b>,
                  NL: <br />,
                  nl: <br />,
                  Nl: <br />
                }}
              />
            )}
            {!!intl.messages[
              'Translation.Feature.CreateAccount.AccountDetails.Agreement.Modal3.text'
            ] && (
              <FormattedMessage
                id="Translation.Feature.CreateAccount.AccountDetails.Agreement.Modal3.text"
                defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a default text."
                values={{
                  b: (...chunks) => <b>{chunks}</b>,
                  NL: <br />,
                  nl: <br />,
                  Nl: <br />
                }}
              />
            )}
            {!!intl.messages[
              'Translation.Feature.CreateAccount.AccountDetails.Agreement.Modal4.text'
            ] && (
              <FormattedMessage
                id="Translation.Feature.CreateAccount.AccountDetails.Agreement.Modal4.text"
                defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a default text."
                values={{
                  b: (...chunks) => <b>{chunks}</b>,
                  NL: <br />,
                  nl: <br />,
                  Nl: <br />
                }}
              />
            )}
            {!!intl.messages[
              'Translation.Feature.CreateAccount.AccountDetails.Agreement.Modal5.text'
            ] && (
              <FormattedMessage
                id="Translation.Feature.CreateAccount.AccountDetails.Agreement.Modal5.text"
                defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a default text."
                values={{
                  b: (...chunks) => <b>{chunks}</b>,
                  NL: <br />,
                  nl: <br />,
                  Nl: <br />
                }}
              />
            )}
            {!!intl.messages[
              'Translation.Feature.CreateAccount.AccountDetails.Agreement.Modal6.text'
            ] && (
              <FormattedMessage
                id="Translation.Feature.CreateAccount.AccountDetails.Agreement.Modal6.text"
                defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a default text."
                values={{
                  b: (...chunks) => <b>{chunks}</b>,
                  NL: <br />,
                  nl: <br />,
                  Nl: <br />
                }}
              />
            )}
            {!!intl.messages[
              'Translation.Feature.CreateAccount.AccountDetails.Agreement.Modal7.text'
            ] && (
              <FormattedMessage
                id="Translation.Feature.CreateAccount.AccountDetails.Agreement.Modal7.text"
                defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a default text."
                values={{
                  b: (...chunks) => <b>{chunks}</b>,
                  NL: <br />,
                  nl: <br />,
                  Nl: <br />
                }}
              />
            )}
            {!!intl.messages[
              'Translation.Feature.CreateAccount.AccountDetails.Agreement.Modal8.text'
            ] && (
              <FormattedMessage
                id="Translation.Feature.CreateAccount.AccountDetails.Agreement.Modal8.text"
                defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a default text."
                values={{
                  b: (...chunks) => <b>{chunks}</b>,
                  NL: <br />,
                  nl: <br />,
                  Nl: <br />
                }}
              />
            )}
            {!!intl.messages[
              'Translation.Feature.CreateAccount.AccountDetails.Agreement.Modal9.text'
            ] && (
              <FormattedMessage
                id="Translation.Feature.CreateAccount.AccountDetails.Agreement.Modal9.text"
                defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a default text."
                values={{
                  b: (...chunks) => <b>{chunks}</b>,
                  NL: <br />,
                  nl: <br />,
                  Nl: <br />
                }}
              />
            )}
            {!!intl.messages[
              'Translation.Feature.CreateAccount.AccountDetails.Agreement.Modal10.text'
            ] && (
              <FormattedMessage
                id="Translation.Feature.CreateAccount.AccountDetails.Agreement.Modal10.text"
                defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a default text."
                values={{
                  b: (...chunks) => <b>{chunks}</b>,
                  NL: <br />,
                  nl: <br />,
                  Nl: <br />
                }}
              />
            )}
          </div>
          <Button
            className={styles.termsAndConditionsModalButton}
            onClick={() => setShowTermsAndConditions(false)}
            defaultText="Ok"
          >
            Translation.Feature.CreateAccount.AccountDetails.Agreement.Button.label
          </Button>
        </div>
      </Modal> */}
    </div>
  );
}

CreateAccount.propTypes = {
  className: string,
  onRegister: func.isRequired,
  onConfirm: func.isRequired,
  busy: bool,
  timezones: arrayOf(timezoneType).isRequired,
  createAccountError: shape({
    translationKey: string.isRequired,
    defaultText: string.isRequired
  })
};

CreateAccount.defaultProps = {
  className: null,
  busy: false,
  createAccountError: null
};

export default CreateAccount;
