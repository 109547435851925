import React, { useEffect, useRef } from 'react';
import { string, number } from 'types';
import clsx from 'clsx';
import styles from './ProgressBar.module.scss';

function ProgressBar({ className, ...props }) {
  const { value, minValue, maxValue } = props;
  const containerRef = useRef();
  const barRef = useRef();
  const classNames = clsx(className, styles.root);

  useEffect(() => {
    if (containerRef.current && barRef.current) {
      const total = maxValue - minValue;
      const percentage = (value / total) * 100;
      barRef.current.style.width = `${percentage}%`;
      barRef.current.style.opacity = 1;
    }
  }, [value, minValue, maxValue]);

  return (
    <div className={classNames} ref={containerRef}>
      <div className={styles.backgroundBar} />
      <div className={styles.bar} ref={barRef} />
    </div>
  );
}

ProgressBar.propTypes = {
  className: string,
  value: number,
  minValue: number,
  maxValue: number
};

ProgressBar.defaultProps = {
  className: null,
  value: 50,
  minValue: 0,
  maxValue: 100
};

export default ProgressBar;
