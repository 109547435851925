import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { string, arrayOf, orderType, bool, languageType, translationItemType, func } from 'types';
import clsx from 'clsx';
import { Loader, getCurrencyFormat } from 'assets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { S1, B1, B2, CheckBox, Button, Modal, Input } from 'components';
import { AdminOrderDetails } from './components';
import styles from './AdminOrders.module.scss';

function AdminOrders(props) {
  const {
    className,
    orders,
    busy,
    languages,
    filters,
    orderStatuses,
    onApproveOrder,
    onRejectOrder,
    onConnectProject,
    onSetOrderInvoiced,
    onSetOrderPaid,
  } = props;
  const intl = useIntl();
  const [filter, setFilter] = useState({ ongoing: false, ready: false, cancelled: false });
  const [sort, setSort] = useState({});
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [connectProjectOrderId, setConnectProjectOrderId] = useState(null);
  const [connectProjectId, setConnectProjectId] = useState(null);
  const classNames = clsx(className, styles.root);

  const filterChanged = (currentFilter) => {
    setFilter({ ...filter, ...currentFilter });
  };

  const onSort = (column) => {
    setSort({ [column]: sort[column] ? !sort[column] : true });
  };

  const filterOrders = () => {
    if (Object.keys(filter).reduce((a, b) => a || filter[b], false)) {
      return orders.filter((o) => filter[o.filterStatus]);
    }
    return orders;
  };

  const getLanguageTranslation = (value) => {
    return languages.find((l) => l.value === value);
  };

  const getSorting = (a, b, ascending) => {
    if (ascending) {
      if (b > a) {
        return 1;
      }

      if (b < a) {
        return -1;
      }
      return 0;
    }

    if (b < a) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const renderOrderHeaders = [
    <div key="1" className={styles.orderIdColumn}>
      <B1 defaultText="Id" bold className={clsx(styles.noMargin, styles.headerLabel)}>
        Translation.Feature.Admin.Orders.OrderHeader.OrderId.label
      </B1>
      <FontAwesomeIcon
        icon={faChevronUp}
        onClick={() => onSort('id')}
        className={clsx(styles.sortIcon, sort.id && styles.sortDecending)}
      />
    </div>,
    <div key="2" className={styles.userNameColumn}>
      <B1 defaultText="Email" bold className={clsx(styles.noMargin, styles.headerLabel)}>
        Translation.Feature.Admin.Orders.OrderHeader.Email.label
      </B1>
      <FontAwesomeIcon
        icon={faChevronUp}
        onClick={() => onSort('username')}
        className={clsx(styles.sortIcon, sort.username && styles.sortDecending)}
      />
    </div>,
    <div key="3" className={styles.dateHeader}>
      <B1 defaultText="Placed" bold className={clsx(styles.noMargin, styles.headerLabel)}>
        Translation.Feature.Admin.Orders.OrderHeader.Placed.label
      </B1>
      <FontAwesomeIcon
        icon={faChevronUp}
        onClick={() => onSort('placed')}
        className={clsx(styles.sortIcon, sort.placed && styles.sortDecending)}
      />
    </div>,
    <div key="4" className={styles.dateHeader}>
      <B1 defaultText="Deliver" bold className={clsx(styles.noMargin, styles.headerLabel)}>
        Translation.Feature.Admin.Orders.OrderHeader.Deliver.label
      </B1>
      <FontAwesomeIcon
        icon={faChevronUp}
        onClick={() => onSort('deliver')}
        className={clsx(styles.sortIcon, sort.deliver && styles.sortDecending)}
      />
    </div>,
    <div key="5" className={styles.statusColumn}>
      <B1 defaultText="Status" bold className={clsx(styles.noMargin, styles.headerLabel)}>
        Translation.Feature.Admin.Orders.OrderHeader.Status.label
      </B1>
      <FontAwesomeIcon
        icon={faChevronUp}
        onClick={() => onSort('status')}
        className={clsx(styles.sortIcon, sort.status && styles.sortDecending)}
      />
    </div>,
    <div key="6" className={styles.sourceLanguageColumn}>
      <B1 defaultText="Source language" bold className={clsx(styles.noMargin, styles.headerLabel)}>
        Translation.Feature.Admin.Orders.OrderHeader.SourceLanguage.label
      </B1>
      <FontAwesomeIcon
        icon={faChevronUp}
        onClick={() => onSort('sourceLanguage')}
        className={clsx(styles.sortIcon, sort.sourceLanguage && styles.sortDecending)}
      />
    </div>,
    <div key="7" className={styles.targetLanguagesColumn}>
      <B1
        defaultText="Target language(s)"
        bold
        className={clsx(styles.noMargin, styles.headerLabel)}
      >
        Translation.Feature.Admin.Orders.OrderHeader.TargetLanguages.label
      </B1>
      <FontAwesomeIcon
        icon={faChevronUp}
        onClick={() => onSort('targetLanguages')}
        className={clsx(styles.sortIcon, sort.targetLanguages && styles.sortDecending)}
      />
    </div>,
    <div key="8" className={styles.wordCountColumn}>
      <B1 defaultText="# Words" bold className={clsx(styles.noMargin, styles.headerLabel)}>
        Translation.Feature.Admin.Orders.OrderHeader.WordCount.label
      </B1>
      <FontAwesomeIcon
        icon={faChevronUp}
        onClick={() => onSort('wordCount')}
        className={clsx(styles.sortIcon, sort.wordCount && styles.sortDecending)}
      />
    </div>,
    <div key="9" className={styles.totalAmountColumn}>
      <B1 defaultText="Total" bold className={clsx(styles.noMargin, styles.headerLabel)}>
        Translation.Feature.Admin.Orders.OrderHeader.TotalAmount.label
      </B1>
      <FontAwesomeIcon
        icon={faChevronUp}
        onClick={() => onSort('total')}
        className={clsx(styles.sortIcon, sort.total && styles.sortDecending)}
      />
    </div>,
    <div key="10" className={styles.actionsColumn}>
      <B1 defaultText="Actions" bold className={clsx(styles.noMargin, styles.headerLabel)}>
        Translation.Feature.Admin.Orders.OrderHeader.Actions.label
      </B1>
    </div>,
  ];

  let filteredOrders = filterOrders();

  if (filteredOrders.length > 0 && Object.keys(sort).length > 0) {
    filteredOrders = filteredOrders.slice().sort((a, b) => {
      const key = Object.keys(sort)[0];

      if (key === 'status') {
        let orderStatus1 = orderStatuses.find((os) => os.value === a[key]);
        let orderStatus2 = orderStatuses.find((os) => os.value === b[key]);
        orderStatus1 = intl.formatMessage({
          id: orderStatus1.translationKey,
          defaultMessage: orderStatus1.defaultTranslation,
        });
        orderStatus2 = intl.formatMessage({
          id: orderStatus2.translationKey,
          defaultMessage: orderStatus2.defaultTranslation,
        });
        return getSorting(orderStatus1, orderStatus2, sort[key]);
      }

      if (key === 'sourceLanguage') {
        let sourceLanguage1 = getLanguageTranslation(a[key]);
        let sourceLanguage2 = getLanguageTranslation(b[key]);
        sourceLanguage1 = intl.formatMessage({
          id: sourceLanguage1.label,
          defaultMessage: sourceLanguage1.defaultLabel,
        });
        sourceLanguage2 = intl.formatMessage({
          id: sourceLanguage2.label,
          defaultMessage: sourceLanguage2.defaultLabel,
        });
        return getSorting(sourceLanguage1, sourceLanguage2, sort[key]);
      }

      if (key === 'targetLanguages') {
        let targetLanguages1 = a[key].map((tl) => getLanguageTranslation(tl));
        let targetLanguages2 = b[key].map((tl) => getLanguageTranslation(tl));
        targetLanguages1 = targetLanguages1
          .map((tl) => intl.formatMessage({ id: tl.label, defaultMessage: tl.defaultLabel }))
          .join(', ');
        targetLanguages2 = targetLanguages2
          .map((tl) => intl.formatMessage({ id: tl.label, defaultMessage: tl.defaultLabel }))
          .join(', ');
        return getSorting(targetLanguages1, targetLanguages2, sort[key]);
      }

      if (
        key === 'id' ||
        key === 'placed' ||
        key === 'deliver' ||
        key === 'total' ||
        key === 'wordCount' ||
        key === 'username'
      ) {
        return getSorting(a[key], b[key], sort[key]);
      }

      return 0;
    });
  }

  const renderOrders = filteredOrders.map((o) => {
    const sourceLanguage = getLanguageTranslation(o.sourceLanguage);
    const targetLanguages = o.targetLanguages.map((tl) => getLanguageTranslation(tl));
    const orderStatus = orderStatuses.find((os) => os.value === o.status);

    return (
      <div className={styles.orderRow} key={o.id}>
        <div className={styles.orderIdColumn}>
          <B2
            translate={false}
            className={clsx(styles.noMargin, styles.orderId)}
            onClick={() => selectOrder(o.id)}
          >
            {o.id}
          </B2>
        </div>
        <div className={styles.userNameColumn}>
          <B2 translate={false} className={styles.noMargin}>
            {o.username}
          </B2>
        </div>
        <div className={styles.placedColumn}>
          <B2 translate={false} className={styles.noMargin}>
            {intl.formatDate(o.placed, {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            })}
          </B2>
          <B2 translate={false} className={styles.noMargin}>
            {intl.formatTime(o.placed, {
              hour: 'numeric',
              minute: 'numeric',
            })}
          </B2>
        </div>
        <div className={styles.deliverColumn}>
          <B2 translate={false} className={styles.noMargin}>
            {intl.formatDate(o.deliveryDateDelayed, {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            })}
          </B2>
          <B2 translate={false} className={styles.noMargin}>
            {intl.formatTime(o.deliveryDateDelayed, {
              hour: 'numeric',
              minute: 'numeric',
            })}
          </B2>
        </div>
        <div className={styles.statusColumn}>
          <B2 defaultText={orderStatus.defaultTranslation} className={styles.noMargin}>
            {orderStatus.translationKey}
          </B2>
        </div>
        <div className={styles.sourceLanguageColumn}>
          <B2 defaultText={sourceLanguage.defaultLabel} className={styles.noMargin}>
            {sourceLanguage.label}
          </B2>
        </div>
        <div className={styles.targetLanguagesColumn}>
          <B2 translate={false} className={styles.noMargin}>
            {targetLanguages
              .map((tl) => intl.formatMessage({ id: tl.label, defaultMessage: tl.defaultLabel }))
              .join(', ')}
          </B2>
        </div>
        <div className={styles.wordCountColumn}>
          <B2 translate={false} className={styles.noMargin}>
            {o.wordCount}
          </B2>
        </div>
        <div className={styles.totalAmountColumn}>
          <B2 translate={false} className={styles.noMargin}>
            {intl.formatNumber(o.total, getCurrencyFormat(o.currency))}
          </B2>
        </div>
        <div className={styles.actionsColumn}>
          {o.status === 'sent' ? (
            <>
              <Button
                defaultText="Approve"
                className={styles.approveButton}
                small
                onClick={() => onApproveOrder(o.id)}
              >
                Translation.Feature.Admin.Orders.OrderAction.Approve.label
              </Button>
              <Button
                defaultText="Reject"
                className={styles.rejectButton}
                small
                onClick={() => onRejectOrder(o.id)}
              >
                Translation.Feature.Admin.Orders.OrderAction.Reject.label
              </Button>
              <Button
                defaultText="Connect"
                className={styles.connectButton}
                small
                onClick={() => {
                  setConnectProjectId('');
                  setConnectProjectOrderId(o.id);
                }}
              >
                Translation.Feature.Admin.Orders.OrderAction.Connect.label
              </Button>
            </>
          ) : null}
          {o.status === 'translated' || o.status === 'delivered' ? (
            <Button defaultText="Invoiced" small onClick={() => onSetOrderInvoiced(o.id)}>
              Translation.Feature.Admin.Orders.OrderAction.SetInvoiced.label
            </Button>
          ) : null}
          {o.status === 'invoiced' ? (
            <Button defaultText="Paid" small onClick={() => onSetOrderPaid(o.id)}>
              Translation.Feature.Admin.Orders.OrderAction.SetPaid.label
            </Button>
          ) : null}
        </div>
      </div>
    );
  });

  const renderFilters = (
    <div className={styles.filterContainer}>
      <S1 defaultText="Filter" className={styles.noMargin}>
        Translation.Feature.Admin.Orders.Filter.label
      </S1>
      <S1 translate={false} className={styles.noMargin}>
        :
      </S1>
      <div className={styles.checkBoxContainer}>
        {filters.map((f) => (
          <CheckBox
            key={f.value}
            id={f.value}
            className={styles.checkBox}
            label={f.translationKey}
            defaultLabel={f.defaultTranslation}
            checked={filter.ongoing}
            onChange={(checked) => filterChanged({ [f.value]: checked })}
          />
        ))}
      </div>
    </div>
  );

  const selectOrder = (orderId) => {
    setSelectedOrderId(orderId);
  };

  const closeOrderDetails = () => {
    setSelectedOrderId(null);
  };

  if (busy) {
    return <Loader className={styles.loader} />;
  }

  return (
    <>
      <div className={classNames}>
        {renderFilters}
        {orders.length === 0 ? (
          <S1 defaultText="No orders to display">
            Translation.Feature.Admin.Orders.NoOrders.label
          </S1>
        ) : (
          [
            <div key="orderHeaders" className={styles.orderHeadersContainer}>
              {renderOrderHeaders}
            </div>,
            <div key="orders" className={styles.orderRowContainer}>
              {renderOrders}
            </div>,
          ]
        )}
        {selectedOrderId && (
          <AdminOrderDetails
            orderId={selectedOrderId}
            onClosed={closeOrderDetails}
            orderStatuses={orderStatuses}
          />
        )}
      </div>
      <Modal
        isOpen={connectProjectOrderId !== null}
        title="Translation.Feature.Admin.Orders.ConnectProject.Modal.title"
        defaultTitle="Connect project"
        hideOnBackDropClick={false}
        className={styles.connectProjectModal}
      >
        <div className={styles.connectProjectModalInputContainer}>
          <B2 className={styles.noMargin} defaultText="Connect to project number">
            Translation.Feature.Admin.Orders.ConnectProject.Modal.subTitle
          </B2>
          {connectProjectId !== null && (
            <Input
              integer
              className={styles.connectProjectModalProjectNumberInput}
              placeHolder="Translation.Feature.Admin.Orders.ConnectProject.Modal.Input.placeholder"
              defaultPlaceHolder="Enter project number"
              onChange={setConnectProjectId}
              enterAction={() => {
                if (
                  connectProjectId !== null &&
                  connectProjectId !== '' &&
                  connectProjectOrderId !== null &&
                  connectProjectOrderId !== ''
                ) {
                  onConnectProject(connectProjectOrderId, connectProjectId);
                }
              }}
              value={connectProjectId}
              autoFocus={!!connectProjectOrderId}
            />
          )}
          <div className={styles.connectProjectModalButtonContainer}>
            <Button
              defaultText="Cancel"
              className={styles.connectProjectModalCancelButton}
              onClick={() => {
                setConnectProjectOrderId(null);
                setConnectProjectId(null);
              }}
            >
              Translation.Feature.Admin.Orders.ConnectProject.Modal.CancelButton.label
            </Button>
            <Button
              secondary
              defaultText="Add"
              className={styles.connectProjectModalDeleteButton}
              disabled={connectProjectId === null || connectProjectId === ''}
              onClick={() => {
                onConnectProject(connectProjectOrderId, connectProjectId);
                setConnectProjectOrderId(null);
                setConnectProjectId(null);
              }}
            >
              Translation.Feature.Admin.Orders.ConnectProject.Modal.AddButton.label
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

AdminOrders.propTypes = {
  className: string,
  busy: bool.isRequired,
  orders: arrayOf(orderType).isRequired,
  languages: arrayOf(languageType).isRequired,
  filters: arrayOf(translationItemType).isRequired,
  orderStatuses: arrayOf(translationItemType).isRequired,
  onApproveOrder: func.isRequired,
  onRejectOrder: func.isRequired,
  onConnectProject: func.isRequired,
  onSetOrderInvoiced: func.isRequired,
  onSetOrderPaid: func.isRequired,
};

AdminOrders.defaultProps = {
  className: null,
};

export default AdminOrders;
