import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { ensuredForwardRef } from 'react-use';
import { string } from 'types';
import clsx from 'clsx';
import { H3, H4, S1, B1, Container } from 'components';
import styles from './Services.module.scss';

const Services = ensuredForwardRef((props, ref) => {
  const { className } = props;
  const intl = useIntl();
  const classNames = clsx(className, styles.root);

  const renderServices = () => {
    return [
      <Container key="1" borderColor={styles.transparent} className={styles.container}>
        <H4 bold upperCase className={styles.headers} defaultText="Reword standard">
          Translation.Feature.OurServices.RewordStandard.header
        </H4>
        {!!intl.messages['Translation.Feature.OurServices.RewordStandard.description'] && (
          <B1 translate={false}>
            <FormattedMessage
              id="Translation.Feature.OurServices.RewordStandard.description"
              defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a <i>default text.</i>"
              values={{
                b: (...chunks) => <b>{chunks}</b>,
                i: (...chunks) => <i>{chunks}</i>,
                NL: <br />,
                nl: <br />,
                Nl: <br />,
              }}
            />
          </B1>
        )}
        {!!intl.messages['Translation.Feature.OurServices.RewordStandard.description2'] && (
          <B1 translate={false}>
            <FormattedMessage
              id="Translation.Feature.OurServices.RewordStandard.description2"
              defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a <i>default text.</i>"
              values={{
                b: (...chunks) => <b>{chunks}</b>,
                i: (...chunks) => <i>{chunks}</i>,
                NL: <br />,
                nl: <br />,
                Nl: <br />,
              }}
            />
          </B1>
        )}
        {!!intl.messages['Translation.Feature.OurServices.RewordStandard.description3'] && (
          <B1 translate={false}>
            <FormattedMessage
              id="Translation.Feature.OurServices.RewordStandard.description3"
              defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a <i>default text.</i>"
              values={{
                b: (...chunks) => <b>{chunks}</b>,
                i: (...chunks) => <i>{chunks}</i>,
                NL: <br />,
                nl: <br />,
                Nl: <br />,
              }}
            />
          </B1>
        )}
        {!!intl.messages['Translation.Feature.OurServices.RewordStandard.description4'] && (
          <B1 translate={false}>
            <FormattedMessage
              id="Translation.Feature.OurServices.RewordStandard.description4"
              defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a <i>default text.</i>"
              values={{
                b: (...chunks) => <b>{chunks}</b>,
                i: (...chunks) => <i>{chunks}</i>,
                NL: <br />,
                nl: <br />,
                Nl: <br />,
              }}
            />
          </B1>
        )}
        {!!intl.messages['Translation.Feature.OurServices.RewordStandard.description5'] && (
          <B1 translate={false}>
            <FormattedMessage
              id="Translation.Feature.OurServices.RewordStandard.description5"
              defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a <i>default text.</i>"
              values={{
                b: (...chunks) => <b>{chunks}</b>,
                i: (...chunks) => <i>{chunks}</i>,
                NL: <br />,
                nl: <br />,
                Nl: <br />,
              }}
            />
          </B1>
        )}
      </Container>,
      <Container key="2" borderColor={styles.transparent} className={styles.container}>
        <H4 bold upperCase className={styles.headers} defaultText="Reword pro">
          Translation.Feature.OurServices.RewordPro.header
        </H4>
        {!!intl.messages['Translation.Feature.OurServices.RewordPro.description'] && (
          <B1 translate={false}>
            <FormattedMessage
              id="Translation.Feature.OurServices.RewordPro.description"
              defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a <i>default text.</i>"
              values={{
                b: (...chunks) => <b>{chunks}</b>,
                i: (...chunks) => <i>{chunks}</i>,
                NL: <br />,
                nl: <br />,
                Nl: <br />,
              }}
            />
          </B1>
        )}
        {!!intl.messages['Translation.Feature.OurServices.RewordPro.description2'] && (
          <B1 translate={false}>
            <FormattedMessage
              id="Translation.Feature.OurServices.RewordPro.description2"
              defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a <i>default text.</i>"
              values={{
                b: (...chunks) => <b>{chunks}</b>,
                i: (...chunks) => <i>{chunks}</i>,
                NL: <br />,
                nl: <br />,
                Nl: <br />,
              }}
            />
          </B1>
        )}
        {!!intl.messages['Translation.Feature.OurServices.RewordPro.description3'] && (
          <B1 translate={false}>
            <FormattedMessage
              id="Translation.Feature.OurServices.RewordPro.description3"
              defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a <i>default text.</i>"
              values={{
                b: (...chunks) => <b>{chunks}</b>,
                i: (...chunks) => <i>{chunks}</i>,
                NL: <br />,
                nl: <br />,
                Nl: <br />,
              }}
            />
          </B1>
        )}
        {!!intl.messages['Translation.Feature.OurServices.RewordPro.description4'] && (
          <B1 translate={false}>
            <FormattedMessage
              id="Translation.Feature.OurServices.RewordPro.description4"
              defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a <i>default text.</i>"
              values={{
                b: (...chunks) => <b>{chunks}</b>,
                i: (...chunks) => <i>{chunks}</i>,
                NL: <br />,
                nl: <br />,
                Nl: <br />,
              }}
            />
          </B1>
        )}
        {!!intl.messages['Translation.Feature.OurServices.RewordPro.description5'] && (
          <B1 translate={false}>
            <FormattedMessage
              id="Translation.Feature.OurServices.RewordPro.description5"
              defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a <i>default text.</i>"
              values={{
                b: (...chunks) => <b>{chunks}</b>,
                i: (...chunks) => <i>{chunks}</i>,
                NL: <br />,
                nl: <br />,
                Nl: <br />,
              }}
            />
          </B1>
        )}
      </Container>,
      <Container key="3" borderColor={styles.transparent} className={styles.container}>
        <H4 bold upperCase className={styles.headers} defaultText="Reword pro express">
          Translation.Feature.OurServices.RewordProExpress.header
        </H4>
        {!!intl.messages['Translation.Feature.OurServices.RewordProExpress.description'] && (
          <B1 translate={false}>
            <FormattedMessage
              id="Translation.Feature.OurServices.RewordProExpress.description"
              defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a <i>default text.</i>"
              values={{
                b: (...chunks) => <b>{chunks}</b>,
                i: (...chunks) => <i>{chunks}</i>,
                NL: <br />,
                nl: <br />,
                Nl: <br />,
              }}
            />
          </B1>
        )}
        {!!intl.messages['Translation.Feature.OurServices.RewordProExpress.description2'] && (
          <B1 translate={false}>
            <FormattedMessage
              id="Translation.Feature.OurServices.RewordProExpress.description2"
              defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a <i>default text.</i>"
              values={{
                b: (...chunks) => <b>{chunks}</b>,
                i: (...chunks) => <i>{chunks}</i>,
                NL: <br />,
                nl: <br />,
                Nl: <br />,
              }}
            />
          </B1>
        )}
        {!!intl.messages['Translation.Feature.OurServices.RewordProExpress.description3'] && (
          <B1 translate={false}>
            <FormattedMessage
              id="Translation.Feature.OurServices.RewordProExpress.description3"
              defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a <i>default text.</i>"
              values={{
                b: (...chunks) => <b>{chunks}</b>,
                i: (...chunks) => <i>{chunks}</i>,
                NL: <br />,
                nl: <br />,
                Nl: <br />,
              }}
            />
          </B1>
        )}
        {!!intl.messages['Translation.Feature.OurServices.RewordProExpress.description4'] && (
          <B1 translate={false}>
            <FormattedMessage
              id="Translation.Feature.OurServices.RewordProExpress.description4"
              defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a <i>default text.</i>"
              values={{
                b: (...chunks) => <b>{chunks}</b>,
                i: (...chunks) => <i>{chunks}</i>,
                NL: <br />,
                nl: <br />,
                Nl: <br />,
              }}
            />
          </B1>
        )}
        {!!intl.messages['Translation.Feature.OurServices.RewordProExpress.description5'] && (
          <B1 translate={false}>
            <FormattedMessage
              id="Translation.Feature.OurServices.RewordProExpress.description5"
              defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a <i>default text.</i>"
              values={{
                b: (...chunks) => <b>{chunks}</b>,
                i: (...chunks) => <i>{chunks}</i>,
                NL: <br />,
                nl: <br />,
                Nl: <br />,
              }}
            />
          </B1>
        )}
      </Container>,
    ];
  };

  return (
    <div className={classNames} ref={ref}>
      <H3 className={styles.header} bold upperCase defaultText="Our services">
        Translation.Feature.Services.header
      </H3>
      <S1
        className={styles.bodyText}
        defaultText="Reword offers three differnt products. Standard, Pro and Pro Express. For each you will get a price and a delivery date in real time."
      >
        Translation.Feature.Services.subHeader
      </S1>
      <div className={styles.servicesContainer}>{renderServices()}</div>
    </div>
  );
});

Services.propTypes = {
  className: string,
};

Services.defaultProps = {
  className: null,
};

export default Services;
