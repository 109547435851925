import { createReducer } from '@reduxjs/toolkit';
import {
  isUserLoggedIn,
  getUserRoles,
  getUserFeatures,
  getAccountName,
  getUserName,
} from 'utils/cookieReader';
import sessionActions from './actions';

const initialState = {
  busy: false,
  userLoggedIn: isUserLoggedIn(),
  loginFailed: false,
  userRoles: getUserRoles(),
  userFeatures: getUserFeatures(),
  cookieExpired: false,
  accountName: getAccountName(),
  userName: getUserName(),
};

const sessionReducer = createReducer(initialState, {
  [sessionActions.loginRequested]: (state) => {
    const session = state;
    session.busy = true;
    session.userLoggedIn = false;
    session.loginFailed = false;
  },
  [sessionActions.loginSucceeded]: (state) => {
    const session = state;
    session.busy = false;
    session.userLoggedIn = isUserLoggedIn();
    session.userRoles = getUserRoles();
    session.userFeatures = getUserFeatures();
    session.accountName = getAccountName();
    session.userName = getUserName();
    session.loginFailed = false;
    session.cookieExpired = false;
  },
  [sessionActions.loginFailed]: (state, action) => {
    const session = state;
    session.busy = false;
    session.loginFailed = true;
    session.userLoggedIn = false;
    console.log('LOGIN FAILED', action.payload);
  },
  [sessionActions.logoutRequested]: (state) => {
    const session = state;
    session.busy = true;
  },
  [sessionActions.logoutSucceeded]: (state, action) => {
    const session = state;
    session.busy = false;
    session.userLoggedIn = isUserLoggedIn();
    session.userRoles = getUserRoles();
    session.userFeatures = getUserFeatures();
    session.accountName = getAccountName();
    session.userName = getUserName();
    session.cookieExpired = action.payload === true;
  },
  [sessionActions.logoutFailed]: (state, action) => {
    const session = state;
    session.busy = false;
    console.log('LOGOUT FAILED', action.payload);
  },
});

export default sessionReducer;
