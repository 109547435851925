import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { string, bool, shape, arrayOf, selectOptionType, oneOfType } from 'types';
import clsx from 'clsx';
import ReactSelect from 'react-select';
import styles from './Select.module.scss';

function Select({ className, ...props }) {
  const {
    bold,
    bolder,
    disabled,
    upperCase,
    isMulti,
    options,
    customStyles,
    isValid,
    validationMessage,
    defaultValidationMessage,
    translateValidationMessage,
    value,
    portal,
    ...other
  } = props;

  const intl = useIntl();

  const classNames = clsx(
    className,
    styles.root,
    bold && styles.bold,
    bolder && styles.bolder,
    disabled && styles.disabled,
    upperCase && styles.upperCase,
    !isValid && styles.notValid
  );

  const _options = options.map((o) => ({
    ...o,
    translatedLabel: o.translate !== false ? intl.formatMessage({ id: o.label }) : o.label,
  }));

  _options.sort((a, b) => {
    if (a.sorting && !b.sorting) {
      return -1;
    }
    if (!a.sorting && b.sorting) {
      return 1;
    }
    if (a.sorting && b.sorting) {
      return a.sorting - b.sorting;
    }
    if (a.translatedLabel > b.translatedLabel) {
      return 1;
    }

    return -1;
  });

  if (
    options &&
    options.some((o) => !isNaN(o.sorting)) &&
    options.some((o) => o.sorting === null)
  ) {
    const filtered = _options.filter((o) => o.sorting);

    if (filtered.length > 0) {
      _options.filter((o) => o.sorting).reverse()[0].separator = true;
    }
  }

  const _customStyles = customStyles || {
    option: (provided, state) => {
      return {
        ...provided,
        color: state.isFocused ? styles.whiteColor : styles.primaryColor,
        background: state.isFocused ? styles.secondaryColor : styles.whiteColor,
        padding: 10,
        fontSize: 13,
        letterSpacing: 1,
        '&:active': {
          background: state.isFocused ? styles.secondaryBrightColor : null,
        },
        borderBottom: state.data.separator ? '1px dashed lightgray' : null,
        marginBottom: state.data.separator ? 15 : null,
      };
    },
    control: (provided, state) => ({
      ...provided,
      borderRadius: 0,
      boxShadow: 'none',
      borderColor: styles.grayColor,
      '&:hover': {
        borderColor: state.isFocused ? styles.secondaryColor : null,
      },
    }),
    singleValue: (provided, state) => {
      return {
        ...provided,
        opacity: state.isDisabled ? 0.5 : 1,
        transition: 'opacity 300ms',
        color: styles.primaryColor,
      };
    },
    multiValue: (provided, { data }) => {
      return {
        ...provided,
        backgroundColor: styles.grayColor,
      };
    },
    multiValueLabel: (provided, { data }) => ({
      ...provided,
      color: styles.primaryColor,
    }),
    multiValueRemove: (provided, { data }) => ({
      ...provided,
      color: styles.primaryColor,
      ':hover': {
        backgroundColor: styles.darkGrayColor,
      },
    }),
  };

  let renderValidation = null;

  if (!isValid) {
    if (translateValidationMessage) {
      renderValidation = (
        <span className={styles.validationText}>
          <FormattedMessage id={validationMessage} defaultMessage={defaultValidationMessage} />
        </span>
      );
    } else {
      renderValidation = <p className={styles.validationMessage}>{validationMessage}</p>;
    }
  }

  return (
    <div>
      <ReactSelect
        isDisabled={disabled}
        placeholder={intl.formatMessage({
          id: 'Translation.Component.Select.placeholder',
          defaultMessage: 'Select...',
        })}
        filterOption={(option, searchText) => {
          if (!searchText) {
            return true;
          }

          return option.data.translatedLabel.toLowerCase().includes(searchText.toLowerCase());
        }}
        styles={_customStyles}
        className={classNames}
        options={_options}
        isMulti={isMulti}
        menuPortalTarget={portal && document.body}
        formatOptionLabel={(o, l) => {
          if (o.translate === undefined || o.translate === true) {
            return (
              <span
                className={
                  value && value.value === o.value && l.context === 'menu' ? styles.selected : null
                }
              >
                <FormattedMessage id={o.label} defaultMessage={o.defaultLabel} />
              </span>
            );
          }
          return (
            <span
              className={
                value && value.value === o.value && l.context === 'menu' ? styles.selected : null
              }
            >
              {o.label}
            </span>
          );
        }}
        value={value}
        {...other}
      />
      {renderValidation}
    </div>
  );
}

Select.propTypes = {
  className: string,
  disabled: bool,
  bold: bool,
  bolder: bool,
  upperCase: bool,
  isMulti: bool,
  options: arrayOf(selectOptionType),
  customStyles: shape({}),
  isValid: bool,
  validationMessage: string,
  defaultValidationMessage: string,
  translateValidationMessage: bool,
  value: oneOfType([selectOptionType, arrayOf(selectOptionType)]),
  portal: bool,
};

Select.defaultProps = {
  className: null,
  disabled: false,
  bold: false,
  bolder: false,
  upperCase: false,
  isMulti: false,
  options: [],
  customStyles: null,
  isValid: true,
  validationMessage: '',
  defaultValidationMessage: '',
  translateValidationMessage: true,
  value: null,
  portal: false,
};

export default Select;
