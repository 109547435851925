import React from 'react';
import { H3 } from 'components';
import { ResetPassword } from 'features';
import styles from './ResetPasswordPage.module.scss';

function ResetPasswordPage(props) {
  return (
    <div className={styles.root}>
      <H3 className={styles.header} bold upperCase defaultText="Reset password">
        Translation.Pages.ResetPassword.header.label
      </H3>
      <ResetPassword {...props} />
    </div>
  );
}

export default ResetPasswordPage;
