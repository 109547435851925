import { toast } from 'react-toastify';
import React from 'react';
import { B2 } from 'components';
import styles from './Toaster.module.scss';

const defaultOptions = (id, autoClose, className) => ({
  position: toast.POSITION.BOTTOM_RIGHT,
  toastId: id,
  autoClose,
  className,
  hideProgressBar: true,
  closeButton: !autoClose
});

const toastSuccess = (text, defaultText, id = null, translate = true, autoClose = 2000) =>
  toast.success(
    <div className={styles.container}>
      <B2 bold className={styles.text} defaultText={defaultText} translate={translate}>
        {text}
      </B2>
    </div>,
    defaultOptions(id, autoClose, styles.successToast)
  );

const toastInfo = (text, defaultText, id = null, translate = true, autoClose = 2000) =>
  toast.info(
    <div className={styles.container}>
      <B2 bold className={styles.text} defaultText={defaultText} translate={translate}>
        {text}
      </B2>
    </div>,
    defaultOptions(id, autoClose, styles.infoToast)
  );

const toastWarning = (text, defaultText, id = null, translate = true, autoClose = 2000) =>
  toast.warn(
    <div className={styles.container}>
      <B2 bold className={styles.text} defaultText={defaultText} translate={translate}>
        {text}
      </B2>
    </div>,
    defaultOptions(id, autoClose, styles.warningToast)
  );

const toastError = (text, defaultText, id = null, translate = true, autoClose = 2000) =>
  toast.error(
    <div className={styles.container}>
      <B2 bold className={styles.text} defaultText={defaultText} translate={translate}>
        {text}
      </B2>
    </div>,
    defaultOptions(id, autoClose, styles.errorToast)
  );

const dismissToast = id => toast.dismiss(id);

export { toastSuccess, toastInfo, toastWarning, toastError, dismissToast };
