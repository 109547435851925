import React from 'react';
import { string, node, bool } from 'types';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import styles from './Typography.module.scss';

function Label({ className, children, ...props }) {
  const {
    useDefault,
    translate,
    bold,
    bolder,
    disabled,
    inline,
    upperCase,
    defaultText,
    ...others
  } = props;
  const classNames = clsx(
    className,
    styles.label,
    bold ? styles.bold : null,
    bolder ? styles.bolder : null,
    inline ? styles.inline : null,
    disabled ? styles.disabled : null,
    upperCase ? styles.upperCase : null,
    !useDefault ? styles.secondary : null
  );
  return (
    <p className={classNames} {...others}>
      {translate === true ? (
        <FormattedMessage id={children} defaultMessage={defaultText} />
      ) : (
        children
      )}
    </p>
  );
}

Label.propTypes = {
  className: string,
  children: node,
  useDefault: bool,
  translate: bool,
  bold: bool,
  bolder: bool,
  disabled: bool,
  inline: bool,
  upperCase: bool,
  defaultText: string
};

Label.defaultProps = {
  className: null,
  children: null,
  useDefault: true,
  translate: true,
  bold: false,
  bolder: false,
  disabled: false,
  inline: false,
  upperCase: false,
  defaultText: ''
};

export default Label;
