import React from 'react';
import { string, node, bool, func } from 'types';
import clsx from 'clsx';
import { C } from 'components';
import styles from './Link.module.scss';

function Link({ className, ...props }) {
  const {
    bold,
    bolder,
    disabled,
    upperCase,
    translate,
    children,
    onClick,
    defaultText,
    href,
    ...other
  } = props;

  const classNames = clsx(className, styles.root);
  const hrefAttribute = href ? { href } : {};

  const _onClick = () => {
    if (!disabled && onClick) {
      onClick();
    }
  };

  return (
    <a
      role="presentation"
      className={clsx(classNames, disabled ? styles.disabled : styles.enabled)}
      onClick={_onClick}
      {...hrefAttribute}
      {...other}
    >
      <C
        translate={translate}
        bold={bold}
        bolder={bolder}
        disabled={disabled}
        upperCase={upperCase}
        className={disabled ? styles.disabledLabel : styles.label}
        defaultText={defaultText}
      >
        {children}
      </C>
    </a>
  );
}

Link.propTypes = {
  className: string,
  children: node,
  disabled: bool,
  bold: bool,
  bolder: bool,
  upperCase: bool,
  translate: bool,
  onClick: func,
  defaultText: string,
  href: string,
};

Link.defaultProps = {
  className: null,
  children: null,
  disabled: false,
  bold: false,
  bolder: false,
  upperCase: false,
  translate: true,
  onClick: null,
  defaultText: '',
  href: null,
};

export default Link;
