import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { registerAccount } from 'services/account';
import { accountType, historyType } from 'types';
import Register from '.';

function RegisterContainer(props) {
  const { account, history } = props;
  const [showConfirmation, setShowConfirmation] = useState(0);

  const onSignUp = email => {
    const request = {
      email
    };

    registerAccount(request)
      .then(response => {
        history.push(
          `/create-account?id=${response.payload.id}&verificationKey=${response.payload.verificationKey}`
        );
        // setShowConfirmation(1);
      })
      .catch(error => {
        setShowConfirmation(2);
      });
  };

  const onConfirm = () => {
    history.push('/');
  };

  const onLogin = () => {
    history.push('/login');
  };

  return (
    <Register
      {...props}
      onSignUp={onSignUp}
      onConfirm={onConfirm}
      onLogin={onLogin}
      showConfirmation={showConfirmation}
      busy={account.busy}
    />
  );
}

const mapStateToProps = state => {
  return {
    account: state.account
  };
};

RegisterContainer.propTypes = {
  account: accountType.isRequired,
  history: historyType.isRequired
};

export default connect(mapStateToProps)(withRouter(RegisterContainer));
