import React, { useState, useEffect } from 'react';
import {
  string,
  bool,
  func,
  adminMailSettingsType,
  adminRewordSettingsType,
  adminTranslationSettingsType
} from 'types';
import clsx from 'clsx';
import { Loader } from 'assets';
import { H3, Container, Label, Input, TextArea, Button, CheckBox } from 'components';
import styles from './AdminSettings.module.scss';

function AdminSettings(props) {
  const { className, busy, mail, reword, translation, onSave } = props;
  const [settings, setSettings] = useState({ mail, reword, translation });
  const classNames = clsx(className, styles.root);

  useEffect(() => {
    if (mail && reword && translation) {
      setSettings({ mail, reword, translation });
    }
  }, [mail, reword, translation]);

  if (busy || !settings.mail || !settings.reword || !settings.translation) {
    return <Loader className={styles.loader} />;
  }

  const onApiKeyChanged = value => {
    setSettings({ ...settings, mail: { ...settings.mail, apiKey: value } });
  };

  const onFromMailChanged = value => {
    setSettings({ ...settings, mail: { ...settings.mail, fromMail: value } });
  };

  const onNotificationMailsToChanged = value => {
    setSettings({ ...settings, mail: { ...settings.mail, notificationMailsTo: value } });
  }

  const onErrorMailsToChanged = value => {
    setSettings({ ...settings, mail: { ...settings.mail, systemMailsTo: value } });
  };

  const onTranslationDocumentsDeletedChanged = value => {
    setSettings({ ...settings, mail: { ...settings.mail, translationDocumentsDeletedTemplate: value } });
  };

  const onTranslationDocumentsDeletedBccChanged = value => {
    setSettings({ ...settings, mail: { ...settings.mail, translationDocumentsDeletedTemplateBcc: value } });
  };

  const onLoginUrlChanged = value => {
    setSettings({ ...settings, mail: { ...settings.mail, loginUrl: value } });
  };

  const onVerificationUrlChanged = value => {
    setSettings({ ...settings, mail: { ...settings.mail, verificationUrl: value } });
  };

  const onWelcomeTemplateChanged = value => {
    setSettings({ ...settings, mail: { ...settings.mail, welcomeTemplate: value } });
  };

  const onWelcomeTemplateBccChanged = value => {
    setSettings({ ...settings, mail: { ...settings.mail, welcomeTemplateBcc: value } });
  };

  const onRegistrationTemplateChanged = value => {
    setSettings({ ...settings, mail: { ...settings.mail, registrationTemplate: value } });
  };

  const onRegistrationTemplateBccChanged = value => {
    setSettings({ ...settings, mail: { ...settings.mail, registrationTemplateBcc: value } });
  };

  const onResetPasswordTemplateChanged = value => {
    setSettings({ ...settings, mail: { ...settings.mail, resetPasswordTemplate: value } });
  };

  const onResetPasswordTemplateBccChanged = value => {
    setSettings({ ...settings, mail: { ...settings.mail, resetPasswordTemplateBcc: value } });
  };

  const onOrderConfirmationTemplateChanged = value => {
    setSettings({ ...settings, mail: { ...settings.mail, orderConfirmationTemplate: value } });
  };

  const onOrderConfirmationTemplateBccChanged = value => {
    setSettings({ ...settings, mail: { ...settings.mail, orderConfirmationTemplateBcc: value } });
  };

  const onTranslationCancelledTemplateChanged = value => {
    setSettings({ ...settings, mail: { ...settings.mail, translationCancelledTemplate: value } });
  };

  const onTranslationCancelledTemplateBccChanged = value => {
    setSettings({
      ...settings,
      mail: { ...settings.mail, translationCancelledTemplateBcc: value }
    });
  };

  const onTranslationCompletedTemplateChanged = value => {
    setSettings({ ...settings, mail: { ...settings.mail, translationCompletedTemplate: value } });
  };

  const onTranslationCompletedTemplateBccChanged = value => {
    setSettings({
      ...settings,
      mail: { ...settings.mail, translationCompletedTemplateBcc: value }
    });
  };

  const onTranslationDelayedTemplateChanged = value => {
    setSettings({ ...settings, mail: { ...settings.mail, translationDelayedTemplate: value } });
  };

  const onTranslationDelayedTemplateBccChanged = value => {
    setSettings({ ...settings, mail: { ...settings.mail, translationDelayedTemplateBcc: value } });
  };

  const onTranslationPartialCompletedTemplateChanged = value => {
    setSettings({
      ...settings,
      mail: { ...settings.mail, translationPartialCompletedTemplate: value }
    });
  };

  const onTranslationPartialCompletedTemplateBccChanged = value => {
    setSettings({
      ...settings,
      mail: { ...settings.mail, translationPartialCompletedTemplateBcc: value }
    });
  };

  const onResetPasswordUrlChanged = value => {
    setSettings({ ...settings, mail: { ...settings.mail, resetPasswordUrl: value } });
  };

  const onFixedCostChanged = value => {
    setSettings({ ...settings, reword: { ...settings.reword, fixedCost: value } });
  };

  const onEnvironmentChanged = value => {
    setSettings({ ...settings, reword: { ...settings.reword, environment: value } });
  };

  const onFirstLevelChanged = value => {
    setSettings({ ...settings, reword: { ...settings.reword, firstLevel: value } });
  };

  const onSecondLevelChanged = value => {
    setSettings({ ...settings, reword: { ...settings.reword, secondLevel: value } });
  };

  const onStep1MarkupChanged = value => {
    setSettings({ ...settings, reword: { ...settings.reword, step1Markup: value } });
  };

  const onStep2MarkupChanged = value => {
    setSettings({ ...settings, reword: { ...settings.reword, step2Markup: value } });
  };

  const onStep3MarkupChanged = value => {
    setSettings({ ...settings, reword: { ...settings.reword, step3Markup: value } });
  };

  const onProMarkupChanged = value => {
    setSettings({ ...settings, reword: { ...settings.reword, proMarkup: value } });
  };

  const onProExtraMarkupChanged = value => {
    setSettings({ ...settings, reword: { ...settings.reword, proExtraMarkup: value } });
  };

  const onHoursStandardChanged = value => {
    setSettings({ ...settings, reword: { ...settings.reword, hoursStandard: value } });
  };

  const onHoursProfessionalChanged = value => {
    setSettings({ ...settings, reword: { ...settings.reword, hoursProfessional: value } });
  };

  const onHoursProfessionalProChanged = value => {
    setSettings({ ...settings, reword: { ...settings.reword, hoursProfessionalPro: value } });
  };

  const onSandboxChanged = value => {
    setSettings({ ...settings, translation: { ...settings.translation, sandbox: value } });
  };

  const onBaseUrlChanged = value => {
    setSettings({ ...settings, translation: { ...settings.translation, baseUrl: value } });
  };

  const onUserChanged = value => {
    setSettings({ ...settings, translation: { ...settings.translation, user: value } });
  };

  const onPasswordChanged = value => {
    setSettings({ ...settings, translation: { ...settings.translation, password: value } });
  };

  return [
    <div key="settingsContainer" className={classNames}>
      <Container className={styles.settingsContainer}>
        <H3 className={styles.settingsHeader} defaultText="Mail">
          Translation.Feature.AdminSettings.Mail.header
        </H3>
        <Label defaultText="Api key">Translation.Feature.AdminSettings.Mail.ApiKey.label</Label>
        <TextArea
          className={styles.apiKeyTextArea}
          value={settings.mail.apiKey}
          onChange={onApiKeyChanged}
        />
        <Label defaultText="From mail">Translation.Feature.AdminSettings.Mail.FromMail.label</Label>
        <Input
          className={styles.input}
          value={settings.mail.fromMail}
          onChange={onFromMailChanged}
        />
        <Label defaultText="Send notification mails to">Translation.Feature.AdminSettings.Mail.NotificationMailsTo.label</Label>
        <Input
          className={styles.input}
          value={settings.mail.notificationMailsTo}
          onChange={onNotificationMailsToChanged}
        />
        <Label defaultText="Send system mails to">Translation.Feature.AdminSettings.Mail.ErrorMailsTo.label</Label>
        <Input
          className={styles.input}
          value={settings.mail.systemMailsTo}
          onChange={onErrorMailsToChanged}
        />
        <Label defaultText="Login url">Translation.Feature.AdminSettings.Mail.LoginUrl.label</Label>
        <Input
          className={styles.input}
          value={settings.mail.loginUrl}
          onChange={onLoginUrlChanged}
        />
        <Label defaultText="Verification url">
          Translation.Feature.AdminSettings.Mail.VerificationUrl.label
        </Label>
        <Input
          className={styles.input}
          value={settings.mail.verificationUrl}
          onChange={onVerificationUrlChanged}
        />
        <Label defaultText="Welcome template">
          Translation.Feature.AdminSettings.Mail.WelcomeTemplate.label
        </Label>
        <Input
          className={styles.input}
          value={settings.mail.welcomeTemplate}
          onChange={onWelcomeTemplateChanged}
        />
        <Label defaultText="Welcome template Bcc mail(s)">
          Translation.Feature.AdminSettings.Mail.WelcomeTemplate.Bcc.label
        </Label>
        <Input
          className={styles.input}
          value={settings.mail.welcomeTemplateBcc}
          onChange={onWelcomeTemplateBccChanged}
        />
        <Label defaultText="Registration template">
          Translation.Feature.AdminSettings.Mail.RegistrationTemplate.label
        </Label>
        <Input
          className={styles.input}
          value={settings.mail.registrationTemplate}
          onChange={onRegistrationTemplateChanged}
        />
        <Label defaultText="Registration template Bcc mail(s)">
          Translation.Feature.AdminSettings.Mail.RegistrationTemplate.Bcc.label
        </Label>
        <Input
          className={styles.input}
          value={settings.mail.registrationTemplateBcc}
          onChange={onRegistrationTemplateBccChanged}
        />
        <Label defaultText="Reset password template">
          Translation.Feature.AdminSettings.Mail.ResetPasswordTemplate.label
        </Label>
        <Input
          className={styles.input}
          value={settings.mail.resetPasswordTemplate}
          onChange={onResetPasswordTemplateChanged}
        />
        <Label defaultText="Reset password template Bcc mail(s)">
          Translation.Feature.AdminSettings.Mail.ResetPasswordTemplate.Bcc.label
        </Label>
        <Input
          className={styles.input}
          value={settings.mail.resetPasswordTemplateBcc}
          onChange={onResetPasswordTemplateBccChanged}
        />
        <Label defaultText="Order confirmation template">
          Translation.Feature.AdminSettings.Mail.OrderConfirmationTemplate.label
        </Label>
        <Input
          className={styles.input}
          value={settings.mail.orderConfirmationTemplate}
          onChange={onOrderConfirmationTemplateChanged}
        />
        <Label defaultText="Order confirmation template Bcc mail(s)">
          Translation.Feature.AdminSettings.Mail.OrderConfirmationTemplate.Bcc.label
        </Label>
        <Input
          className={styles.input}
          value={settings.mail.orderConfirmationTemplateBcc}
          onChange={onOrderConfirmationTemplateBccChanged}
        />
        <Label defaultText="Translation cancelled template">
          Translation.Feature.AdminSettings.Mail.TranslationCancelledTemplate.label
        </Label>
        <Input
          className={styles.input}
          value={settings.mail.translationCancelledTemplate}
          onChange={onTranslationCancelledTemplateChanged}
        />
        <Label defaultText="Translation cancelled template Bcc mail(s)">
          Translation.Feature.AdminSettings.Mail.TranslationCancelledTemplate.Bcc.label
        </Label>
        <Input
          className={styles.input}
          value={settings.mail.translationCancelledBccTemplate}
          onChange={onTranslationCancelledTemplateBccChanged}
        />
        <Label defaultText="Translation completed template">
          Translation.Feature.AdminSettings.Mail.TranslationCompletedTemplate.label
        </Label>
        <Input
          className={styles.input}
          value={settings.mail.translationCompletedTemplate}
          onChange={onTranslationCompletedTemplateChanged}
        />
        <Label defaultText="Translation completed template Bcc mail(s)">
          Translation.Feature.AdminSettings.Mail.TranslationCompletedTemplate.Bcc.label
        </Label>
        <Input
          className={styles.input}
          value={settings.mail.translationCompletedTemplateBcc}
          onChange={onTranslationCompletedTemplateBccChanged}
        />
        <Label defaultText="Translation delayed template">
          Translation.Feature.AdminSettings.Mail.TranslationDelayedTemplate.label
        </Label>
        <Input
          className={styles.input}
          value={settings.mail.translationDelayedTemplate}
          onChange={onTranslationDelayedTemplateChanged}
        />
        <Label defaultText="Translation delayed template Bcc mail(s)">
          Translation.Feature.AdminSettings.Mail.TranslationDelayedTemplate.Bcc.label
        </Label>
        <Input
          className={styles.input}
          value={settings.mail.translationDelayedBccTemplate}
          onChange={onTranslationDelayedTemplateBccChanged}
        />
        <Label defaultText="Translation partial completed template">
          Translation.Feature.AdminSettings.Mail.TranslationPartialCompeltedTemplate.label
        </Label>
        <Input
          className={styles.input}
          value={settings.mail.translationPartialCompletedTemplate}
          onChange={onTranslationPartialCompletedTemplateChanged}
        />
        <Label defaultText="Translation partial completed template Bcc mail(s)">
          Translation.Feature.AdminSettings.Mail.TranslationPartialCompeltedTemplate.Bcc.label
        </Label>
        <Input
          className={styles.input}
          value={settings.mail.translationPartialCompletedTemplateBcc}
          onChange={onTranslationPartialCompletedTemplateBccChanged}
        />
        <Label defaultText="Translation documents deleted template">
          Translation.Feature.AdminSettings.Mail.TranslationDocumentsDeletedTemplate.label
        </Label>
        <Input
          className={styles.input}
          value={settings.mail.translationDocumentsDeletedTemplate}
          onChange={onTranslationDocumentsDeletedChanged}
        />
        <Label defaultText="Translation documents deleted template Bcc mail(s)">
          Translation.Feature.AdminSettings.Mail.TranslationDocumentsDeletedTemplate.Bcc.label
        </Label>
        <Input
          className={styles.input}
          value={settings.mail.translationDocumentsDeletedTemplateBcc}
          onChange={onTranslationDocumentsDeletedBccChanged}
        />
        <Label defaultText="Reset password url">
          Translation.Feature.AdminSettings.Mail.ResetPasswordUrl.label
        </Label>
        <Input
          className={styles.input}
          value={settings.mail.resetPasswordUrl}
          onChange={onResetPasswordUrlChanged}
        />
      </Container>
      <Container className={styles.settingsContainer}>
        <H3 className={styles.settingsHeader} defaultText="Reword">
          Translation.Feature.AdminSettings.Reword.header
        </H3>
        <Label defaultText="Environment">
          Translation.Feature.AdminSettings.Reword.Environment.label
        </Label>
        <Input
          className={styles.input}
          value={settings.reword.environment}
          onChange={onEnvironmentChanged}
        />
        <Label defaultText="Fixed cost">
          Translation.Feature.AdminSettings.Reword.FixedCost.label
        </Label>
        <Input
          className={styles.input}
          value={settings.reword.fixedCost}
          onChange={onFixedCostChanged}
          type="number"
          step={1}
        />
        <Label defaultText="First level">
          Translation.Feature.AdminSettings.Reword.FirstLevel.label
        </Label>
        <Input
          className={styles.input}
          value={settings.reword.firstLevel}
          onChange={onFirstLevelChanged}
          type="number"
          step={1}
        />
        <Label defaultText="Second level">
          Translation.Feature.AdminSettings.Reword.SecondLevel.label
        </Label>
        <Input
          className={styles.input}
          value={settings.reword.secondLevel}
          onChange={onSecondLevelChanged}
          type="number"
          step={1}
        />
        <Label defaultText="Step 1 markup">
          Translation.Feature.AdminSettings.Reword.Step1Markup.label
        </Label>
        <Input
          className={styles.input}
          value={settings.reword.step1Markup}
          onChange={onStep1MarkupChanged}
          type="number"
          step={0.01}
        />
        <Label defaultText="Step 2 markup">
          Translation.Feature.AdminSettings.Reword.Step2Markup.label
        </Label>
        <Input
          className={styles.input}
          value={settings.reword.step2Markup}
          onChange={onStep2MarkupChanged}
          type="number"
          step={0.01}
        />
        <Label defaultText="Step 3 markup">
          Translation.Feature.AdminSettings.Reword.Step3Markup.label
        </Label>
        <Input
          className={styles.input}
          value={settings.reword.step3Markup}
          onChange={onStep3MarkupChanged}
          type="number"
          step={0.01}
        />
        <Label defaultText="Pro markup">
          Translation.Feature.AdminSettings.Reword.ProMarkup.label
        </Label>
        <Input
          className={styles.input}
          value={settings.reword.proMarkup}
          onChange={onProMarkupChanged}
          type="number"
          step={0.01}
        />
        <Label defaultText="Pro extra markup">
          Translation.Feature.AdminSettings.Reword.ProExtraMarkup.label
        </Label>
        <Input
          className={styles.input}
          value={settings.reword.proExtraMarkup}
          onChange={onProExtraMarkupChanged}
          type="number"
          step={0.01}
        />
        <Label defaultText="Hours standard">
          Translation.Feature.AdminSettings.Reword.HoursStandard.label
        </Label>
        <Input
          className={styles.input}
          value={settings.reword.hoursStandard}
          onChange={onHoursStandardChanged}
          type="number"
          step={1}
        />
        <Label defaultText="Hours professional">
          Translation.Feature.AdminSettings.Reword.HoursProfessional.label
        </Label>
        <Input
          className={styles.input}
          value={settings.reword.hoursProfessional}
          onChange={onHoursProfessionalChanged}
          type="number"
          step={1}
        />
        <Label defaultText="Hours professional pro">
          Translation.Feature.AdminSettings.Reword.HoursProfessionalPro.label
        </Label>
        <Input
          className={styles.input}
          value={settings.reword.hoursProfessionalPro}
          onChange={onHoursProfessionalProChanged}
          type="number"
          step={1}
        />
      </Container>
      <Container className={styles.settingsContainer}>
        <H3 className={styles.settingsHeader} defaultText="Translation">
          Translation.Feature.AdminSettings.Translation.header
        </H3>
        <Label defaultText="Sandbox">
          Translation.Feature.AdminSettings.Translation.Sandbox.label
        </Label>
        <CheckBox
          onChange={onSandboxChanged}
          checked={settings.translation.sandbox}
          className={styles.sandboxCheckBox}
        />
        <Label defaultText="Base url">
          Translation.Feature.AdminSettings.Translation.BaseUrl.label
        </Label>
        <Input
          className={styles.input}
          value={settings.translation.baseUrl}
          onChange={onBaseUrlChanged}
        />
        <Label defaultText="User">Translation.Feature.AdminSettings.Translation.User.label</Label>
        <Input
          className={styles.input}
          value={settings.translation.user}
          onChange={onUserChanged}
        />
        <Label defaultText="Password">
          Translation.Feature.AdminSettings.Translation.Password.label
        </Label>
        <Input
          className={styles.input}
          value={settings.translation.password}
          onChange={onPasswordChanged}
        />
      </Container>
    </div>,
    <Button
      key="saveButton"
      secondary
      defaultText="Save"
      className={styles.saveButton}
      onClick={() => onSave(settings)}
    >
      Translation.Feature.AdminSettings.SaveButton.label
    </Button>
  ];
}

AdminSettings.propTypes = {
  className: string,
  busy: bool.isRequired,
  mail: adminMailSettingsType,
  reword: adminRewordSettingsType,
  translation: adminTranslationSettingsType,
  onSave: func.isRequired
};

AdminSettings.defaultProps = {
  className: null,
  mail: null,
  reword: null,
  translation: null
};

export default AdminSettings;
