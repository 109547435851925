import { createReducer } from '@reduxjs/toolkit';
import adminActions from './actions';

const initialState = {
  adminOrder: {
    busy: false,
    orders: []
  },
  adminSettings: {
    busy: false
  },
  adminAccount: {
    busy: false,
    accounts: []
  }
};

const adminReducer = createReducer(initialState, {
  [adminActions.getOrdersRequest]: (state, action) => {
    const { adminOrder } = state;
    adminOrder.busy = true;
  },
  [adminActions.getOrdersSuccess]: (state, action) => {
    const adminState = state;
    adminState.adminOrder = {
      orders: action.payload.map(o => ({
        ...o,
        placed: new Date(o.placed)
      })),
      busy: false
    };
  },
  [adminActions.getOrdersFailed]: (state, action) => {
    const { adminOrder } = state;
    adminOrder.busy = false;
    console.log('GET ADMIN ORDERS FAILED', action.payload);
  },
  [adminActions.getSettingsRequest]: (state, action) => {
    const { adminSettings } = state;
    adminSettings.busy = true;
  },
  [adminActions.getSettingsSuccess]: (state, action) => {
    const adminState = state;
    adminState.adminSettings = { ...action.payload, busy: false };
  },
  [adminActions.getSettingsFailed]: (state, action) => {
    const { adminSettings } = state;
    adminSettings.busy = false;
    console.log('GET ADMIN SETTINGS FAILED', action.payload);
  },
  [adminActions.getAccountsRequest]: (state, action) => {
    const { adminAccount } = state;
    adminAccount.busy = true;
  },
  [adminActions.getAccountsSuccess]: (state, action) => {
    const adminState = state;
    adminState.adminAccount = { accounts: action.payload, busy: false };
  },
  [adminActions.getAccountsFailed]: (state, action) => {
    const { adminAccount } = state;
    adminAccount.busy = false;
    console.log('GET ADMIN ACCOUNTS FAILED', action.payload);
  },
  [adminActions.updateSettingsSuccess]: (state, action) => {
    const adminState = state;
    adminState.adminSettings = { ...action.payload, busy: false };
  },
  [adminActions.approveOrderSuccess]: (state, action) => {
    const { adminOrder } = state;
    adminOrder.orders = adminOrder.orders.map(o =>
      o.id === action.payload.id ? action.payload : o
    );
  },
  [adminActions.rejectOrderSuccess]: (state, action) => {
    const { adminOrder } = state;
    adminOrder.orders = adminOrder.orders.map(o =>
      o.id === action.payload.id ? action.payload : o
    );
  },
  [adminActions.updateSettingsSuccess]: (state, action) => {
    const adminState = state;
    adminState.adminSettings = { ...action.payload, busy: false };
  }
});

export default adminReducer;
