import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Loader } from 'assets';
import { H3, Container, Label, Input, Button, Select } from 'components';
import isEmailValid from 'utils/emailValidator';
import { arrayOf, string, bool, func, accountType, countryType, timezoneType } from 'types';
import styles from './ManageAccount.module.scss';

function ManageAccount(props) {
  const { className, busy, account, countries, timezones, onSave } = props;
  const [_account, setAccount] = useState(account);
  const [validation, setValidation] = useState({});
  const classNames = clsx(className, styles.root);

  useEffect(() => {
    if (account) {
      setAccount({
        ...account,
        country: countries.find(c => c.value === account.country),
        timezone: timezones.find(tz => tz.value === account.timezone)
      });
    }
  }, [account]);

  if (busy || !_account) {
    return <Loader className={styles.loader} />;
  }

  const onCompanyNameChanged = value => {
    setAccount({ ..._account, companyName: value });
  };

  const onContactNameChanged = value => {
    setAccount({ ..._account, contactName: value });
  };

  const onAddress1Changed = value => {
    setAccount({ ..._account, address1: value });
  };

  const onAddress2Changed = value => {
    setAccount({ ..._account, address2: value });
  };

  const onPostalCodeChanged = value => {
    setAccount({ ..._account, postalCode: value });
  };

  const onCityChanged = value => {
    setAccount({ ..._account, city: value });
  };

  const onCountryChanged = value => {
    setAccount({ ..._account, country: value });
  };

  const onPhoneChanged = value => {
    setAccount({ ..._account, phone: value });
  };

  const onVatChanged = value => {
    setAccount({ ..._account, vat: value });
  };

  const onEmailChanged = value => {
    setAccount({ ..._account, email: value });
  };

  const onPasswordChanged = value => {
    setAccount({ ..._account, password: value });
  };

  const onTimezoneChanged = value => {
    setAccount({ ..._account, timezone: value });
  };

  const _onSave = () => {
    if (validate()) {
      onSave({ ..._account, country: _account.country.value, timezone: _account.timezone.value });
    }
  };

  const validate = () => {
    const currentValidation = {
      allValid: false,
      companyNameValid: false,
      contactNameValid: false,
      address1Valid: false,
      postalCodeValid: false,
      cityValid: false,
      countryValid: false,
      phoneValid: false,
      emailValid: false,
      passwordValid: false,
      timezoneValid: false,
      companyInformationValid: false,
      loginCredentialsValid: false,
      settingsValid: false
    };

    currentValidation.emailValid = isEmailValid(_account.email);
    currentValidation.passwordValid =
      _account.password !== undefined && _account.password.length > 5;

    currentValidation.timezoneValid = _account.timezone !== undefined && _account.timezone !== null;

    if (_account.companyName) {
      currentValidation.companyNameValid = true;
    }

    if (_account.contactName) {
      currentValidation.contactNameValid = true;
    }

    if (_account.address1) {
      currentValidation.address1Valid = true;
    }

    if (_account.postalCode) {
      currentValidation.postalCodeValid = true;
    }

    if (_account.city) {
      currentValidation.cityValid = true;
    }

    if (_account.country !== undefined && _account.country !== null) {
      currentValidation.countryValid = true;
    }

    if (_account.phone) {
      currentValidation.phoneValid = true;
    }

    currentValidation.companyInformationValid =
      currentValidation.companyNameValid &&
      currentValidation.contactNameValid &&
      currentValidation.address1Valid &&
      currentValidation.postalCodeValid &&
      currentValidation.cityValid &&
      currentValidation.countryValid &&
      currentValidation.phoneValid;

    currentValidation.loginCredentialsValid =
      currentValidation.emailValid && currentValidation.passwordValid;

    currentValidation.settingsValid = currentValidation.timezoneValid;

    currentValidation.allValid =
      currentValidation.companyInformationValid &&
      currentValidation.loginCredentialsValid &&
      currentValidation.settingsValid;

    setValidation(currentValidation);

    return currentValidation.allValid;
  };

  return [
    <div key="accountContainer" className={classNames}>
      <Container
        className={clsx(
          styles.accountContainer,
          validation.companyInformationValid === false ? styles.containerInvalid : null
        )}
      >
        <H3 className={styles.accountHeader} defaultText="Company information">
          Translation.Feature.ManageAccount.CompanyInformation.header
        </H3>
        <Label defaultText="Company name">
          Translation.Feature.ManageAccount.CompanyName.label
        </Label>
        <Input
          className={styles.input}
          value={_account.companyName}
          onChange={onCompanyNameChanged}
          placeHolder="Translation.Feature.ManageAccount.CompanyName.Input.placeholder"
          defaultPlaceHolder="Enter name of your company"
          isValid={validation.companyNameValid}
          validationMessage="Translation.Feature.ManageAccount.CompanyName.Input.validationMessage"
          defaultValidationMessage="Please provide a company name"
          enterAction={_onSave}
          name="organization"
          id="organization"
          maxValue={70}
        />
        <Label defaultText="Contact name">
          Translation.Feature.ManageAccount.ContactName.label
        </Label>
        <Input
          className={styles.input}
          value={_account.contactName}
          onChange={onContactNameChanged}
          placeHolder="Translation.Feature.ManageAccount.ContactName.Input.placeholder"
          defaultPlaceHolder="Enter contact name"
          isValid={validation.nameValid}
          validationMessage="Translation.Feature.ManageAccount.ContactName.Input.validationMessage"
          defaultValidationMessage="Please provide a contact name"
          enterAction={_onSave}
          name="name"
          id="name"
          maxValue={50}
        />
        <Label defaultText="Address 1">Translation.Feature.ManageAccount.Address1.label</Label>
        <Input
          className={styles.input}
          value={_account.address1}
          onChange={onAddress1Changed}
          placeHolder="Translation.Feature.ManageAccount.Address1.Input.placeholder"
          defaultPlaceHolder="Enter postal address to your company"
          isValid={validation.addressValid}
          validationMessage="Translation.Feature.ManageAccount.Address1.Input.validationMessage"
          defaultValidationMessage="Please provide a valid postal address"
          enterAction={_onSave}
          name="address-line1"
          id="address-line1"
          maxValue={50}
        />
        <Label defaultText="Address 2">Translation.Feature.ManageAccount.Address2.label</Label>
        <Input
          className={styles.input}
          value={_account.address2}
          onChange={onAddress2Changed}
          placeHolder="Translation.Feature.ManageAccount.Address2.Input.placeholder"
          defaultPlaceHolder="Enter postal address to your company"
          enterAction={_onSave}
          name="address-line2"
          id="address-line2"
          maxValue={50}
        />
        <Label defaultText="Postal code">Translation.Feature.ManageAccount.PostalCode.label</Label>
        <Input
          className={styles.input}
          value={_account.postalCode}
          onChange={onPostalCodeChanged}
          placeHolder="Translation.Feature.ManageAccount.PostalCode.Input.placeholder"
          defaultPlaceHolder="Enter postal code"
          isValid={validation.postalCodeValid}
          validationMessage="Translation.Feature.ManageAccount.PostalCode.Input.validationMessage"
          defaultValidationMessage="Please provide a valid postal code"
          enterAction={_onSave}
          name="postal-code"
          id="postal-code"
          maxValue={15}
        />
        <Label defaultText="City">Translation.Feature.ManageAccount.City.label</Label>
        <Input
          className={styles.input}
          value={_account.city}
          onChange={onCityChanged}
          placeHolder="Translation.Feature.ManageAccount.City.Input.placeholder"
          defaultPlaceHolder="Enter city"
          isValid={validation.cityValid}
          validationMessage="Translation.Feature.ManageAccount.City.Input.validationMessage"
          defaultValidationMessage="Please provide a city"
          enterAction={_onSave}
          maxValue={30}
        />
        <Label defaultText="Country">Translation.Feature.ManageAccount.Country.label</Label>
        <div className={styles.countrySelectContainer}>
          <Select
            options={countries}
            value={_account.country}
            onChange={onCountryChanged}
            isValid={validation.countryValid}
            validationMessage="Translation.Feature.ManageAccount.Country.Select.validationMessage"
            defaultValidationMessage="Please provide a country"
            portal
          />
        </div>
        <Label defaultText="Phone">Translation.Feature.ManageAccount.Phone.label</Label>
        <Input
          className={styles.input}
          value={_account.phone}
          onChange={onPhoneChanged}
          placeHolder="Translation.Feature.ManageAccount.Phone.Input.placeholder"
          defaultPlaceHolder="Enter phone number"
          isValid={validation.phoneValid}
          validationMessage="Translation.Feature.ManageAccount.Phone.Input.validationMessage"
          defaultValidationMessage="Please provide a valid phone number"
          enterAction={_onSave}
          name="tel"
          id="tel"
          maxValue={30}
        />
        <Label defaultText="VAT number">Translation.Feature.ManageAccount.VAT.label</Label>
        <Input
          className={styles.input}
          value={_account.vat}
          onChange={onVatChanged}
          maxValue={20}
        />
      </Container>
      <Container
        className={clsx(
          styles.accountContainer,
          validation.loginCredentialsValid === false ? styles.containerInvalid : null
        )}
      >
        <H3 className={styles.accountHeader} defaultText="Login credentials">
          Translation.Feature.ManageAccount.LoginCredentials.header
        </H3>
        <Label defaultText="Email">Translation.Feature.ManageAccount.Email.label</Label>
        <Input
          className={styles.input}
          value={_account.email}
          onChange={onEmailChanged}
          placeHolder="Translation.Feature.ManageAccount.Email.Input.placeholder"
          defaultPlaceHolder="Enter email address"
          isValid={validation.emailValid}
          validationMessage="Translation.Feature.ManageAccount.Email.Input.validationMessage"
          defaultValidationMessage="Please provide a valid email"
          enterAction={_onSave}
          name="email"
          id="email"
          type="email"
          maxValue={50}
        />
        <Label defaultText="Password">Translation.Feature.ManageAccount.Password.label</Label>
        <Input
          className={styles.input}
          value={_account.password}
          onChange={onPasswordChanged}
          placeHolder="Translation.Feature.ManageAccount.Password.Input.placeholder"
          defaultPlaceHolder="Enter password"
          isValid={validation.passwordValid}
          validationMessage="Translation.Feature.ManageAccount.Password.Input.validationMessage"
          defaultValidationMessage="Password needs to be at least 6 characters long"
          enterAction={_onSave}
          name="new-password"
          autoComplete="new-password"
          password
          maxValue={50}
        />
      </Container>
      <Container
        className={clsx(
          styles.accountContainer,
          validation.settingsValid === false ? styles.containerInvalid : null
        )}
      >
        <H3 className={styles.accountHeader} defaultText="Settings">
          Translation.Feature.ManageAccount.Settings.header
        </H3>
        <Label defaultText="Time zone">Translation.Feature.ManageAccount.Timezone.label</Label>
        <Select
          options={timezones}
          onChange={onTimezoneChanged}
          value={_account.timezone}
          isValid={validation.timezoneValid}
          validationMessage="Translation.Feature.ManageAccount.Timezone.Select.validationMessage"
          defaultValidationMessage="Please select a timezone"
          portal
        />
      </Container>
    </div>,
    <Button
      key="saveButton"
      secondary
      defaultText="Save"
      className={styles.saveButton}
      onClick={_onSave}
    >
      Translation.Feature.ManageAccount.SaveButton.label
    </Button>
  ];
}

ManageAccount.propTypes = {
  className: string,
  busy: bool.isRequired,
  account: accountType.isRequired,
  onSave: func.isRequired,
  countries: arrayOf(countryType).isRequired,
  timezones: arrayOf(timezoneType).isRequired
};

ManageAccount.defaultProps = {
  className: null
};

export default ManageAccount;
