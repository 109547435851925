import { createReducer } from '@reduxjs/toolkit';
import { getAccountName, getUserName } from 'utils/cookieReader';
import sessionActions from 'services/session/actions';
import orderActions from './actions';

const initialState = {
  currentOrder: {
    busy: false,
    getQuotesCriteria: {},
    quotes: [],
    selectedQuote: null,
    documents: [],
    translationInstructions: '',
    oldQuoteIds: [],
    reference: getUserName() || getAccountName(),
  },
  existingOrders: {
    busy: false,
    orders: [],
  },
};

const orderReducer = createReducer(initialState, {
  [orderActions.getQuotesRequest]: (state, action) => {
    const order = state;
    const { currentOrder } = order;
    currentOrder.busy = true;
    currentOrder.quotes = [];
    if (
      currentOrder.selectedQuote &&
      !currentOrder.oldQuoteIds.includes(currentOrder.selectedQuote.id)
    ) {
      currentOrder.oldQuoteIds.push(currentOrder.selectedQuote.id);
    }
    currentOrder.selectedQuote = null;
    currentOrder.getQuotesCriteria = action.payload;
  },
  [orderActions.getQuotesSuccess]: (state, action) => {
    const order = state;
    const { currentOrder } = order;
    currentOrder.busy = false;
    currentOrder.quotes = action.payload;
  },
  [orderActions.getQuotesFailed]: (state, action) => {
    const order = state;
    const { currentOrder } = order;
    currentOrder.busy = false;
    currentOrder.quotes = [
      {
        error: {
          defaultTranslation: 'Something went wrong. Failed to get quote',
          translationKey: 'Translation.Api.GetQuotes.Quote.Error.label',
        },
        translationType: 'p',
      },
      {
        error: {
          defaultTranslation: 'Something went wrong. Failed to get quote',
          translationKey: 'Translation.Api.GetQuotes.Quote.Error.label',
        },
        translationType: 't',
      },
      {
        error: {
          defaultTranslation: 'Something went wrong. Failed to get quote',
          translationKey: 'Translation.Api.GetQuotes.Quote.Error.label',
        },
        translationType: 'r',
      },
    ];
    console.log('GET QUOTES FAILED', action.payload);
  },
  [orderActions.selectQuote]: (state, action) => {
    const order = state;
    const { currentOrder } = order;
    if (
      currentOrder.selectedQuote &&
      currentOrder.selectedQuote.id !== action.payload.id &&
      !currentOrder.oldQuoteIds.includes(currentOrder.selectedQuote.id)
    ) {
      currentOrder.oldQuoteIds.push(currentOrder.selectedQuote.id);
    }
    currentOrder.selectedQuote = action.payload;
  },
  [orderActions.uploadDocumentsRequest]: (state, action) => {
    const order = state;
    const { currentOrder } = order;
    currentOrder.busy = true;
    const documents = action.payload.map((d) => {
      return {
        lastModified: d.lastModified,
        lastModifiedDate: d.lastModifiedDate,
        name: d.name,
        path: d.path,
        size: d.size,
        type: d.type,
        webkitRelativePath: d.webkitRelativePath,
        uploadPercentage: 0,
      };
    });
    currentOrder.documents = [...currentOrder.documents, ...documents];
  },
  [orderActions.uploadDocumentsSuccess]: (state, action) => {
    const order = state;
    const { currentOrder } = order;
    const { payload } = action;
    const { document, response, quoteId } = payload;
    currentOrder.busy = false;
    const documentsCopy = [...currentOrder.documents];
    const currentDocument = documentsCopy.find(
      (d) => d.path === document.path && d.name === document.name && d.size === document.size
    );
    currentDocument.uploadPercentage = 100;
    currentDocument.id = response.id;
    currentDocument.quoteId = quoteId;
    currentOrder.documents = documentsCopy;
  },
  [orderActions.uploadDocumentsFailed]: (state, action) => {
    const order = state;
    const { currentOrder } = order;
    const { payload } = action;
    currentOrder.documents = currentOrder.documents.filter(
      (d) => d.path !== payload.path || d.name !== payload.name || d.size !== payload.size
    );
    currentOrder.busy = false;
    console.log('UPLOAD DOCUMENTS FAILED', action.payload);
  },
  [orderActions.updateDocumentUploadPercentage]: (state, action) => {
    const order = state;
    const { currentOrder } = order;
    const { payload } = action;
    const { document, uploadPercentage } = payload;
    const documentsCopy = [...currentOrder.documents];
    const currentDocument = documentsCopy.find(
      (d) => d.path === document.path && d.name === document.name && d.size === document.size
    );
    currentDocument.uploadPercentage = uploadPercentage;
    currentOrder.documents = documentsCopy;
  },
  [orderActions.removeDocumentRequest]: (state) => {
    const order = state;
    const { currentOrder } = order;
    currentOrder.busy = true;
  },
  [orderActions.removeDocumentSuccess]: (state, action) => {
    const order = state;
    const { currentOrder } = order;
    const { payload } = action;
    currentOrder.documents = currentOrder.documents.filter(
      (d) => d.path !== payload.path || d.name !== payload.name || d.size !== payload.size
    );
    currentOrder.busy = false;
  },
  [orderActions.removeDocumentFailed]: (state, action) => {
    const order = state;
    const { currentOrder } = order;
    currentOrder.busy = false;
    console.log('REMOVE DOCUMENT FAILED', action.payload);
  },
  [orderActions.clearCurrentOrder]: (state) => {
    const order = state;
    order.currentOrder = initialState.currentOrder;
  },
  [orderActions.getExistingOrdersRequest]: (state) => {
    const order = state;
    const { existingOrders } = order;
    existingOrders.busy = true;
  },
  [orderActions.getExistingOrdersSuccess]: (state, action) => {
    const order = state;
    const { existingOrders } = order;
    existingOrders.busy = false;
    existingOrders.orders = action.payload.map((o) => ({ ...o, placed: new Date(o.placed) }));
  },
  [orderActions.getExistingOrdersFailed]: (state, action) => {
    const order = state;
    const { existingOrders } = order;
    existingOrders.busy = false;
    console.log('GET EXISTING ORDERS FAILED', action.payload);
  },
  [orderActions.changeTranslationInstructions]: (state, action) => {
    const order = state;
    const { currentOrder } = order;
    currentOrder.translationInstructions = action.payload;
  },
  [orderActions.changeReference]: (state, action) => {
    const order = state;
    const { currentOrder } = order;
    currentOrder.reference = action.payload;
  },
  [sessionActions.loginSucceeded]: (state) => {
    const order = state;
    const { currentOrder } = order;
    currentOrder.reference = getUserName() || getAccountName();
  },
});

export default orderReducer;
