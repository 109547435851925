import React, { useRef, useState, useEffect } from 'react';
import { string, node, func, bool } from 'types';
import { gsap } from 'gsap';
import clsx from 'clsx';
import { H4 } from 'components';
import { Loader } from 'assets';
import styles from './Modal.module.scss';

function Modal({ className, children, ...props }) {
  const {
    isOpen,
    hideOnBackDropClick,
    onClosed,
    title,
    defaultTitle,
    translateTitle,
    busy,
    backdropStyle,
  } = props;
  const classNames = clsx(className, styles.root);
  const [_isOpen, setIsOpen] = useState(isOpen);
  const [animationState, setAnimationState] = useState({ isAnimating: false, state: 0 });
  const modalRef = useRef(null);
  const loadingContainerRef = useRef(null);
  const contentContainerRef = useRef(null);
  const backdropRef = useRef(null);

  useEffect(() => {
    if (modalRef.current) {
      if (isOpen) {
        showModal();
      } else if (!isOpen && _isOpen === true) {
        hideModal();
      }

      setIsOpen(isOpen);
    }
  }, [isOpen]);

  useEffect(() => {
    if (busy === true && isOpen && (animationState.state === 2 || animationState.state === 1)) {
      const timeline = gsap.timeline({
        paused: true,
        onComplete: () => setAnimationState({ isAnimating: false, state: 4 }),
      });
      timeline
        .to(
          contentContainerRef.current,
          0.5,
          {
            opacity: 0,
            visibility: 'hidden',
          },
          0
        )
        .to(
          loadingContainerRef.current,
          0.5,
          {
            opacity: 1,
            delay: 0.5,
            visibility: 'visible',
          },
          0
        );
      setAnimationState({ isAnimating: true, state: 3 });
      timeline.play();
    } else if (
      busy === false &&
      isOpen &&
      (animationState.state === 4 || animationState.state === 2 || animationState.state === 1)
    ) {
      const timeline = gsap.timeline({
        paused: true,
        onComplete: () => setAnimationState({ isAnimating: false, state: 6 }),
      });

      timeline
        .to(
          loadingContainerRef.current,
          0.5,
          {
            opacity: 0,
            visibility: 'hidden',
          },
          0
        )
        .to(
          contentContainerRef.current,
          0.5,
          {
            opacity: 1,
            delay: 0.3,
            visibility: 'visible',
          },
          0
        );

      setAnimationState({ isAnimating: true, state: 5 });
      timeline.play();
    }
  }, [busy, animationState]);

  const showModal = () => {
    const timeline = gsap.timeline({
      paused: true,
      onComplete: () => setAnimationState({ isAnimating: false, state: 2 }),
    });
    timeline.to(modalRef.current, 0.3, {
      display: 'block',
      maxWidth: '65%',
      maxHeight: '50%',
      onComplete: () => {
        modalRef.current.style.overflowY = 'auto';
      },
    });

    timeline.to(
      backdropRef.current,
      0.6,
      {
        opacity: 0.7,
        display: 'block',
      },
      0
    );

    setAnimationState({ isAnimating: true, state: 1 });

    timeline.play();
  };

  const hideModal = () => {
    const timeline = gsap.timeline({
      paused: true,
      onComplete: () => setAnimationState({ isAnimating: false, state: 0 }),
    });

    timeline
      .to(contentContainerRef.current, 0.5, {
        opacity: 0,
      })
      .to(
        modalRef.current,
        0.3,
        {
          display: 'none',
          maxWidth: 0,
          maxHeight: 0,
          delay: 0.3,
          overflowY: 'hidden',
        },
        0
      )
      .to(
        backdropRef.current,
        0.6,
        {
          opacity: 0,
          display: 'none',
          onComplete: () => {
            if (onClosed) {
              onClosed();
            }
          },
        },
        0
      );

    setAnimationState({ isAnimating: true, state: 7 });
    timeline.play();
  };

  const onBackdropClick = () => {
    if (hideOnBackDropClick) {
      hideModal();
    }
  };

  return [
    <div key="1" className={classNames} ref={modalRef}>
      <div
        className={clsx(styles.loadingContainer, busy ? styles.show : styles.hide)}
        ref={loadingContainerRef}
      >
        <Loader />
      </div>
      <div
        className={clsx(styles.contentContainer, busy ? styles.hide : styles.show)}
        ref={contentContainerRef}
      >
        {title ? (
          <div className={styles.titleContainer}>
            <H4
              className={styles.title}
              defaultText={defaultTitle}
              translate={translateTitle}
              upperCase
              bold
            >
              {title}
            </H4>
          </div>
        ) : null}
        {children}
      </div>
    </div>,
    <div
      role="presentation"
      key="2"
      className={clsx(backdropStyle, styles.backdrop)}
      ref={backdropRef}
      onClick={onBackdropClick}
    />,
  ];
}

Modal.propTypes = {
  className: string,
  children: node,
  isOpen: bool,
  hideOnBackDropClick: bool,
  onClosed: func,
  title: string,
  defaultTitle: string,
  translateTitle: bool,
  busy: bool,
  backdropStyle: string,
};

Modal.defaultProps = {
  className: null,
  children: null,
  isOpen: false,
  hideOnBackDropClick: true,
  onClosed: null,
  title: null,
  defaultTitle: '',
  translateTitle: true,
  busy: false,
  backdropStyle: null,
};

export default Modal;
