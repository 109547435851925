import React from 'react';
import { string, func } from 'types';
import clsx from 'clsx';
import { B1, Button } from 'components';
import styles from './RegistrationFailed.module.scss';

function RegistrationFailed(props) {
  const { className, onConfirm, bodyTranslationKey, bodyDefaultText } = props;
  const classNames = clsx(className, styles.root);

  return (
    <div className={classNames}>
      <B1 defaultText={bodyDefaultText}>{bodyTranslationKey}</B1>
      <Button className={styles.confirmButton} onClick={onConfirm} defaultText="Confirm">
        Translation.Feature.CreateAccount.RegistrationFailed.Confirmation.Button.label
      </Button>
    </div>
  );
}

RegistrationFailed.propTypes = {
  className: string,
  onConfirm: func.isRequired,
  bodyTranslationKey: string.isRequired,
  bodyDefaultText: string.isRequired
};

RegistrationFailed.defaultProps = {
  className: null
};

export default RegistrationFailed;
