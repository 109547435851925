import React, { useState } from 'react';
import { string, func, bool } from 'types';
import clsx from 'clsx';
import { B1, Link, Label, Input, Button, Modal, Container } from 'components';
import isEmailValid from 'utils/emailValidator';
import { ForgotPasswordConfirmation } from './components';
import styles from './ForgotPassword.module.scss';

function ForgotPassword(props) {
  const {
    className,
    onForgotPassword,
    showConfirmation,
    onConfirm,
    onClosedConfirmation,
    onRememberPassword
  } = props;
  const classNames = clsx(className, styles.root);
  const [email, setEmail] = useState(null);
  const [emailValid, setEmailValid] = useState(null);

  const _onForgotPassword = () => {
    if (validate()) {
      onForgotPassword(email);
    }
  };

  const validate = () => {
    const validEmail = isEmailValid(email);
    setEmailValid(validEmail);
    return validEmail;
  };

  const emailChanged = value => {
    setEmail(value);
  };

  return [
    <Container key="1" className={classNames}>
      <div>
        <B1
          className={styles.instructionsText}
          defaultText="Enter the email address associated with your Reword Account and we will send you instructions to reset your password."
        >
          Translation.Feature.ForgotPassword.Instructions.label
        </B1>
        <Label defaultText="Email address">Translation.Feature.ForgotPassword.Email.label</Label>
        <Input
          className={styles.emailInput}
          placeHolder="Translation.Feature.ForgotPassword.Input.placeholder"
          defaultPlaceHolder="Enter your email address"
          onChange={emailChanged}
          isValid={emailValid}
          defaultValidationMessage="Please provide a valid email"
          validationMessage="Translation.Feature.ForgotPassword.Input.validationMessage"
          enterAction={_onForgotPassword}
          name="email"
          id="email"
          type="email"
          autoFocus
          autoComplete="email"
          maxValue={50}
        />
        <B1 className={styles.bodyText} defaultText="I remember now. Take me">
          Translation.Feature.ForgotPassword.RememberLink.label
        </B1>
        <Link
          bolder
          className={styles.link}
          onClick={onRememberPassword}
          defaultText="to the login page."
        >
          Translation.Feature.ForgotPassword.RememberLink.link
        </Link>
        <div className={styles.buttonContainer}>
          <Button onClick={_onForgotPassword} defaultText="Send" disabled={!isEmailValid(email)}>
            Translation.Feature.ForgotPassword.SendButton.label
          </Button>
        </div>
      </div>
    </Container>,
    <Modal
      key="2"
      isOpen={showConfirmation}
      onClosed={onClosedConfirmation}
      title="Translation.Feature.ForgotPassword.Modal.title"
      defaultTitle="Reset link successfully sent"
      hideOnBackDropClick={false}
    >
      <ForgotPasswordConfirmation onConfirm={onConfirm} />
    </Modal>
  ];
}

ForgotPassword.propTypes = {
  className: string,
  onForgotPassword: func.isRequired,
  showConfirmation: bool.isRequired,
  onConfirm: func.isRequired,
  onClosedConfirmation: func.isRequired,
  onRememberPassword: func.isRequired
};

ForgotPassword.defaultProps = {
  className: null
};

export default ForgotPassword;
