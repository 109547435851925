import React from 'react';
import { string, func } from 'types';
import clsx from 'clsx';
import { B1, Button } from 'components';
import styles from './RegisterFailed.module.scss';

function RegisterFailed(props) {
  const { className, onLogin } = props;
  const classNames = clsx(className, styles.root);

  return (
    <div className={classNames}>
      <B1 defaultText="Account already registered. Please proceed to login page.">
        Translation.Feature.Register.RegisterFailed.header
      </B1>
      <Button className={styles.loginButton} onClick={onLogin} defaultText="Login">
        Translation.Feature.Register.RegisterFailed.Button.label
      </Button>
    </div>
  );
}

RegisterFailed.propTypes = {
  className: string,
  onLogin: func.isRequired
};

RegisterFailed.defaultProps = {
  className: null
};

export default RegisterFailed;
