import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { orderStateType, orderActionsType, sessionStateType } from 'types';
import orderActions from 'services/order/actions';
import SelectQuote from '.';

function SelectQuoteContainer(props) {
  const {
    orderState: {
      currentOrder: {
        busy,
        quotes,
        documents,
        getQuotesCriteria: { sourceLanguage, targetLanguages, wordCount, translationSubject },
      },
    },
    actions,
    sessionState: { userLoggedIn },
  } = props;

  const [navigationUrl, setNavigationUrl] = useState();

  if (!sourceLanguage && !navigationUrl) {
    setNavigationUrl('/get-quotes');
  }

  const onGoBack = () => {
    setNavigationUrl('/get-quotes');
  };

  const onLogin = () => {
    setNavigationUrl('/login');
  };

  const onSelectQuote = (selectedQuote) => {
    if (userLoggedIn) {
      actions.selectQuote(selectedQuote);
      setNavigationUrl('/add-documents');
    }
  };

  if (navigationUrl) {
    return <Redirect to={navigationUrl} />;
  }

  return (
    <SelectQuote
      quotes={quotes}
      busy={busy}
      onLogin={onLogin}
      onSelectQuote={onSelectQuote}
      sourceLanguage={sourceLanguage}
      targetLanguages={targetLanguages}
      wordCount={wordCount}
      onGoBack={onGoBack}
      userLoggedIn={userLoggedIn}
      translationSubject={translationSubject}
      documents={documents}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    orderState: state.order,
    sessionState: state.session,
  };
};

const mapDispatch = (dispatch) => ({
  actions: bindActionCreators({ ...orderActions }, dispatch),
});

SelectQuoteContainer.propTypes = {
  orderState: orderStateType.isRequired, // eslint-disable-line react/no-unused-prop-types
  actions: orderActionsType.isRequired,
  sessionState: sessionStateType.isRequired, // eslint-disable-line react/no-unused-prop-types
};

export default connect(mapStateToProps, mapDispatch)(SelectQuoteContainer);
