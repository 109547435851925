import React from 'react';
import { useIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { string, bool, historyType } from 'types';
import AdminSelect from '.';

function AdminSelectContainer(props) {
  const { useDefault, className, history } = props;
  const intl = useIntl();

  const options = [
    {
      label: 'Translation.Feature.AdminSelect.OrdersOption.label',
      defaultLabel: 'ORDERS',
      action: () => history.push('/admin/orders'),
      value: 0
    },
    {
      label: 'Translation.Feature.AdminSelect.AccountsOption.label',
      defaultLabel: 'ACCOUNTS',
      action: () => history.push('/admin/accounts'),
      value: 1
    },
    {
      label: 'Translation.Feature.AdminSelect.SettingsOption.label',
      defaultLabel: 'SETTINGS',
      action: () => history.push('/admin/settings'),
      value: 2
    }
  ];

  return (
    <AdminSelect
      options={options}
      useDefault={useDefault}
      className={className}
      placeHolder={intl.formatMessage({
        id: 'Translation.Feature.AdminSelect.placeholder',
        defaultMessage: 'ADMIN'
      })}
      pathname={history.location.pathname}
    />
  );
}

AdminSelectContainer.propTypes = {
  className: string,
  useDefault: bool,
  history: historyType.isRequired
};

AdminSelectContainer.defaultProps = {
  className: null,
  useDefault: true
};

export default withRouter(AdminSelectContainer);
