import React, { useState } from 'react';
import { string, func, bool } from 'types';
import clsx from 'clsx';
import { H4, B2, Link, Label, Input, Button, Container } from 'components';
import styles from './Login.module.scss';

function Login(props) {
  const { className, onForgotPassword, onLogin, loginFailed } = props;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const classNames = clsx(className, styles.root);

  const emailChanged = value => {
    setEmail(value);
  };

  const passwordChanged = value => {
    setPassword(value);
  };

  const _onLogin = () => {
    if (email && password) {
      onLogin(email, password);
    }
  };

  return (
    <Container className={classNames}>
      <div>
        <div className={styles.registerContainer}>
          <H4 className={styles.header} bold upperCase defaultText="Already registered?">
            Translation.Feature.Login.AlreadyRegistered.label
          </H4>
        </div>
        <form autoComplete="on">
          <Label defaultText="Email address">Translation.Feature.Login.Email.label</Label>
          <Input
            className={styles.emailInput}
            placeHolder="Translation.Feature.Login.Email.Input.placeholder"
            defaultPlaceHolder="Enter your email address"
            name="email"
            id="email"
            type="email"
            autoFocus
            onChange={emailChanged}
            enterAction={_onLogin}
            autoComplete="email"
            maxValue={50}
          />
          <Label defaultText="Password">Translation.Feature.Login.Password.label</Label>
          <Input
            className={styles.passwordInput}
            placeHolder="Translation.Feature.Login.Password.Input.placeholder"
            defaultPlaceHolder="Enter your password"
            password
            id="current-password"
            name="current-password"
            autoComplete="current-password"
            onChange={passwordChanged}
            enterAction={_onLogin}
            maxValue={50}
          />
        </form>
        <div className={styles.validationMessageContainer}>
          {loginFailed ? (
            <B2
              className={styles.validationMessage}
              defaultText="You have provided wrong email or password for your account"
            >
              Translation.Feature.Login.ValidationMessage.label
            </B2>
          ) : null}
        </div>
        <div className={styles.buttonContainer}>
          <Button onClick={_onLogin} defaultText="Sign in" disabled={!email || !password}>
            Translation.Feature.Login.SignIn.Button.label
          </Button>
          <Link
            className={styles.forgotPasswordLink}
            onClick={onForgotPassword}
            defaultText="Forgot password?"
          >
            Translation.Feature.Login.ForgotPassword.label
          </Link>
        </div>
      </div>
    </Container>
  );
}

Login.propTypes = {
  className: string,
  onForgotPassword: func.isRequired,
  onLogin: func.isRequired,
  loginFailed: bool.isRequired
};

Login.defaultProps = {
  className: null
};

export default Login;
