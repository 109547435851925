import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { historyType } from 'types';
import { forgotPassword } from 'services/account';
import ForgotPassword from '.';

function ForgotPasswordContainer(props) {
  const { history } = props;
  const [showConfirmation, setShowConfirmation] = useState(false);

  const onForgotPassword = email => {
    forgotPassword(email).then(() => setShowConfirmation(true));
  };

  const onConfirm = () => {
    setShowConfirmation(false);
  };

  const onClosedConfirmation = () => {
    history.push('/');
  };

  const onRememberPassword = () => {
    history.push('/login');
  };

  return (
    <ForgotPassword
      {...props}
      onForgotPassword={onForgotPassword}
      showConfirmation={showConfirmation}
      onConfirm={onConfirm}
      onClosedConfirmation={onClosedConfirmation}
      onRememberPassword={onRememberPassword}
    />
  );
}

ForgotPasswordContainer.propTypes = {
  history: historyType.isRequired
};

export default withRouter(ForgotPasswordContainer);
