import { createReducer } from '@reduxjs/toolkit';
import accountActions from './actions';

const initialState = {
  busy: false
};

const accountReducer = createReducer(initialState, {
  [accountActions.registerAccountRequest]: state => {
    const account = state;
    account.busy = true;
  },
  [accountActions.registerAccountSuccess]: state => {
    const account = state;
    account.busy = false;
  },
  [accountActions.registerAccountFailed]: state => {
    const account = state;
    account.busy = false;
  },
  [accountActions.createAccountRequest]: state => {
    const account = state;
    account.busy = true;
    account.createAccountError = null;
  },
  [accountActions.createAccountSuccess]: state => {
    const account = state;
    account.busy = false;
  },
  [accountActions.createAccountFailed]: (state, action) => {
    const account = state;
    account.busy = false;
    account.createAccountError = action.payload;
  }
});

export default accountReducer;
