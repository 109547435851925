import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { adminSettingsStateType } from 'types';
import { getSettings, updateSettings } from 'services/admin';
import { toastSuccess, toastError } from 'utils/Toaster';
import AdminSettings from '.';

function AdminSettingsContainer(props) {
  const {
    adminSettingsState: { busy, mail, reword, translation }
  } = props;

  useEffect(() => {
    if (!busy) {
      getSettings();
    }
  }, []);

  const onSave = settings => {
    updateSettings(settings)
      .then(() => {
        toastSuccess(
          'Translation.Feature.AdminSettings.UpdateSuccess.toastLabel',
          'SETTINGS UPDATED'
        );
      })
      .catch(error => {
        toastError(
          'Translation.Feature.AdminSettings.UpdateFailed.toastLabel',
          'SETTINGS UPDATE FAILED'
        );
      });
  };

  return (
    <AdminSettings
      mail={mail}
      reword={reword}
      translation={translation}
      busy={busy}
      onSave={onSave}
    />
  );
}

AdminSettingsContainer.propTypes = {
  adminSettingsState: adminSettingsStateType.isRequired // eslint-disable-line react/no-unused-prop-types
};

const mapStateToProps = state => ({
  adminSettingsState: state.admin.adminSettings
});

export default connect(mapStateToProps)(AdminSettingsContainer);
