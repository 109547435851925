import React from 'react';
import clsx from 'clsx';
import { string, number } from 'types';
import styles from './Loader.module.scss';

const Loader = props => {
  const { height, width, className } = props;
  const classNames = clsx(styles.root, className);

  return (
    <div className={classNames}>
      <svg className={styles.spinner} width={width} height={height} viewBox="0 0 66 66">
        <circle
          className={styles.path}
          fill="none"
          strokeWidth="6"
          strokeLinecap="round"
          cx="33"
          cy="33"
          r="30"
        />
      </svg>
    </div>
  );
};

Loader.propTypes = {
  className: string,
  height: number,
  width: number
};

Loader.defaultProps = {
  className: null,
  height: 65,
  width: 65
};

export default Loader;
