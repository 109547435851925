import api from '../api';
import store from '../../store';
import configurationActions from './actions';

const configurationEndpoint = 'configuration';
const quoteEndpoint = 'quotes';

const getAccountConfiguration = () => {
  store.dispatch(configurationActions.getAccountConfigurationRequest());
  return api
    .get(`${configurationEndpoint}/account`)
    .then((response) =>
      store.dispatch(configurationActions.getAccountConfigurationSuccess(response.data))
    )
    .catch((error) => {
      store.dispatch(configurationActions.getAccountConfigurationFailed(error));
      throw error;
    });
};

const getQuoteConfiguration = () => {
  store.dispatch(configurationActions.getQuoteConfigurationRequest());
  return api
    .get(`${configurationEndpoint}/quote`)
    .then((response) =>
      store.dispatch(configurationActions.getQuoteConfigurationSuccess(response.data))
    )
    .catch((error) => {
      store.dispatch(configurationActions.getQuoteConfigurationFailed(error));
      throw error;
    });
};

const getApplicationConfiguration = () => {
  store.dispatch(configurationActions.getApplicationConfigurationRequest());
  return api
    .get(`${configurationEndpoint}/application`)
    .then((response) =>
      store.dispatch(configurationActions.getApplicationConfigurationSuccess(response.data))
    )
    .catch((error) => {
      store.dispatch(configurationActions.getApplicationConfigurationFailed(error));
      throw error;
    });
};

const getOrderConfiguration = () => {
  store.dispatch(configurationActions.getOrderConfigurationRequest());
  return api
    .get(`${configurationEndpoint}/order`)
    .then((response) =>
      store.dispatch(configurationActions.getOrderConfigurationSuccess(response.data))
    )
    .catch((error) => {
      store.dispatch(configurationActions.getOrderConfigurationFailed(error));
      throw error;
    });
};

const getAdminConfiguration = () => {
  store.dispatch(configurationActions.getAdminConfigurationRequest());

  return api
    .get(`${configurationEndpoint}/admin`)
    .then((response) =>
      store.dispatch(configurationActions.getAdminConfigurationSuccess(response.data))
    )
    .catch((error) => store.dispatch(configurationActions.getAdminConfigurationFailed(error)));
};

const getStatsConfiguration = () => {
  return api
    .get(`${configurationEndpoint}/stats`)
    .then((response) =>
      store.dispatch(configurationActions.getStatsConfigurationSuccess(response.data))
    )
    .catch((error) => null);
};

const getFaqConfiguration = () => {
  return api
    .get(`${configurationEndpoint}/faq`)
    .then((response) =>
      store.dispatch(configurationActions.getFaqConfigurationSuccess(response.data))
    )
    .catch((error) => null);
};

const changeLanguage = (selectedLanguage) => {
  store.dispatch(configurationActions.changeLanguage(selectedLanguage));
};

const deleteOrderDocument = (orderId, documentId) => {
  return api.delete(`${quoteEndpoint}/${orderId}/files/${documentId}`);
};

const uploadDocument = (orderId, originalFileId, translationLanguage, file) => {
  const formData = new FormData();
  formData.append('file', file);
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };

  return api.put(
    `${quoteEndpoint}/${orderId}/files/${originalFileId}/append/${encodeURIComponent(
      translationLanguage
    )}`,
    formData,
    config
  );
};

export {
  getAccountConfiguration,
  getQuoteConfiguration,
  getApplicationConfiguration,
  getOrderConfiguration,
  getAdminConfiguration,
  getStatsConfiguration,
  getFaqConfiguration,
  changeLanguage,
  deleteOrderDocument,
  uploadDocument,
};
