export const CURRENCY_FORMAT = {
  style: 'currency',
  currency: 'sek',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
};

export const getCurrencyFormat = (currency) => {
  return {
    style: 'currency',
    currency: currency || 'sek',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  };
};

export const NUMBER_FORMAT = {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
};

export const FEATURES = {
  deleteTranslationDocuments: 'DELETE_TRANSLATION_DOCUMENTS',
};

export const FEATURES_ARRAY = () => {
  return Object.keys(FEATURES).map((k) => FEATURES[k]);
};
