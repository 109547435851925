import React from 'react';
import { string, node, bool } from 'types';
import clsx from 'clsx';
import styles from './Container.module.scss';

function Container({ className, children, fullWidth, ...props }) {
  const { backgroundColor, borderColor, separators } = props;
  const classNames = clsx(className, styles.root, fullWidth && styles.fullWidth);

  const renderContent = () => {
    const style = {
      borderColor: borderColor || styles.grayBrightColor,
      backgroundColor: backgroundColor || styles.whiteColor
    };

    if (Array.isArray(children) && separators) {
      const content = children.map((section, index) => {
        const isLast = index === children.length - 1;
        const key = index;
        return (
          <div
            key={key}
            className={clsx(styles.padding, !isLast ? styles.borderBottom : null)}
            style={style}
          >
            {section}
          </div>
        );
      });

      return (
        <div className={classNames} style={style}>
          {content}
        </div>
      );
    }

    return (
      <div className={clsx(classNames, styles.padding)} style={style}>
        {children}
      </div>
    );
  };

  return renderContent();
}

Container.propTypes = {
  className: string,
  children: node,
  backgroundColor: string,
  borderColor: string,
  separators: bool,
  fullWidth: bool
};

Container.defaultProps = {
  className: null,
  children: null,
  backgroundColor: styles.whiteColor,
  borderColor: styles.grayBrightColor,
  separators: false,
  fullWidth: false
};

export default Container;
