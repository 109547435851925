import React, { useState, useRef } from 'react';
import clsx from 'clsx';
import { gsap } from 'gsap';
import '@formatjs/intl-listformat/polyfill';
import '@formatjs/intl-listformat/dist/locale-data/de';
import '@formatjs/intl-listformat/dist/locale-data/sv';
import { useIntl, FormattedMessage } from 'react-intl';
import { H3, S1, B1, Link, Button, TextArea, CheckBox, Input } from 'components';
import { currentOrderStateType, func, string, bool } from 'types';
import { Loader, NUMBER_FORMAT, PoliciesPdf } from 'assets';
import { ConfirmOrder } from './components';
import styles from './OrderSummary.module.scss';

function OrderSummary(props) {
  const {
    className,
    order,
    onGoBack,
    onPlaceOrder,
    showOrderReceived,
    onTranslationInstructionsChanged,
    onReferenceChanged,
    onConfirmOrder,
    busy,
  } = props;
  const { documents, getQuotesCriteria, selectedQuote, translationInstructions, reference } = order;
  const { sourceLanguage, targetLanguages, wordCount, translationSubject } = getQuotesCriteria;
  const { total, delivery_date } = selectedQuote; // eslint-disable-line camelcase
  const [canPlaceOrder, setCanPlaceOrder] = useState(false);
  // const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const loadingContainerRef = useRef(null);
  const orderSummaryContainerRef = useRef(null);
  const confirmationContainerRef = useRef(null);
  const classNames = clsx(className, styles.root);
  const intl = useIntl();
  const timeline = gsap.timeline({ paused: true });

  const onTermsChange = (checked) => {
    setCanPlaceOrder(checked);
  };

  const fade = (state) => {
    if (state === 1) {
      timeline
        .to(orderSummaryContainerRef.current, 0.5, {
          opacity: 0,
          display: 'none',
        })
        .to(loadingContainerRef.current, 0.5, {
          opacity: 1,
          display: 'block',
          delay: 0,
        });
    } else if (state === 2) {
      timeline
        .to(loadingContainerRef.current, 0.5, {
          opacity: 0,
          display: 'none',
        })
        .to(confirmationContainerRef.current, 0.5, {
          opacity: 1,
          display: 'block',
          delay: 0,
        });
    }

    timeline.play();
  };

  if (busy) {
    fade(1);
  }

  if (showOrderReceived) {
    fade(2);
  }

  const getSubHeaderTextAndValues = () => {
    const textAndValues = {
      values: {
        b: (...chunks) => <b>{chunks}</b>,
        numberOfDocuments: documents.length,
        wordCount: wordCount ? intl.formatNumber(wordCount, NUMBER_FORMAT) : '#',
        sourceLanguage: intl.formatMessage({ id: sourceLanguage.label }),
        targetLanguages: intl.formatList(
          targetLanguages.map((tl) => intl.formatMessage({ id: tl.label })),
          { type: 'conjunction' }
        ),
      },
    };

    if (translationSubject.value !== '1') {
      textAndValues.values.subject = intl.formatMessage({ id: translationSubject.label });
      if (documents.length !== 1) {
        textAndValues.defaultMessage =
          'Translation of <b>{numberOfDocuments}</b> documents with <b>{wordCount}</b> words and with subject, {subject}, from <b>{sourceLanguage}</b> to <b>{targetLanguages}</b>.';
        textAndValues.id = 'Translation.Feature.OrderSummary.NumberOfDocuments.Plural.label';
      } else {
        textAndValues.defaultMessage =
          'Translation of <b>{numberOfDocuments}</b> document with <b>{wordCount}</b> words and with subject, {subject}, from <b>{sourceLanguage}</b> to <b>{targetLanguages}</b>.';
        textAndValues.id = 'Translation.Feature.OrderSummary.NumberOfDocuments.Singular.label';
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (documents.length !== 1) {
        textAndValues.defaultMessage =
          'Translation of <b>{numberOfDocuments}</b> documents with <b>{wordCount}</b> words from <b>{sourceLanguage}</b> to <b>{targetLanguages}</b>.';
        textAndValues.id =
          'Translation.Feature.OrderSummary.NumberOfDocuments.Plural.WithoutSubject.label';
      } else {
        textAndValues.defaultMessage =
          'Translation of <b>{numberOfDocuments}</b> document with <b>{wordCount}</b> words from <b>{sourceLanguage}</b> to <b>{targetLanguages}</b>.';
        textAndValues.id =
          'Translation.Feature.OrderSummary.NumberOfDocuments.Singular.WithoutSubject.label';
      }
    }

    return textAndValues;
  };

  const subHeaderTextAndValues = getSubHeaderTextAndValues();

  return (
    <div className={classNames}>
      <div
        ref={loadingContainerRef}
        className={clsx(styles.loadingContainer, showOrderReceived && styles.hide)}
      >
        <Loader className={styles.loader} />
      </div>
      <div ref={confirmationContainerRef} className={styles.confirmationContainer}>
        <ConfirmOrder onConfirm={onConfirmOrder} orderReceived={new Date()} />
      </div>
      <div ref={orderSummaryContainerRef} className={styles.orderSummaryContainer}>
        <H3 className={styles.header} bold upperCase defaultText="Order summary">
          Translation.Feature.OrderSummary.header
        </H3>
        <S1 className={styles.subHeader} translate={false}>
          <FormattedMessage
            id={subHeaderTextAndValues.id}
            defaultMessage={subHeaderTextAndValues.defaultMessage}
            values={subHeaderTextAndValues.values}
          />
        </S1>
        <div className={styles.deliveryTextContainer}>
          <S1 className={styles.subHeader} translate={false}>
            <FormattedMessage
              id="Translation.Feature.OrderSummary.Delivery.label"
              defaultMessage="A guaranteed delivery date on <b>{deliveryDate}</b> with the fixed price <b>{total}</b> (excluded taxes) which will be billed with 10 days payment terms."
              values={{
                b: (...chunks) => <b>{chunks}</b>,
                deliveryDate: `${intl.formatDate(delivery_date, {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric',
                })} ${intl.formatTime(delivery_date, {
                  hour: 'numeric',
                  minute: 'numeric',
                })}`,
                total: `SEK ${intl.formatNumber(total, NUMBER_FORMAT)}`,
              }}
            />
          </S1>
        </div>
        <div className={styles.instructionsLabelContainer}>
          <B1
            bold
            className={styles.instructionsLabel}
            defaultText="Add instructions to the translator"
          >
            Translation.Feature.OrderSummary.Instructions.label
          </B1>
          <B1 className={styles.instructionsLabel} defaultText="(source language or english)">
            Translation.Feature.OrderSummary.Instructions.Language.label
          </B1>
        </div>
        <TextArea
          className={styles.instructionsTextArea}
          value={translationInstructions}
          onChange={onTranslationInstructionsChanged}
          maxValue={600}
          placeholder="Translation.Feature.OrderSummary.Instructions.TextArea.placeholder"
          defaultPlaceholder="If you have specific instructions to the translator."
        />
        <div className={styles.referenceContainer}>
          <B1 bold className={styles.referenceLabel} defaultText="Buyer's reference">
            Translation.Feature.OrderSummary.Reference.label
          </B1>
          <B1 className={styles.referenceLabel} defaultText="(included on invoice)">
            Translation.Feature.OrderSummary.Reference.Invoice.label
          </B1>
        </div>
        <Input
          className={styles.referenceInput}
          value={reference}
          onChange={onReferenceChanged}
          maxValue={50}
        />
        <div className={styles.termsContainer}>
          <CheckBox
            className={styles.termsCheckBoxRoot}
            checkBoxClassName={styles.termsCheckBox}
            onChange={onTermsChange}
          />
          <B1 inline className={styles.termsLabel} defaultText="Yes! I have read and agree to the">
            Translation.Feature.OrderSummary.Agree.label
          </B1>
          &nbsp;
          <Link
            className={styles.termsLink}
            defaultText="Terms and conditions and Quality policy"
            // onlick={() => setShowTermsAndConditions(true)}
            href={PoliciesPdf}
            target="_blank"
          >
            Translation.Feature.OrderSummary.Agree.link
          </Link>
          {/* <Modal
            className={styles.termsAndConditionsModal}
            isOpen={showTermsAndConditions}
            defaultTitle="Terms and conditions and Quality policy"
            title="Translation.Feature.OrderSummary.Agree.Modal.title"
            hideOnBackDropClick={false}
          >
            <div className={styles.termsAndConditionsModalContentContainer}>
              <div className={styles.termsAndConditionsModalTextContainer}>
                {!!intl.messages['Translation.Feature.OrderSummary.Agree.Modal.text'] && (
                  <FormattedMessage
                    id="Translation.Feature.OrderSummary.Agree.Modal.text"
                    defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a default text."
                    values={{
                      b: (...chunks) => <b>{chunks}</b>,
                      NL: <br />,
                      nl: <br />,
                      Nl: <br />,
                    }}
                  />
                )}
                {!!intl.messages['Translation.Feature.OrderSummary.Agree.Modal2.text'] && (
                  <FormattedMessage
                    id="Translation.Feature.OrderSummary.Agree.Modal2.text"
                    defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a default text."
                    values={{
                      b: (...chunks) => <b>{chunks}</b>,
                      NL: <br />,
                      nl: <br />,
                      Nl: <br />,
                    }}
                  />
                )}
                {!!intl.messages['Translation.Feature.OrderSummary.Agree.Modal3.text'] && (
                  <FormattedMessage
                    id="Translation.Feature.OrderSummary.Agree.Modal3.text"
                    defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a default text."
                    values={{
                      b: (...chunks) => <b>{chunks}</b>,
                      NL: <br />,
                      nl: <br />,
                      Nl: <br />,
                    }}
                  />
                )}
                {!!intl.messages['Translation.Feature.OrderSummary.Agree.Modal4.text'] && (
                  <FormattedMessage
                    id="Translation.Feature.OrderSummary.Agree.Modal4.text"
                    defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a default text."
                    values={{
                      b: (...chunks) => <b>{chunks}</b>,
                      NL: <br />,
                      nl: <br />,
                      Nl: <br />,
                    }}
                  />
                )}
                {!!intl.messages['Translation.Feature.OrderSummary.Agree.Modal5.text'] && (
                  <FormattedMessage
                    id="Translation.Feature.OrderSummary.Agree.Modal5.text"
                    defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a default text."
                    values={{
                      b: (...chunks) => <b>{chunks}</b>,
                      NL: <br />,
                      nl: <br />,
                      Nl: <br />,
                    }}
                  />
                )}
                {!!intl.messages['Translation.Feature.OrderSummary.Agree.Modal6.text'] && (
                  <FormattedMessage
                    id="Translation.Feature.OrderSummary.Agree.Modal6.text"
                    defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a default text."
                    values={{
                      b: (...chunks) => <b>{chunks}</b>,
                      NL: <br />,
                      nl: <br />,
                      Nl: <br />,
                    }}
                  />
                )}
                {!!intl.messages['Translation.Feature.OrderSummary.Agree.Modal7.text'] && (
                  <FormattedMessage
                    id="Translation.Feature.OrderSummary.Agree.Modal7.text"
                    defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a default text."
                    values={{
                      b: (...chunks) => <b>{chunks}</b>,
                      NL: <br />,
                      nl: <br />,
                      Nl: <br />,
                    }}
                  />
                )}
                {!!intl.messages['Translation.Feature.OrderSummary.Agree.Modal8.text'] && (
                  <FormattedMessage
                    id="Translation.Feature.OrderSummary.Agree.Modal8.text"
                    defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a default text."
                    values={{
                      b: (...chunks) => <b>{chunks}</b>,
                      NL: <br />,
                      nl: <br />,
                      Nl: <br />,
                    }}
                  />
                )}
                {!!intl.messages['Translation.Feature.OrderSummary.Agree.Modal9.text'] && (
                  <FormattedMessage
                    id="Translation.Feature.OrderSummary.Agree.Modal9.text"
                    defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a default text."
                    values={{
                      b: (...chunks) => <b>{chunks}</b>,
                      NL: <br />,
                      nl: <br />,
                      Nl: <br />,
                    }}
                  />
                )}
                {!!intl.messages['Translation.Feature.OrderSummary.Agree.Modal10.text'] && (
                  <FormattedMessage
                    id="Translation.Feature.OrderSummary.Agree.Modal10.text"
                    defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a default text."
                    values={{
                      b: (...chunks) => <b>{chunks}</b>,
                      NL: <br />,
                      nl: <br />,
                      Nl: <br />,
                    }}
                  />
                )}
              </div>
              <Button
                className={styles.termsAndConditionsModalButton}
                onClick={() => setShowTermsAndConditions(false)}
                defaultText="Ok"
              >
                Translation.Feature.OrderSummary.Agree.Button.label
              </Button>
            </div>
          </Modal> */}
        </div>

        <div className={styles.buttonContainer}>
          <Button
            preLeftArrow
            className={styles.backButton}
            onClick={onGoBack}
            defaultText="Go back and edit"
          >
            Translation.Feature.OrderSummary.GoBackAndEdit.Button.label
          </Button>
          <Button
            preRightArrow
            secondary
            className={styles.nextButton}
            disabled={!canPlaceOrder}
            onClick={onPlaceOrder}
            defaultText="Place order"
          >
            Translation.Feature.OrderSummary.PlaceOrder.Button.label
          </Button>
        </div>
      </div>
    </div>
  );
}

OrderSummary.propTypes = {
  className: string,
  onGoBack: func.isRequired,
  onPlaceOrder: func.isRequired,
  showOrderReceived: bool.isRequired,
  order: currentOrderStateType.isRequired,
  onTranslationInstructionsChanged: func.isRequired,
  onReferenceChanged: func.isRequired,
  onConfirmOrder: func.isRequired,
  busy: bool.isRequired,
};

OrderSummary.defaultProps = {
  className: null,
};

export default OrderSummary;
