import React, { useState } from 'react';
import { string, arrayOf, adminAccountType, bool, func } from 'types';
import clsx from 'clsx';
import { Loader, FEATURES } from 'assets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { S1, B1, B2, CheckBox, Button, Modal } from 'components';
import styles from './AdminAccounts.module.scss';

function AdminAccounts(props) {
  const {
    className,
    accounts,
    busy,
    onLockAccount,
    onUnlockAccount,
    onDeleteAccount,
    onSaveAccountFeatures,
  } = props;
  const [filter, setFilter] = useState({ ongoing: false, ready: false, cancelled: false });
  const [sort, setSort] = useState({});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showAccountFeatures, setShowAccountFeatures] = useState(false);
  const [currentAccountId, setCurrentAccountId] = useState();
  const [currentAccountFeatures, setCurrentAccountFeatures] = useState();
  const classNames = clsx(className, styles.root);

  const filterChanged = (currentFilter) => {
    setFilter({ ...filter, ...currentFilter });
  };

  const filterAccounts = () => {
    return accounts;
  };

  const onSort = (column) => {
    setSort({ [column]: sort[column] ? !sort[column] : true });
  };

  const renderAccountHeaders = [
    <div key="1" className={styles.accountIdColumn}>
      <B1 defaultText="Id" bold className={clsx(styles.noMargin, styles.headerLabel)}>
        Translation.Feature.Admin.Accounts.AccountHeader.AccountId.label
      </B1>
      <FontAwesomeIcon
        icon={faChevronUp}
        onClick={() => onSort('id')}
        className={clsx(styles.sortIcon, sort.id && styles.sortDecending)}
      />
    </div>,
    <div key="2" className={styles.userNameColumn}>
      <B1 defaultText="User name" bold className={clsx(styles.noMargin, styles.headerLabel)}>
        Translation.Feature.Admin.Accounts.AccountHeader.UserName.label
      </B1>
      <FontAwesomeIcon
        icon={faChevronUp}
        onClick={() => onSort('userName')}
        className={clsx(styles.sortIcon, sort.userName && styles.sortDecending)}
      />
    </div>,
    <div key="3" className={styles.companyNameColumn}>
      <B1 defaultText="Company name" bold className={clsx(styles.noMargin, styles.headerLabel)}>
        Translation.Feature.Admin.Accounts.AccountHeader.CompanyName.label
      </B1>
      <FontAwesomeIcon
        icon={faChevronUp}
        onClick={() => onSort('companyName')}
        className={clsx(styles.sortIcon, sort.companyName && styles.sortDecending)}
      />
    </div>,
    <div key="4" className={styles.organizationNumberColumn}>
      <B1
        defaultText="Organization number"
        bold
        className={clsx(styles.noMargin, styles.headerLabel)}
      >
        Translation.Feature.Admin.Accounts.AccountHeader.OrganisationNumber.label
      </B1>
      <FontAwesomeIcon
        icon={faChevronUp}
        onClick={() => onSort('organisationNumber')}
        className={clsx(styles.sortIcon, sort.organisationNumber && styles.sortDecending)}
      />
    </div>,
    <div key="5" className={styles.customerNameColumn}>
      <B1 defaultText="Customer name" bold className={clsx(styles.noMargin, styles.headerLabel)}>
        Translation.Feature.Admin.Accounts.AccountHeader.CustomerName.label
      </B1>
      <FontAwesomeIcon
        icon={faChevronUp}
        onClick={() => onSort('customerName')}
        className={clsx(styles.sortIcon, sort.customerName && styles.sortDecending)}
      />
    </div>,
    // <div key="5" className={styles.addressColumn}>
    //   <B1 defaultText="Address" bold className={clsx(styles.noMargin, styles.headerLabel)}>
    //     Translation.Feature.Admin.Accounts.AccountHeader.Address.label
    //   </B1>
    //   <FontAwesomeIcon
    //     icon={faChevronUp}
    //     onClick={() => onSort('address')}
    //     className={clsx(styles.sortIcon, sort.address && styles.sortDecending)}
    //   />
    // </div>,
    <div key="6" className={styles.phoneColumn}>
      <B1 defaultText="Phone" bold className={clsx(styles.noMargin, styles.headerLabel)}>
        Translation.Feature.Admin.Accounts.AccountHeader.Phone.label
      </B1>
      <FontAwesomeIcon
        icon={faChevronUp}
        onClick={() => onSort('phone')}
        className={clsx(styles.sortIcon, sort.phone && styles.sortDecending)}
      />
    </div>,
    // <div key="6" className={styles.enabledColumn}>
    //   <B1 defaultText="Enabled" bold className={clsx(styles.noMargin, styles.headerLabel)}>
    //     Translation.Feature.Admin.Accounts.AccountHeader.Enabled.label
    //   </B1>
    //   <FontAwesomeIcon
    //     icon={faChevronUp}
    //     onClick={() => onSort('enabled')}
    //     className={clsx(styles.sortIcon, sort.enabled && styles.sortDecending)}
    //   />
    // </div>,
    // <div key="7" className={styles.lockedColumn}>
    //   <B1 defaultText="Locked" bold className={clsx(styles.noMargin, styles.headerLabel)}>
    //     Translation.Feature.Admin.Accounts.AccountHeader.Locked.label
    //   </B1>
    //   <FontAwesomeIcon
    //     icon={faChevronUp}
    //     onClick={() => onSort('accountLocked')}
    //     className={clsx(styles.sortIcon, sort.accountLocked && styles.sortDecending)}
    //   />
    // </div>,
    <div key="7" className={styles.actionsColumn}>
      <B1 defaultText="Actions" bold className={clsx(styles.noMargin, styles.headerLabel)}>
        Translation.Feature.Admin.Accounts.AccountHeader.Actions.label
      </B1>
    </div>,
  ];

  let filteredAccounts = filterAccounts();

  if (filteredAccounts.length > 0 && Object.keys(sort).length > 0) {
    filteredAccounts = filteredAccounts.slice().sort((a, b) => {
      const key = Object.keys(sort)[0];
      // if (key === 'address') {
      //   if (sort[key] === true) {
      //     return (
      //       b.address1 +
      //       b.address2 +
      //       b.postalCode +
      //       b.city +
      //       b.country -
      //       (a.address1 + a.address2 + a.postalCode + a.city + a.country)
      //     );
      //   }
      //   return (
      //     a.address1 +
      //     a.address2 +
      //     a.postalCode +
      //     a.city +
      //     a.country -
      //     (b.address1 + b.address2 + b.postalCode + b.city + b.country)
      //   );
      // }
      if (sort[key] === true) {
        if (b[key] > a[key]) {
          return 1;
        }

        if (b[key] < a[key]) {
          return -1;
        }
        return 0;
      }

      if (b[key] < a[key]) {
        return 1;
      }

      if (b[key] > a[key]) {
        return -1;
      }
      return 0;
    });
  }

  const renderAccounts = filteredAccounts.map((account) => {
    return (
      <div className={styles.accountRow} key={account.id}>
        <div className={styles.accountIdColumn}>
          <B2 translate={false} className={styles.noMargin}>
            {account.id}
          </B2>
        </div>
        <div className={styles.userNameColumn}>
          <B2 translate={false} className={styles.noMargin}>
            {account.userName}
          </B2>
          {account.verified && (
            <FontAwesomeIcon icon={faCheckCircle} className={styles.accountVerifiedIcon} />
          )}
        </div>
        <div className={styles.companyNameColumn}>
          <B2 translate={false} className={styles.noMargin}>
            {account.companyName}
          </B2>
        </div>
        <div className={styles.organizationNumberColumn}>
          <B2 translate={false} className={styles.noMargin}>
            {account.organisationNumber}
          </B2>
        </div>
        <div className={styles.customerNameColumn}>
          <B2 translate={false} className={styles.noMargin}>
            {account.customerName}
          </B2>
        </div>
        {/* <div className={styles.addressColumnItem}>
          <B2 translate={false} className={styles.noMargin}>
            {a.address1}
          </B2>
          <B2 translate={false} className={styles.noMargin}>
            {a.address2}
          </B2>
          <B2 translate={false} className={styles.noMargin}>
            {a.postalCode} {a.city}
          </B2>
          <B2 translate={false} className={styles.noMargin}>
            {a.country}
          </B2>
        </div> 
        <div className={styles.enabledColumn}>
          <B2 translate={false} className={clsx(styles.noMargin, styles.capitalize)}>
            {a.enabled.toString()}
          </B2>
        </div>
        <div className={styles.lockedColumn}>
          <B2 translate={false} className={styles.noMargin}>
            {a.accountLocked === true ? 'True' : 'False'}
          </B2>
        </div> */}
        <div className={styles.phoneColumn}>
          <B2 translate={false} className={styles.noMargin}>
            {account.phone}
          </B2>
        </div>
        <div className={styles.actionsColumn}>
          {account.accountLocked === true ? (
            <Button
              defaultText="Unlock"
              className={styles.unlockButton}
              onClick={() => onUnlockAccount(account.id)}
            >
              Translation.Feature.Admin.Accounts.AccountAction.Unlock.label
            </Button>
          ) : (
            <Button
              defaultText="Lock"
              className={styles.lockButton}
              onClick={() => onLockAccount(account.id)}
            >
              Translation.Feature.Admin.Accounts.AccountAction.Lock.label
            </Button>
          )}
          <Button
            defaultText="Delete"
            className={styles.deleteButton}
            onClick={() => {
              setCurrentAccountId(account.id);
              setShowDeleteConfirmation(true);
            }}
          >
            Translation.Feature.Admin.Accounts.AccountAction.Delete.label
          </Button>
          <Button
            defaultText="Features"
            className={styles.featuresButton}
            onClick={() => {
              setCurrentAccountId(account.id);
              setCurrentAccountFeatures(account.features);
              setShowAccountFeatures(true);
            }}
          >
            Translation.Feature.Admin.Accounts.AccountAction.Features.label
          </Button>
        </div>
      </div>
    );
  });

  const renderFilters = (
    <div className={styles.filterContainer}>
      {/* <S1 defaultText="Filter" className={styles.noMargin}>
        Translation.Feature.Admin.Accounts.Filter.label
      </S1>
      <S1 translate={false} className={styles.noMargin}>
        :
      </S1> */}
      <div className={styles.checkBoxContainer}>
        {/* <CheckBox
          className={styles.checkBox}
          label="Translation.Feature.OrderDashboard.Filter.Ongoing.label"
          defaultLabel="Ongoing"
          checked={filter.ongoing}
          onChange={checked => filterChanged({ ongoing: checked })}
        />
        <CheckBox
          className={styles.checkBox}
          label="Translation.Feature.OrderDashboard.Filter.Ready.label"
          defaultLabel="Ready"
          checked={filter.ready}
          onChange={checked => filterChanged({ ready: checked })}
        />
        <CheckBox
          className={styles.checkBox}
          label="Translation.Feature.OrderDashboard.Filter.Cancelled.label"
          defaultLabel="Cancelled"
          checked={filter.cancelled}
          onChange={checked => filterChanged({ cancelled: checked })}
        /> */}
      </div>
    </div>
  );

  if (busy) {
    return <Loader className={styles.loader} />;
  }

  return (
    <div className={classNames}>
      {renderFilters}
      {filteredAccounts.length === 0 ? (
        <S1 defaultText="No accounts to display">
          Translation.Feature.Admin.Accounts.NoAccounts.label
        </S1>
      ) : (
        [
          <div key="accountHeaders" className={styles.accountHeadersContainer}>
            {renderAccountHeaders}
          </div>,
          <div key="accounts" className={styles.accountRowContainer}>
            {renderAccounts}
          </div>,
        ]
      )}
      <Modal
        isOpen={showDeleteConfirmation}
        title="Translation.Feature.Admin.Accounts.ConfirmDeleteAccount.Modal.title"
        defaultTitle="Delete account"
        hideOnBackDropClick={false}
      >
        <B2 className={styles.noMargin} defaultText="Are you sure you want to delete the account?">
          Translation.Feature.Admin.Accounts.ConfirmDeleteAccount.Modal.text
        </B2>
        <div className={styles.modalButtonContainer}>
          <Button
            defaultText="Cancel"
            className={styles.modalCancelButton}
            onClick={() => {
              setCurrentAccountId(null);
              setShowDeleteConfirmation(false);
            }}
          >
            Translation.Feature.Admin.Accounts.ConfirmDeleteAccount.Modal.CancelButton.label
          </Button>
          <Button
            defaultText="Delete"
            className={styles.modalDeleteButton}
            onClick={() => {
              onDeleteAccount(currentAccountId);
              setShowDeleteConfirmation(false);
              setCurrentAccountId(null);
            }}
          >
            Translation.Feature.Admin.Accounts.ConfirmDeleteAccount.Modal.DeleteButton.label
          </Button>
        </div>
      </Modal>
      <Modal
        isOpen={showAccountFeatures}
        title="Translation.Feature.Admin.Accounts.ShowAccountFeatures.Modal.title"
        defaultTitle="Account features"
        hideOnBackDropClick={false}
      >
        {currentAccountFeatures && (
          <CheckBox
            className={styles.checkBox}
            label="Translation.Feature.Admin.Accounts.ShowAccountFeatures.Modal.DeleteTranslations.label"
            defaultLabel="Delete translations"
            checked={currentAccountFeatures.indexOf(FEATURES.deleteTranslationDocuments) === 0}
            onChange={(checked) => {
              if (checked) {
                setCurrentAccountFeatures([
                  ...currentAccountFeatures,
                  FEATURES.deleteTranslationDocuments,
                ]);
              } else {
                setCurrentAccountFeatures(
                  currentAccountFeatures.filter((p) => p !== FEATURES.deleteTranslationDocuments)
                );
              }
            }}
          />
        )}
        <div className={styles.modalButtonContainer}>
          <Button
            defaultText="Cancel"
            className={styles.modalCancelButton}
            onClick={() => {
              setCurrentAccountId(null);
              setCurrentAccountFeatures(null);
              setShowAccountFeatures(false);
            }}
          >
            Translation.Feature.Admin.Accounts.ShowAccountFeatures.Modal.CancelButton.label
          </Button>
          <Button
            defaultText="Save"
            className={styles.modalSaveFeaturesButton}
            onClick={() => {
              onSaveAccountFeatures(currentAccountId, currentAccountFeatures);
              setCurrentAccountId(null);
              setCurrentAccountFeatures(null);
              setShowAccountFeatures(false);
            }}
          >
            Translation.Feature.Admin.Accounts.ShowAccountFeatures.Modal.SaveButton.label
          </Button>
        </div>
      </Modal>
    </div>
  );
}

AdminAccounts.propTypes = {
  className: string,
  busy: bool.isRequired,
  accounts: arrayOf(adminAccountType).isRequired,
  onLockAccount: func.isRequired,
  onUnlockAccount: func.isRequired,
  onDeleteAccount: func.isRequired,
  onSaveAccountFeatures: func.isRequired,
};

AdminAccounts.defaultProps = {
  className: null,
};

export default AdminAccounts;
