import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { orderStateType } from 'types';
import { uploadDocuments, removeDocument, cancelUploadDocument } from 'services/order';
import AddDocuments from '.';

function AddDocumentsContainer(props) {
  const {
    orderState: {
      currentOrder: {
        busy,
        documents,
        getQuotesCriteria: { wordCount },
        selectedQuote
      }
    }
  } = props;

  const [navigationUrl, setNavigationUrl] = useState();

  const acceptedFileTypes = [
    '.doc',
    '.docx',
    '.xls',
    '.xlsx',
    '.ppt',
    '.pptx',
    '.dot',
    '.pdf',
    '.rtf',
    '.txt',
    '.xml',
    '.mif',
    '.xlf'
  ];

  const filterDocuments = (currentDocuments, addedDocuments) => {
    const documentsToAdd = addedDocuments.filter(
      ad =>
        !currentDocuments.some(
          cd =>
            cd.path === ad.path &&
            cd.size === ad.size &&
            cd.lastModified === ad.lastModified &&
            cd.name === ad.name
        )
    );

    return documentsToAdd;
  };

  const onSelectedDocuments = chosenDocuments => {
    const documentsToAdd = filterDocuments(documents, chosenDocuments);
    if (documentsToAdd.length > 0) {
      uploadDocuments(documentsToAdd, selectedQuote.id);
    }
  };

  const onRemoveDocument = document => {
    removeDocument(document);
  };

  const onCancelUploadDocument = document => {
    cancelUploadDocument(document);
  };

  const onGoBack = () => {
    setNavigationUrl('/select-quote');
  };

  const onOrderSummary = () => {
    setNavigationUrl('/order-summary');
  };

  if (!selectedQuote && !navigationUrl) {
    setNavigationUrl('/get-quotes');
  }

  if (navigationUrl) {
    return <Redirect to={navigationUrl} />;
  }

  return (
    <AddDocuments
      documents={documents}
      onSelectedDocuments={onSelectedDocuments}
      wordCount={wordCount}
      onRemoveDocument={onRemoveDocument}
      onCancelUploadDocument={onCancelUploadDocument}
      onGoBack={onGoBack}
      onOrderSummary={onOrderSummary}
      busy={busy}
      acceptedFileTypes={acceptedFileTypes}
    />
  );
}

const mapStateToProps = state => {
  return {
    orderState: state.order
  };
};

AddDocumentsContainer.propTypes = {
  orderState: orderStateType.isRequired // eslint-disable-line react/no-unused-prop-types
};

export default connect(mapStateToProps)(AddDocumentsContainer);
