import React from 'react';
import { H3 } from 'components';
import { ManageAccount } from 'features';
import styles from './ManageAccountPage.module.scss';

function ManageAccountPage() {
  return (
    <div className={styles.root}>
      <H3 className={styles.header} bold upperCase defaultText="Account">
        Translation.Page.Account.header
      </H3>
      <ManageAccount />
    </div>
  );
}

export default ManageAccountPage;
