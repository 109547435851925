import React from 'react';
import { connect } from 'react-redux';
import { string, applicationConfigurationStateType, bool } from 'types';
import { changeLanguage } from 'services/configuration';
import LanguageSelect from '.';

function LanguageSelectContainer(props) {
  const { applicationConfiguration, useDefault, className } = props;
  const { languages, selectedLanguage } = applicationConfiguration;

  const onLanguageChange = language => {
    changeLanguage(language);
  };

  return (
    <LanguageSelect
      languages={languages.map(l => {
        return { label: l.translationKey, value: l.value };
      })}
      selectedLanguage={selectedLanguage}
      onLanguageChange={onLanguageChange}
      useDefault={useDefault}
      className={className}
    />
  );
}

const mapStateToProps = state => {
  return {
    applicationConfiguration: state.configuration.application
  };
};

LanguageSelectContainer.propTypes = {
  className: string,
  applicationConfiguration: applicationConfigurationStateType.isRequired,
  useDefault: bool
};

LanguageSelectContainer.defaultProps = {
  className: null,
  useDefault: true
};

export default connect(mapStateToProps)(LanguageSelectContainer);
