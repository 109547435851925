import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getAccountConfiguration } from 'services/configuration';
import { getAccount, updateAccount } from 'services/account';
import { toastSuccess, toastError } from 'utils/Toaster';
import { accountConfigurationStateType } from 'types';
import ManageAccount from '.';

function ManageAccountContainer(props) {
  const { accountConfigurationState } = props;
  const { busy, countries, timezones } = accountConfigurationState;
  const [_account, setAccount] = useState();

  useEffect(() => {
    let isMounted = true;

    if (!busy) {
      getAccountConfiguration();
    }

    if (!_account) {
      getAccount().then(account => {
        if (isMounted) {
          setAccount(account);
        }
      });
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const onSave = account => {
    updateAccount(account)
      .then(() => {
        toastSuccess(
          'Translation.Feature.ManageAccount.UpdateSuccess.toastLabel',
          'ACCOUNT UPDATED'
        );
      })
      .catch(error => {
        toastError(
          'Translation.Feature.ManageAccount.UpdateFailed.toastLabel',
          'ACCOUNT UPDATE FAILED'
        );
      });
  };

  return (
    <ManageAccount
      account={_account}
      busy={busy || !_account}
      onSave={onSave}
      countries={countries}
      timezones={timezones}
    />
  );
}

const mapStateToProps = state => {
  return {
    accountConfigurationState: state.configuration.account
  };
};

ManageAccountContainer.propTypes = {
  accountConfigurationState: accountConfigurationStateType.isRequired
};

export default connect(mapStateToProps)(ManageAccountContainer);
