import React from 'react';
import { H3 } from 'components';
import { ForgotPassword } from 'features';
import styles from './ForgotPasswordPage.module.scss';

function ForgotPasswordPage() {
  return (
    <div className={styles.root}>
      <H3 className={styles.header} bold upperCase defaultText="Forgot password">
        Translation.Pages.ForgotPassword.header.label
      </H3>
      <ForgotPassword />
    </div>
  );
}

export default ForgotPasswordPage;
