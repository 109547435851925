import React from 'react';
import { CreateAccount } from 'features';
import styles from './CreateAccount.module.scss';

function CreateAccountPage(props) {
  return (
    <div className={styles.root}>
      <CreateAccount {...props} />
    </div>
  );
}

export default CreateAccountPage;
