import React from 'react';
import { SelectQuote } from 'features';
import styles from './SelectQuote.module.scss';

function SelectQuotePage() {
  return (
    <div className={styles.root}>
      <SelectQuote />
    </div>
  );
}

export default SelectQuotePage;
