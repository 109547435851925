import React, { useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusSquare } from '@fortawesome/free-solid-svg-icons';
import { faSquare, faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import {
  adminOrderDetailsType,
  string,
  func,
  number,
  languagesType,
  translationItemType,
  arrayOf,
} from 'types';
import { getCurrencyFormat, Loader } from 'assets';
import clsx from 'clsx';
import { S1, Link, B1, B2, Modal, Button, Select, Label } from 'components';
import styles from './AdminOrderDetails.module.scss';

function AdminOrderDetails(props) {
  const {
    className,
    orderId,
    orderDetails,
    onClosed,
    onDeleteDocument,
    onChangeDocumentStatus,
    languages,
    onAddDocument,
    orderStatuses,
  } = props;
  const { order, account, files } = orderDetails || {};
  const intl = useIntl();
  const classNames = clsx(className, styles.root);
  const inputFile = useRef(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [changeDocumentStatusInfo, setChangeDocumentStatusInfo] = useState(null);
  const [showAddDocumentModal, setShowAddDocumentModal] = useState(false);
  const [deleteDocumentId, setDeleteDocumentId] = useState();
  const [deleteDocumentName, setDeleteDocumentName] = useState();
  const [addDocumentOriginalFile, setAddDocumentOriginalFile] = useState();
  const [addDocumentLanguage, setAddDocumentLanguage] = useState();
  const [addDocumentTranslationFile, setAddDocumentTranslationFile] = useState();
  const [addDocumentBusy, setAddDocumentBusy] = useState(false);

  const originalFiles = files
    ? files
        .filter((f) => f.original && f.id)
        .map((f) => {
          return {
            label: f.filename,
            value: f.id,
            translate: false,
          };
        })
    : [];

  const getFileSizeText = (sizeInBytes) => {
    if (sizeInBytes < 1000000) {
      return `(${(sizeInBytes / 1024).toFixed(2)} kB)`;
    }
    return `(${(sizeInBytes / 1048576).toFixed(2)} MB)`;
  };

  const orderStatus = order ? orderStatuses.find((os) => os.value === order.status) : null;

  return (
    <>
      <Modal
        className={classNames}
        isOpen={orderId !== null}
        onClosed={() => {
          setDeleteDocumentId(null);
          setDeleteDocumentName(null);
          setShowAddDocumentModal(false);
          setShowDeleteConfirmation(false);
          setChangeDocumentStatusInfo(null);
          onClosed();
        }}
        title={`${intl.formatMessage({
          id: 'Translation.Feature.AdminOrderDetails.Modal.title',
        })} ${orderId}`}
        defaultTitle={`Order ${orderId}`}
        busy={!orderDetails}
        translateTitle={false}
      >
        {!order ? null : (
          <div className={styles.orderDetailsContainer}>
            <div className={styles.orderContainer}>
              <S1 className={styles.header} defaultText="Order">
                Translation.Feature.AdminOrderDetails.Modal.Order.Header.label
              </S1>
              <table>
                <tbody>
                  <tr className={styles.tableRow}>
                    <td className={styles.labelCell}>
                      <B2 defaultText="Project #" className={styles.label}>
                        Translation.Feature.AdminOrderDetails.Modal.Order.ProjectNumber.label
                      </B2>
                    </td>
                    <td>
                      <B2 className={styles.value} translate={false}>
                        {order.projectNumber}
                      </B2>
                    </td>
                  </tr>
                  <tr className={styles.tableRow}>
                    <td className={styles.labelCell}>
                      <B2 defaultText="Placed" className={styles.label}>
                        Translation.Feature.AdminOrderDetails.Modal.Order.Placed.label
                      </B2>
                    </td>
                    <td>
                      <B2 className={styles.value} translate={false}>
                        {intl.formatDate(order.placed)}{' '}
                        {intl.formatTime(order.placed, {
                          hour: 'numeric',
                          minute: 'numeric',
                        })}
                      </B2>
                    </td>
                  </tr>
                  <tr className={styles.tableRow}>
                    <td className={styles.labelCell}>
                      <B2 defaultText="Receive" className={styles.label}>
                        Translation.Feature.AdminOrderDetails.Modal.Order.Receive.label
                      </B2>
                    </td>
                    <td>
                      <B2 className={styles.value} translate={false}>
                        {intl.formatDate(order.deliveryDateService)}{' '}
                        {intl.formatTime(order.deliveryDateService, {
                          hour: 'numeric',
                          minute: 'numeric',
                        })}
                      </B2>
                    </td>
                  </tr>
                  <tr className={styles.tableRow}>
                    <td className={styles.labelCell}>
                      <B2 defaultText="Deliver" className={styles.label}>
                        Translation.Feature.AdminOrderDetails.Modal.Order.Deliver.label
                      </B2>
                    </td>
                    <td>
                      <B2 className={styles.value} translate={false}>
                        {intl.formatDate(order.deliveryDateDelayed)}{' '}
                        {intl.formatTime(order.deliveryDateDelayed, {
                          hour: 'numeric',
                          minute: 'numeric',
                        })}
                      </B2>
                    </td>
                  </tr>
                  <tr className={styles.tableRow}>
                    <td className={styles.labelCell}>
                      <B2 defaultText="Status" className={styles.label}>
                        Translation.Feature.AdminOrderDetails.Modal.Order.Status.label
                      </B2>
                    </td>
                    <td>
                      {orderStatus && (
                        <B2 defaultText={orderStatus.defaultTranslation} className={styles.value}>
                          {orderStatus.translationKey}
                        </B2>
                      )}
                    </td>
                  </tr>
                  <tr className={styles.tableRow}>
                    <td className={styles.labelCell}>
                      <B2 defaultText="Source language" className={styles.label}>
                        Translation.Feature.AdminOrderDetails.Modal.Order.SourceLanguage.label
                      </B2>
                    </td>
                    <td>
                      <B2 className={styles.value} translate={false}>
                        {order.sourceLanguage}
                      </B2>
                    </td>
                  </tr>
                  <tr className={styles.tableRow}>
                    <td className={styles.labelCell}>
                      <B2 defaultText="Target language(s)" className={styles.label}>
                        Translation.Feature.AdminOrderDetails.Modal.Order.TargetLanguages.label
                      </B2>
                    </td>
                    <td>
                      <B2 className={styles.value} translate={false}>
                        {order.targetLanguages.join(', ')}
                      </B2>
                    </td>
                  </tr>
                  <tr className={styles.tableRow}>
                    <td className={styles.labelCell}>
                      <B2 defaultText="Word count" className={styles.label}>
                        Translation.Feature.AdminOrderDetails.Modal.Order.WordCount.label
                      </B2>
                    </td>
                    <td>
                      <B2 className={styles.value} translate={false}>
                        {order.wordCount}
                      </B2>
                    </td>
                  </tr>
                  <tr className={styles.tableRow}>
                    <td className={styles.labelCell}>
                      <B2 defaultText="Subject" className={styles.label}>
                        Translation.Feature.AdminOrderDetails.Modal.Order.Subject.label
                      </B2>
                    </td>
                    <td>
                      <B2 className={styles.value}>{order.translationSubject}</B2>
                    </td>
                  </tr>
                  <tr className={styles.tableRow}>
                    <td className={styles.labelCell}>
                      <B2 defaultText="Translation type" className={styles.label}>
                        Translation.Feature.AdminOrderDetails.Modal.Order.TranslationType.label
                      </B2>
                    </td>
                    <td>
                      <B2 className={styles.value} translate={false}>
                        {order.translationType}
                      </B2>
                    </td>
                  </tr>
                  <tr className={styles.tableRow}>
                    <td className={styles.labelCell}>
                      <B2 defaultText="Amount" className={styles.label}>
                        Translation.Feature.AdminOrderDetails.Modal.Order.Amount.label
                      </B2>
                    </td>
                    <td>
                      <B2 className={styles.value} translate={false}>
                        {intl.formatNumber(order.total, getCurrencyFormat(order.currency))}
                      </B2>
                    </td>
                  </tr>
                  <tr className={styles.tableRow}>
                    <td className={styles.labelCell}>
                      <B2 defaultText="Cost" className={styles.label}>
                        Translation.Feature.AdminOrderDetails.Modal.Order.Cost.label
                      </B2>
                    </td>
                    <td>
                      <B2 className={styles.value} translate={false}>
                        {intl.formatNumber(order.cost, getCurrencyFormat('eur'))}
                      </B2>
                    </td>
                  </tr>
                  <tr className={styles.tableRow}>
                    <td className={styles.labelCell}>
                      <B2 defaultText="Buyer's reference" className={styles.label}>
                        Translation.Feature.AdminOrderDetails.Modal.Order.Reference.label
                      </B2>
                    </td>
                    <td>
                      <B2 className={styles.value} translate={false}>
                        {order.buyersReference}
                      </B2>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className={styles.accountContainer}>
              <S1 className={styles.header} defaultText="Account">
                Translation.Feature.AdminOrderDetails.Modal.Account.Header.label
              </S1>
              <table>
                <tbody>
                  <tr className={styles.tableRow}>
                    <td className={styles.labelCell}>
                      <B2 defaultText="Account id" className={styles.label}>
                        Translation.Feature.AdminOrderDetails.Modal.Account.AccountId.label
                      </B2>
                    </td>
                    <td>
                      <B2 className={styles.value} translate={false}>
                        {account.id}
                      </B2>
                    </td>
                  </tr>
                  <tr className={styles.tableRow}>
                    <td className={styles.labelCell}>
                      <B2 defaultText="User name" className={styles.label}>
                        Translation.Feature.AdminOrderDetails.Modal.Account.UserName.label
                      </B2>
                    </td>
                    <td>
                      <B2 className={styles.value} translate={false}>
                        {account.userName}
                      </B2>
                    </td>
                  </tr>
                  <tr className={styles.tableRow}>
                    <td className={styles.labelCell}>
                      <B2 defaultText="Customer name" className={styles.label}>
                        Translation.Feature.AdminOrderDetails.Modal.Account.CustomerName.label
                      </B2>
                    </td>
                    <td>
                      <B2 className={styles.value} translate={false}>
                        {account.customerName}
                      </B2>
                    </td>
                  </tr>
                  <tr className={styles.tableRow}>
                    <td className={styles.labelCell}>
                      <B2 defaultText="Company name" className={styles.label}>
                        Translation.Feature.AdminOrderDetails.Modal.Account.CompanyName.label
                      </B2>
                    </td>
                    <td>
                      <B2 className={styles.value} translate={false}>
                        {account.companyName}
                      </B2>
                    </td>
                  </tr>
                  <tr className={styles.tableRow}>
                    <td className={styles.labelCell}>
                      <B2 defaultText="Organization number" className={styles.label}>
                        Translation.Feature.AdminOrderDetails.Modal.Account.OrganisationNumber.label
                      </B2>
                    </td>
                    <td>
                      <B2 className={styles.value} translate={false}>
                        {account.organisationNumber}
                      </B2>
                    </td>
                  </tr>
                  {/* <tr className={styles.tableRow}>
                  <td className={styles.labelCell}>
                    <B2 defaultText="Address" className={styles.label}>
                      Translation.Feature.AdminOrderDetails.Modal.Account.Address.label
                    </B2>
                  </td>
                  <td>
                    <B2 className={styles.value} translate={false}>
                      {account.address1}
                    </B2>
                    <B2 className={styles.value} translate={false}>
                      {account.address2}
                    </B2>
                    <B2 className={styles.value} translate={false}>
                      {account.postalCode} {account.city}
                    </B2>
                    <B2 className={styles.value} translate={false}>
                      {account.country}
                    </B2>
                  </td>
                </tr> */}
                  <tr className={styles.tableRow}>
                    <td className={styles.labelCell}>
                      <B2 defaultText="Phone" className={styles.label}>
                        Translation.Feature.AdminOrderDetails.Modal.Account.Phone.label
                      </B2>
                    </td>
                    <td>
                      <B2 className={styles.value} translate={false}>
                        {account.phone}
                      </B2>
                    </td>
                  </tr>
                  <tr className={styles.tableRow}>
                    <td className={styles.labelCell}>
                      <B2 defaultText="Account locked" className={styles.label}>
                        Translation.Feature.AdminOrderDetails.Modal.Account.AccountLocked.label
                      </B2>
                    </td>
                    <td>
                      <B2 className={styles.value} translate={false}>
                        {account.accountLocked ? 'Yes' : 'No'}
                      </B2>
                    </td>
                  </tr>
                  <tr className={styles.tableRow}>
                    <td className={styles.labelCell}>
                      <B2 defaultText="Translation memory" className={styles.label}>
                        Translation.Feature.AdminOrderDetails.Modal.Account.TranslationMemory.label
                      </B2>
                    </td>
                    <td>
                      <B2 className={styles.value} translate={false}>
                        {account.translationMemory}
                      </B2>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className={styles.filesContainer}>
              <S1 className={styles.header} defaultText="Files">
                Translation.Feature.AdminOrderDetails.Modal.Files.Header.label
              </S1>
              <table style={{ marginBottom: 20 }}>
                <tbody>
                  <tr className={styles.tableRow}>
                    {/* <td className={styles.labelCell}>
                    <B2 defaultText="Download links" className={styles.label}>
                      Translation.Feature.AdminOrderDetails.Modal.Account.DownloadLinks.label
                    </B2>
                  </td> */}
                    <td>
                      {files.map((f) => (
                        <div key={f.filename + f.size} className={styles.fileLinkContainer}>
                          <Link
                            bolder
                            className={styles.fileLink}
                            translate={false}
                            href={order.allDocumentsDeleted ? '' : f.url}
                            download={!order.allDocumentsDeleted}
                            disabled={order.allDocumentsDeleted}
                          >
                            {f.filename}
                          </Link>
                          <B2 className={styles.fileSizeLabel} translate={false}>
                            {getFileSizeText(f.size)}
                          </B2>
                          {!order.allDocumentsDeleted && (
                            <>
                              <FontAwesomeIcon
                                title={intl.formatMessage({
                                  id:
                                    'Translation.Feature.AdminOrderDetails.RemoveDocument.tooltip',
                                  defaultMessage: 'Remove document',
                                })}
                                icon={faMinusSquare}
                                className={
                                  f.deletable
                                    ? styles.removeDocumentIcon
                                    : styles.hiddenRemoveDocumentIcon
                                }
                                onClick={() => {
                                  if (f.deletable) {
                                    setDeleteDocumentId(f.id);
                                    setDeleteDocumentName(f.filename);
                                    setShowDeleteConfirmation(true);
                                  }
                                }}
                              />
                              <FontAwesomeIcon
                                title={
                                  f.status === 'reviewed'
                                    ? intl.formatMessage({
                                        id:
                                          'Translation.Feature.AdminOrderDetails.MarkDocumentAsUnreviewed.tooltip',
                                        defaultMessage: 'Mark as unreviewed',
                                      })
                                    : intl.formatMessage({
                                        id:
                                          'Translation.Feature.AdminOrderDetails.MarkDocumentAsReviewed.tooltip',
                                        defaultMessage: 'Mark as reviewed',
                                      })
                                }
                                icon={f.status === 'reviewed' ? faCheckSquare : faSquare}
                                className={
                                  f.deletable && !f.userUploaded
                                    ? styles.removeDocumentIcon
                                    : styles.hiddenRemoveDocumentIcon
                                }
                                onClick={() => {
                                  if (f.deletable) {
                                    setChangeDocumentStatusInfo({
                                      orderId,
                                      documentId: f.id,
                                      reviewed: f.status === 'reviewed',
                                    });
                                  }
                                }}
                              />
                            </>
                          )}
                        </div>
                      ))}
                    </td>
                  </tr>
                </tbody>
              </table>
              {order.allDocumentsDeleted ? (
                <S1 className={styles.allDocumentsDeletedLabel} defaultText="All documents deleted">
                  Translation.Feature.AdminOrderDetails.AllDocumentsDeleted.label
                </S1>
              ) : (
                <Button
                  defaultText="Add document"
                  className={styles.addDocumentButton}
                  onClick={() => {
                    setShowAddDocumentModal(true);
                  }}
                >
                  Translation.Feature.AdminOrderDetails.AddDocumentButton.label
                </Button>
              )}
            </div>
          </div>
        )}
        <Modal
          isOpen={showDeleteConfirmation}
          title="Translation.Feature.AdminOrderDetails.Modal.ConfirmDeleteDocument.title"
          defaultTitle="Delete document"
          hideOnBackDropClick={false}
          className={styles.deleteDocumentModal}
        >
          <B2
            className={styles.noMargin}
            defaultText="Are you sure you want to delete this document?"
          >
            Translation.Feature.AdminOrderDetails.Modal.ConfirmDeleteDocument.text
          </B2>
          <B1 className={styles.modalDeleteDocumentFileName} translate={false} bold>
            {deleteDocumentName}
          </B1>
          <div className={styles.modalButtonContainer}>
            <Button
              defaultText="Cancel"
              className={styles.modalCancelButton}
              onClick={() => {
                setDeleteDocumentId(null);
                setShowDeleteConfirmation(false);
                setDeleteDocumentName(null);
              }}
            >
              Translation.Feature.AdminOrderDetails.Modal.ConfirmDeleteDocument.CancelButton.label
            </Button>
            <Button
              defaultText="Delete"
              className={styles.modalDeleteButton}
              onClick={() => {
                onDeleteDocument(deleteDocumentId);
                setShowDeleteConfirmation(false);
                setDeleteDocumentId(null);
                setDeleteDocumentName(null);
              }}
            >
              Translation.Feature.AdminOrderDetails.Modal.ConfirmDeleteDocument.DeleteButton.label
            </Button>
          </div>
        </Modal>
        <Modal
          isOpen={changeDocumentStatusInfo ? true : false}
          title={
            changeDocumentStatusInfo?.reviewed
              ? 'Translation.Feature.AdminOrderDetails.Modal.ConfirmMarkDocumentAsUnreviewed.title'
              : 'Translation.Feature.AdminOrderDetails.Modal.ConfirmMarkDocumentAsReviewed.title'
          }
          defaultTitle={
            changeDocumentStatusInfo?.reviewed
              ? 'Mark document as unreviewed'
              : 'Mark document as reviewed'
          }
          hideOnBackDropClick={false}
          className={styles.deleteDocumentModal}
        >
          <B2
            className={styles.noMargin}
            defaultText={
              changeDocumentStatusInfo?.reviewed
                ? 'Are you sure you want to mark this document as unreviewed?'
                : 'Are you sure you want to mark this document as reviewed?'
            }
          >
            {changeDocumentStatusInfo?.reviewed
              ? 'Translation.Feature.AdminOrderDetails.Modal.ConfirmMarkDocumentAsUnreviewed.text'
              : 'Translation.Feature.AdminOrderDetails.Modal.ConfirmMarkDocumentAsReviewed.text'}
          </B2>
          <B1 className={styles.modalDeleteDocumentFileName} translate={false} bold>
            {deleteDocumentName}
          </B1>
          <div className={styles.modalButtonContainer}>
            <Button
              defaultText="Cancel"
              className={styles.modalCancelButton}
              onClick={() => {
                setChangeDocumentStatusInfo(null);
              }}
            >
              Translation.Feature.AdminOrderDetails.Modal.ConfirmMarkDocument.CancelButton.label
            </Button>
            <Button
              defaultText={
                changeDocumentStatusInfo?.reviewed ? 'Mark as unreviewed' : 'Mark as reviewed'
              }
              className={styles.modalChangeStatusButton}
              onClick={() => {
                onChangeDocumentStatus(changeDocumentStatusInfo);
                setChangeDocumentStatusInfo(null);
              }}
            >
              {changeDocumentStatusInfo?.reviewed
                ? 'Translation.Feature.AdminOrderDetails.Modal.ConfirmMarkDocumentAsUnreviewed.Button.label'
                : 'Translation.Feature.AdminOrderDetails.Modal.ConfirmMarkDocumentAsReviewed.Button.label'}
            </Button>
          </div>
        </Modal>
      </Modal>
      <Modal
        isOpen={showAddDocumentModal}
        title="Translation.Feature.AdminOrderDetails.Modal.AddDocument.title"
        defaultTitle="Add document"
        hideOnBackDropClick={false}
        className={styles.addDocumentModal}
        backdropStyle={styles.addDocumentModalBackdrop}
      >
        <div className={styles.addDocumentModalContainer}>
          <div className={styles.selectOriginalFileContainer}>
            <Label defaultText="Select original file">
              Translation.Feature.AdminOrderDetails.Modal.AddDocument.SelectOriginalFileLabel
            </Label>
            <Select
              disabled={addDocumentBusy}
              options={originalFiles}
              onChange={(value) => {
                setAddDocumentOriginalFile(value);
              }}
              value={addDocumentOriginalFile}
            />
          </div>
          <div className={styles.selectLanguageContainer}>
            <Label defaultText="Select translation language">
              Translation.Feature.AdminOrderDetails.Modal.AddDocument.SelectTranslationLanguage.label
            </Label>
            <Select
              disabled={addDocumentBusy}
              options={languages}
              onChange={(value) => {
                setAddDocumentLanguage(value);
              }}
              value={addDocumentLanguage}
            />
          </div>
          <div className={styles.addDocumentTranslationFileContainer}>
            <input
              type="file"
              ref={inputFile}
              style={{ display: 'none' }}
              onChange={(event) => {
                setAddDocumentTranslationFile(event.target.files[0]);
              }}
            />
            <Button
              defaultText="Browse"
              className={styles.browseFileButton}
              onClick={() => {
                inputFile.current.click();
              }}
              secondary
              disabled={addDocumentBusy}
            >
              Translation.Feature.AdminOrderDetails.Modal.AddDocument.BrowseFileButton.label
            </Button>
            {addDocumentTranslationFile ? (
              <Label translate={false} className={styles.translationFileLabel}>
                {addDocumentTranslationFile.name}
              </Label>
            ) : (
              <Label
                defaultText="No translation file selected"
                className={styles.translationFileLabel}
              >
                Translation.Feature.AdminOrderDetails.Modal.AddDocument.NoTranslationFileSelected.label
              </Label>
            )}
          </div>
          {addDocumentBusy ? <Loader className={styles.loader} /> : null}
          <div className={styles.addDocumentModalButtonContainer}>
            <Button
              defaultText="Cancel"
              className={styles.modalCancelButton}
              disabled={addDocumentBusy}
              onClick={() => {
                setAddDocumentLanguage(null);
                setAddDocumentOriginalFile(null);
                setShowAddDocumentModal(false);
                setAddDocumentTranslationFile(null);
              }}
            >
              Translation.Feature.AdminOrderDetails.Modal.AddDocument.CancelButton.label
            </Button>
            <Button
              defaultText="Add"
              className={styles.modalAddButton}
              disabled={
                !(
                  addDocumentLanguage &&
                  addDocumentOriginalFile &&
                  addDocumentTranslationFile &&
                  !addDocumentBusy
                )
              }
              onClick={() => {
                setAddDocumentBusy(true);
                onAddDocument(
                  addDocumentLanguage,
                  addDocumentOriginalFile,
                  addDocumentTranslationFile,
                  () => {
                    setAddDocumentBusy(false);
                    setShowAddDocumentModal(false);
                    setAddDocumentLanguage(null);
                    setAddDocumentOriginalFile(null);
                    setAddDocumentTranslationFile(null);
                  }
                );
              }}
            >
              Translation.Feature.AdminOrderDetails.Modal.AddDocument.AddButton.label
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

AdminOrderDetails.propTypes = {
  className: string,
  onClosed: func.isRequired,
  orderId: number.isRequired,
  orderDetails: adminOrderDetailsType,
  onDeleteDocument: func.isRequired,
  onChangeDocumentStatus: func.isRequired,
  onAddDocument: func.isRequired,
  languages: languagesType.isRequired,
  orderStatuses: arrayOf(translationItemType).isRequired,
};

AdminOrderDetails.defaultProps = {
  className: null,
  orderDetails: null,
};

export default AdminOrderDetails;
