import React, { useState } from 'react';
import { useWindowScroll } from 'react-use';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { string, bool, historyType, sessionStateType, orderActionsType } from 'types';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import { Logo } from 'assets';
import { B1 } from 'components';
import { Menu, LanguageSelect, AdminSelect, HamburgerMenu } from 'features';
import { logout } from 'services/session';
import orderActions from 'services/order/actions';
import styles from './Navbar.module.scss';

function Navbar(props) {
  const { className, useDefault, history, sessionState, actions } = props;
  const { location } = history;
  const { hash } = location;
  const { userLoggedIn, userRoles } = sessionState;
  const { y } = useWindowScroll();
  const [menuOpen, setMenuOpen] = useState(null);
  const classNames = clsx(className, styles.root, useDefault || y > 0 ? null : styles.secondary);

  const menuItems = [
    {
      key: 'Home',
      name: 'Translation.Feature.Navbar.MenuItem.Home.label',
      defaultText: 'Home',
      action: () => {
        if (!hash) {
          history.push('/');
        }
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      },
    },
    {
      key: 'AboutUs',
      name: 'Translation.Feature.Navbar.MenuItem.AboutUs.label',
      defaultText: 'About us',
      action: () => {
        if (history.location.pathname === '/') {
          window.history.pushState(null, null, '/#about');
          window.dispatchEvent(new Event('popstate'));
        } else {
          history.push('/#about');
        }
      },
    },
    {
      key: 'OurServices',
      name: 'Translation.Feature.Navbar.MenuItem.OurServices.label',
      defaultText: 'Our services',
      action: () => {
        if (history.location.pathname === '/') {
          window.history.pushState(null, null, '/#services');
          window.dispatchEvent(new Event('popstate'));
        } else {
          history.push('/#services');
        }
      },
    },
    {
      key: 'Languages',
      name: 'Translation.Feature.Navbar.MenuItem.Languages.label',
      defaultText: 'Languages',
      action: () => {
        if (history.location.pathname === '/') {
          window.history.pushState(null, null, '/#languages');
          window.dispatchEvent(new Event('popstate'));
        } else {
          history.push('/#languages');
        }
      },
    },
    {
      key: 'FAQ',
      name: 'Translation.Feature.Navbar.MenuItem.FAQ.label',
      defaultText: 'FAQ',
      action: () => {
        if (history.location.pathname === '/') {
          window.history.pushState(null, null, '/#faq');
          window.dispatchEvent(new Event('popstate'));
        } else {
          history.push('/#faq');
        }
      },
    },
    {
      key: 'GetOffer',
      name: 'Translation.Feature.Navbar.MenuItem.GetOffer.label',
      defaultText: 'Get offer',
      action: () => history.push('/get-quotes'),
    },
  ];

  if (userLoggedIn) {
    menuItems.push({
      key: 'Orders',
      name: 'Translation.Feature.Navbar.MenuItem.Orders.label',
      defaultText: 'Orders',
      action: () => history.push('/order-dashboard'),
    });
    menuItems.push({
      key: 'Logout',
      name: 'Translation.Feature.Navbar.MenuItem.Logout.label',
      defaultText: 'Logout',
      action: () =>
        logout().then(() => {
          history.push('/');
          actions.clearCurrentOrder();
        }),
    });
  } else {
    menuItems.push({
      key: 'Login',
      name: 'Translation.Feature.Navbar.MenuItem.Login.label',
      defaultText: 'Login',
      action: () => history.push('/login'),
    });
  }

  const navigateToHome = () => {
    history.push('/');
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className={classNames}>
      <div className={styles.innerContainer}>
        <Logo
          className={clsx(styles.logo, y > 0 && styles.secondaryLogo)}
          useDefault={useDefault || y > 0}
          onClick={navigateToHome}
        />
        <Menu className={styles.menu} items={menuItems} useDefault={useDefault || y > 0} />
        <div className={styles.selectContainer}>
          {userRoles.includes('admin') && (
            <AdminSelect className={styles.adminSelect} useDefault={useDefault || y > 0} />
          )}
          <LanguageSelect className={styles.languageSelect} useDefault={useDefault || y > 0} />
        </div>
        <HamburgerMenu
          useDefault={useDefault || y > 0}
          className={styles.hamburgerMenu}
          open={menuOpen}
          onToggle={() => setMenuOpen(!menuOpen)}
        >
          <div className={styles.hamburgerMenuItemsContainer}>
            {menuItems.map((mi) => [
              mi.anchor ? (
                <a href={mi.anchor} className={styles.hamburgerMenuItemAnchor} key={mi.key}>
                  <B1
                    upperCase
                    className={styles.hamburgerMenuItemLabel}
                    defaultText={mi.defaultText}
                  >
                    {mi.name}
                  </B1>
                </a>
              ) : (
                <div
                  role="presentation"
                  onClick={() => {
                    mi.action();
                    setMenuOpen(false);
                  }}
                  key={mi.key}
                  className={styles.hamburgerMenuItem}
                >
                  <B1
                    upperCase
                    className={styles.hamburgerMenuItemLabel}
                    defaultText={mi.defaultText}
                  >
                    {mi.name}
                  </B1>
                </div>
              ),
              <hr key={`${mi.key}_divider`} />,
            ])}
            <div className={styles.hamburgerMenuSelects}>
              {userRoles.includes('admin') && <AdminSelect className={styles.adminSelect} />}
              <LanguageSelect className={styles.languageSelect} />
            </div>
          </div>
        </HamburgerMenu>
      </div>
    </div>
  );
}

Navbar.propTypes = {
  className: string,
  useDefault: bool,
  history: historyType.isRequired,
  sessionState: sessionStateType.isRequired,
  actions: orderActionsType.isRequired,
};

Navbar.defaultProps = {
  className: null,
  useDefault: true,
};

const mapDispatch = (dispatch) => ({
  actions: bindActionCreators({ ...orderActions }, dispatch),
});

const mapStateToProps = (state) => {
  return {
    sessionState: state.session,
  };
};

export default connect(mapStateToProps, mapDispatch)(withRouter(Navbar));
