import React from 'react';
import { H3 } from 'components';
import { OrderDashboard } from 'features';
import styles from './OrderDashboardPage.module.scss';

function OrderDashboardPage() {
  return (
    <div className={styles.root}>
      <H3 className={styles.header} bold upperCase defaultText="Order dashboard">
        Translation.Page.OrderDashboard.header
      </H3>
      <OrderDashboard />
    </div>
  );
}

export default OrderDashboardPage;
