import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toastError } from 'utils/Toaster';
import {
  string,
  number,
  historyType,
  locationType,
  accountType,
  accountConfigurationStateType,
} from 'types';
import { createAccount } from 'services/account';
import { getAccountConfiguration } from 'services/configuration';
import CreateAccount from '.';

function CreateAccountContainer(props) {
  const {
    history,
    location,
    id,
    verificationKey,
    account,
    accountConfigurationState,
    ...other
  } = props;
  const { busy, timezones } = accountConfigurationState;

  useEffect(() => {
    if (!busy) {
      getAccountConfiguration();
    }
  }, []);

  const onRegister = (accountInformation) => {
    const request = {
      ...accountInformation,
      ...{
        id,
        verificationKey,
        timezone: accountInformation.timezone,
        // country: accountInformation.country.value
      },
    };
    createAccount(id, request)
      .then((response) => {
        history.push('/get-quotes');
      })
      .catch((error) => {
        toastError(
          'Translation.Feature.CreateAccount.Failed.toastLabel',
          'FAILED TO CREATE ACCOUNT'
        );
      });
  };

  const onConfirm = (createAccountError) => {
    if (!createAccountError) {
      history.push('/login');
    }
  };

  return (
    <CreateAccount
      {...other}
      onRegister={onRegister}
      onConfirm={onConfirm}
      busy={account.busy || busy}
      timezones={timezones}
      createAccountError={account.createAccountError}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    account: state.account,
    accountConfigurationState: state.configuration.account,
  };
};

CreateAccountContainer.propTypes = {
  history: historyType.isRequired,
  location: locationType.isRequired,
  id: number.isRequired,
  verificationKey: string.isRequired,
  account: accountType.isRequired,
  accountConfigurationState: accountConfigurationStateType.isRequired,
};

export default connect(mapStateToProps)(withRouter(CreateAccountContainer));
