import React from 'react';
import { string, func, bool, arrayOf, languageType } from 'types';
import clsx from 'clsx';
import { Select } from 'components';
import styles from './LanguageSelect.module.scss';

function LanguageSelect(props) {
  const { className, languages, selectedLanguage, onLanguageChange, useDefault } = props;
  const classNames = clsx(className, styles.root);

  const _onLanguageChange = option => {
    onLanguageChange(option.value.toLowerCase());
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? styles.whiteColor : styles.primaryColor,
      backgroundColor: state.isSelected ? styles.secondaryColor : styles.whiteColor,
      padding: 10,
      '&:active': {
        background: state.isFocused ? styles.secondaryBrightColor : null
      },
      '&:hover': {
        opacity: 0.7
      },
      cursor: 'pointer'
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: 'none',
      border: 'none',
      background: 'transparent',
      cursor: 'pointer',
      '&:hover': {
        '& div div': {
          color: styles.secondaryColor
        }
      },
      width: 120
    }),
    singleValue: (provided, state) => {
      return {
        ...provided,
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 2,
        height: 14,
        opacity: state.isDisabled ? 0.5 : 1,
        transition: 'all 0.3s ease 0s',
        color: useDefault ? styles.primaryColor : styles.whiteColor
      };
    },
    multiValue: (provided, { data }) => {
      return {
        ...provided,
        backgroundColor: styles.grayColor
      };
    },
    multiValueLabel: (provided, { data }) => ({
      ...provided,
      color: styles.primaryColor
    }),
    multiValueRemove: (provided, { data }) => ({
      ...provided,
      color: styles.primaryColor,
      ':hover': {
        backgroundColor: styles.darkGrayColor
      }
    }),
    indicatorSeparator: (provided, { data }) => ({
      ...provided,
      display: 'none'
    })
  };

  const selectedOption = languages.find(
    l => l.value.toUpperCase() === selectedLanguage.toUpperCase()
  );

  return (
    <Select
      className={classNames}
      customStyles={customStyles}
      options={languages}
      onChange={_onLanguageChange}
      value={selectedOption}
      isSearchable={false}
    />
  );
}

LanguageSelect.propTypes = {
  className: string,
  languages: arrayOf(languageType).isRequired,
  selectedLanguage: string.isRequired,
  onLanguageChange: func.isRequired,
  useDefault: bool
};

LanguageSelect.defaultProps = {
  className: null,
  useDefault: true
};

export default LanguageSelect;
