import React, { useState } from 'react';
import { string, func, bool } from 'types';
import clsx from 'clsx';
import { Label, Input, Button, Modal, Container } from 'components';
import { ResetPasswordConfirmation } from './components';
import styles from './ResetPassword.module.scss';

function ResetPassword(props) {
  const { className, onResetPassword, showConfirmation, onConfirm, onClosedConfirmation } = props;
  const classNames = clsx(className, styles.root);
  const [password, setPassword] = useState(null);
  const [passwordRepeat, setPasswordRepeat] = useState(null);
  const [passwordValid, setPasswordValid] = useState(null);
  const [passwordRepeatValid, setPasswordRepeatValid] = useState(null);

  const _onResetPassword = () => {
    if (validate()) {
      onResetPassword(password, passwordRepeat);
    }
  };

  const validate = () => {
    const currentPasswordValid = password !== undefined && password !== null && password.length > 5;
    const currentPasswordRepeatValid = password === passwordRepeat;

    setPasswordValid(currentPasswordValid);
    setPasswordRepeatValid(currentPasswordRepeatValid);

    return currentPasswordValid && currentPasswordRepeatValid;
  };

  const passwordChanged = value => {
    setPassword(value);
  };

  const passwordRepeatChanged = value => {
    setPasswordRepeat(value);
  };

  return [
    <Container key="1" className={classNames}>
      <div>
        <Label defaultText="Choose new password">
          Translation.Feature.ResetPassword.Password.label
        </Label>
        <Input
          className={styles.input}
          placeHolder="Translation.Feature.ResetPassword.Password.Input.placeholder"
          defaultPlaceHolder="Enter password"
          password
          onChange={passwordChanged}
          isValid={passwordValid}
          validationMessage="Translation.Feature.ResetPassword.Password.Input.validationMessage"
          defaultValidationMessage="Password needs to be at least 6 characters long"
          enterAction={_onResetPassword}
          name="new-password"
          autoComplete="new-password"
          maxValue={50}
        />
        <Label defaultText="Re-enter password">
          Translation.Feature.ResetPassword.PasswordRepeat.label
        </Label>
        <Input
          className={styles.input}
          placeHolder="Translation.Feature.ResetPassword.PasswordRepeat.Input.placeholder"
          defaultPlaceHolder="Enter password again"
          password
          onChange={passwordRepeatChanged}
          isValid={passwordRepeatValid}
          validationMessage="Translation.Feature.ResetPassword.PasswordRepeat.Input.validationMessage"
          defaultValidationMessage="Passwords do not match"
          enterAction={_onResetPassword}
          name="new-password"
          autoComplete="new-password"
          maxValue={50}
        />
        <div className={styles.buttonContainer}>
          <Button onClick={_onResetPassword} defaultText="Reset password">
            Translation.Feature.ResetPassword.Button.label
          </Button>
        </div>
      </div>
    </Container>,
    <Modal
      key="2"
      isOpen={showConfirmation}
      onClosed={onClosedConfirmation}
      title="Translation.Feature.ResetPassword.Modal.title"
      defaultTitle="Password successfully reset"
      hideOnBackDropClick={false}
    >
      <ResetPasswordConfirmation onConfirm={onConfirm} />
    </Modal>
  ];
}

ResetPassword.propTypes = {
  className: string,
  onResetPassword: func.isRequired,
  showConfirmation: bool.isRequired,
  onConfirm: func.isRequired,
  onClosedConfirmation: func.isRequired
};

ResetPassword.defaultProps = {
  className: null
};

export default ResetPassword;
