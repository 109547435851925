const isUserLoggedIn = () => {
  return getCookie('reword_pub') !== null;
};

const getUserRoles = () => {
  const json = getCookieAsJson();
  if(json) {
    return json.rol.map((r) => r.toLowerCase());
  }

  return [''];
};

const getUserFeatures = () => {
  const json = getCookieAsJson();
  if(json) {
    return json.fea.map((f) => f);
  }

  return [];
};

const getAccountName = () => {
  const json = getCookieAsJson();
  if(json) {
    return json.sub;
  }

  return '';
};

const getUserName = () => {
  const json = getCookieAsJson();
  if(json) {
    return json.cn;
  }
  return '';
};

function getCookieAsJson() {
    const cookie = getCookie('reword_pub');
    if (cookie) {
        return JSON.parse(b64DecodeUnicode(cookie));
    }
    return '';
}

function b64DecodeUnicode(str) {
  return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
}


const getCookie = (name) => {
  function escape(s) {
    return s.replace(/([.*+?^${}()|[\]/\\])/g, '\\$1');
  }

  const match = document && document.cookie.match(RegExp(`(?:^|;\\s*)${escape(name)}=([^;]*)`));

  return match ? match[1] : null;
};

export { isUserLoggedIn, getUserRoles, getUserFeatures, getAccountName, getUserName };
