import React, { useState } from 'react';
import { string, func, arrayOf, timezoneType } from 'types';
import clsx from 'clsx';
import { H3, H4, B2, Label, Input, Button, Select, Container } from 'components';
import isEmailValid from 'utils/emailValidator';
import styles from './Account.module.scss';

function Account(props) {
  const { className, onNextStep, timezones } = props;
  const classNames = clsx(className, styles.root);
  const [account, setAccount] = useState({ timezone: 'Europe/Stockholm' });
  const [validation, setValidation] = useState({});

  const _onNextStep = () => {
    if (validate()) {
      onNextStep(account);
    }
  };

  const emailChanged = value => {
    setAccount({ ...account, email: value });
  };

  const passwordChanged = value => {
    setAccount({ ...account, password: value });
  };

  const emailRepeatChanged = value => {
    setAccount({ ...account, emailRepeat: value });
  };

  const passwordRepeatChanged = value => {
    setAccount({ ...account, passwordRepeat: value });
  };

  // const timezoneChanged = value => {
  //   setAccount({ ...account, timezone: value });
  // };

  const validate = () => {
    const currentValidation = {
      emailValid: false,
      emailRepeatValid: false,
      passwordValid: false,
      passwordRepeatValid: false,
      // timezoneValid: false,
      allValid: false
    };

    currentValidation.emailValid = isEmailValid(account.email);
    currentValidation.emailRepeatValid = account.email === account.emailRepeat;

    currentValidation.passwordValid = account.password !== undefined && account.password.length > 5;
    currentValidation.passwordRepeatValid = account.password === account.passwordRepeat;

    // currentValidation.timezoneValid = account.timezone !== undefined && account.timezone !== null;

    currentValidation.allValid =
      currentValidation.emailValid &&
      currentValidation.emailRepeatValid &&
      currentValidation.passwordValid &&
      currentValidation.passwordRepeatValid;
    //  && currentValidation.timezoneValid;

    setValidation(currentValidation);

    return currentValidation.allValid;
  };

  return (
    <div className={classNames}>
      <H3 className={styles.header} bold upperCase defaultText="Register - Step 1(2)">
        Translation.Feature.CreateAccount.Account.RegisterStep.header
      </H3>
      <Container className={styles.container}>
        <div>
          <H4 className={styles.subHeader} bold upperCase defaultText="Create an account">
            Translation.Feature.CreateAccount.Account.Create.header
          </H4>
          <form autoComplete="on">
            <Label defaultText="Email address">
              Translation.Feature.CreateAccount.Account.Email.label
            </Label>
            <Input
              className={styles.input}
              placeHolder="Translation.Feature.CreateAccount.Account.Email.Input.placeholder"
              defaultPlaceHolder="Enter your email address"
              onChange={emailChanged}
              isValid={validation.emailValid}
              validationMessage="Translation.Feature.CreateAccount.Account.Email.Input.validationMessage"
              defaultValidationMessage="Please provide a valid email"
              enterAction={_onNextStep}
              name="email"
              id="email"
              type="email"
              autoFocus
              maxValue={50}
            />
            <Label defaultText="Re-enter email address">
              Translation.Feature.CreateAccount.Account.EmailRepeat.label
            </Label>
            <Input
              className={styles.input}
              placeHolder="Translation.Feature.CreateAccount.Account.EmailRepeat.Input.placeholder"
              defaultPlaceHolder="Enter your email address again"
              onChange={emailRepeatChanged}
              isValid={validation.emailRepeatValid}
              validationMessage="Translation.Feature.CreateAccount.Account.EmailRepeat.Input.validationMessage"
              defaultValidationMessage="Emails do not match"
              enterAction={_onNextStep}
              maxValue={50}
            />
            <Label defaultText="Choose password">
              Translation.Feature.CreateAccount.Account.Password.label
            </Label>
            <Input
              className={styles.input}
              placeHolder="Translation.Feature.CreateAccount.Account.Password.Input.placeholder"
              defaultPlaceHolder="Enter password"
              password
              onChange={passwordChanged}
              isValid={validation.passwordValid}
              validationMessage="Translation.Feature.CreateAccount.Account.Password.Input.validationMessage"
              defaultValidationMessage="Password needs to be at least 6 characters long"
              enterAction={_onNextStep}
              name="new-password"
              autoComplete="new-password"
              maxValue={50}
            />
            <Label defaultText="Re-enter password">
              Translation.Feature.CreateAccount.Account.PasswordRepeat.label
            </Label>
            <Input
              className={styles.input}
              placeHolder="Translation.Feature.CreateAccount.Account.PasswordRepeat.Input.placeholder"
              defaultPlaceHolder="Enter password again"
              password
              onChange={passwordRepeatChanged}
              isValid={validation.passwordRepeatValid}
              validationMessage="Translation.Feature.CreateAccount.Account.PasswordRepeat.Input.validationMessage"
              defaultValidationMessage="Passwords do not match"
              enterAction={_onNextStep}
              name="new-password"
              autoComplete="new-password"
              maxValue={50}
            />
            {/* <Label defaultText="Timezone" className={styles.timezoneLabel}>
              Translation.Feature.CreateAccount.Account.Timezone.label
            </Label>
            <B2
              defaultText="Your timezone is needed to display delivery time using local time."
              className={styles.timezoneSubLabel}
            >
              Translation.Feature.CreateAccount.Account.Timezone.Description.label
            </B2>
            <Select
              options={timezones}
              onChange={timezoneChanged}
              value={account.timezone}
              isValid={validation.timezoneValid}
              validationMessage="Translation.Feature.CreateAccount.Account.Timezone.Select.validationMessage"
              defaultValidationMessage="Please select a timezone"
            /> */}
            <Button
              onClick={_onNextStep}
              preRightArrow
              className={styles.button}
              defaultText="Next step"
            >
              Translation.Feature.CreateAccount.Account.Next.Button.label
            </Button>
          </form>
        </div>
      </Container>
    </div>
  );
}

Account.propTypes = {
  className: string,
  onNextStep: func.isRequired,
  timezones: arrayOf(timezoneType).isRequired
};

Account.defaultProps = {
  className: null
};

export default Account;
