import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  adminOrderStateType,
  quoteConfigurationStateType,
  orderConfigurationStateType,
} from 'types';
import {
  getOrders,
  approveOrder,
  rejectOrder,
  setOrderInvoiced,
  setOrderPaid,
  connectProject,
} from 'services/admin';
import { getQuoteConfiguration, getOrderConfiguration } from 'services/configuration';
import { toastSuccess, toastError } from 'utils/Toaster';
import AdminOrders from '.';

function AdminOrdersContainer(props) {
  const { adminOrderState, quoteConfigurationState, orderConfigurationState } = props;

  const { orders } = adminOrderState;
  const { sourceLanguages, targetLanguages } = quoteConfigurationState;
  const busy = adminOrderState.busy || quoteConfigurationState.busy || orderConfigurationState.busy;

  useEffect(() => {
    if (!busy) {
      getOrders();
      getQuoteConfiguration();
      getOrderConfiguration();
    }
  }, []);

  if (busy) {
    return null;
  }

  const onApproveOrder = (orderId) => {
    approveOrder(orderId)
      .then(() => {
        toastSuccess(
          'Translation.Feature.AdminOrders.ApproveOrderSuccess.toastLabel',
          'ORDER APPROVED'
        );
      })
      .catch((error) => {
        toastError(
          'Translation.Feature.AdminOrders.ApproveOrderFailed.toastLabel',
          'APPROVE ORDER FAILED'
        );
      });
  };

  const onRejectOrder = (orderId) => {
    rejectOrder(orderId)
      .then(() => {
        toastSuccess(
          'Translation.Feature.AdminOrders.RejectOrderSuccess.toastLabel',
          'ORDER REJECTED'
        );
      })
      .catch((error) => {
        toastError(
          'Translation.Feature.AdminOrders.RejectOrderFailed.toastLabel',
          'REJECT ORDER FAILED'
        );
      });
  };

  const onSetOrderInvoiced = (orderId) => {
    setOrderInvoiced(orderId)
      .then(() => {
        toastSuccess(
          'Translation.Feature.AdminOrders.SetOrderInvoicedSuccess.toastLabel',
          'ORDER SET AS INVOICED'
        );
      })
      .catch((error) => {
        toastError(
          'Translation.Feature.AdminOrders.SetOrderInvoicedFailed.toastLabel',
          'ORDER SET AS INVOICED FAILED'
        );
      });
  };

  const onSetOrderPaid = (orderId) => {
    setOrderPaid(orderId)
      .then(() => {
        toastSuccess(
          'Translation.Feature.AdminOrders.SetOrderPaidSuccess.toastLabel',
          'ORDER SET AS PAID'
        );
      })
      .catch((error) => {
        toastError(
          'Translation.Feature.AdminOrders.SetOrderPaidFailed.toastLabel',
          'ORDER SET AS PAID FAILED'
        );
      });
  };

  const onConnectProject = (orderId, projectId) => {
    connectProject(orderId, projectId)
      .then(() => {
        getOrders();
        toastSuccess(
          'Translation.Feature.AdminOrders.ConnectProjectSuccess.toastLabel',
          'PROJECT CONNECTED'
        );
      })
      .catch((error) => {
        toastError(
          'Translation.Feature.AdminOrders.ConnectProjectFailed.toastLabel',
          'FAILED TO CONNECT PROJECT'
        );
      });
  };

  return (
    <AdminOrders
      filters={orderConfigurationState.orderFilterStatus}
      orderStatuses={orderConfigurationState.orderStatus}
      orders={orders}
      busy={busy}
      languages={[...sourceLanguages, ...targetLanguages]}
      onApproveOrder={onApproveOrder}
      onRejectOrder={onRejectOrder}
      onSetOrderInvoiced={onSetOrderInvoiced}
      onSetOrderPaid={onSetOrderPaid}
      onConnectProject={onConnectProject}
    />
  );
}

AdminOrdersContainer.propTypes = {
  adminOrderState: adminOrderStateType.isRequired,
  quoteConfigurationState: quoteConfigurationStateType.isRequired,
  orderConfigurationState: orderConfigurationStateType.isRequired,
};

const mapStateToProps = (state) => ({
  adminOrderState: state.admin.adminOrder,
  quoteConfigurationState: state.configuration.quote,
  orderConfigurationState: state.configuration.order,
});

export default connect(mapStateToProps)(AdminOrdersContainer);
