import sessionActions from 'services/session/actions';
import api from '../api';
import store from '../../store';
import accountActions from './actions';

const accountEndpoint = 'accounts';

const registerAccount = request => {
  store.dispatch(accountActions.registerAccountRequest());
  return api
    .post(`${accountEndpoint}`, request)
    .then(response => store.dispatch(accountActions.registerAccountSuccess(response.data)))
    .catch(error => {
      store.dispatch(accountActions.registerAccountFailed());
      throw error;
    });
};

const createAccount = (id, request) => {
  store.dispatch(accountActions.createAccountRequest());
  return api
    .put(`${accountEndpoint}/${id}`, request)
    .then(response => {
      store.dispatch(accountActions.createAccountSuccess(response.data));
      store.dispatch(sessionActions.loginSucceeded());
    })
    .catch(error => {
      store.dispatch(
        accountActions.createAccountFailed({
          translationKey: error.data.translationKey,
          defaultText: error.data.defaultTranslation
        })
      );
      throw error;
    });
};

const forgotPassword = email => {
  store.dispatch(accountActions.forgotPasswordRequest());
  const request = {
    username: email
  };
  return api
    .post(`${accountEndpoint}/reset-password`, request)
    .then(response => store.dispatch(accountActions.forgotPasswordSuccess()))
    .catch(error => {
      store.dispatch(accountActions.forgotPasswordFailed());
      throw error;
    });
};

const resetPassword = (userId, resetPasswordKey, password, passwordRepeat) => {
  store.dispatch(accountActions.resetPasswordRequest());
  const request = {
    userId,
    resetPasswordKey,
    password,
    passwordRepeat
  };
  return api
    .put(`${accountEndpoint}/${userId}/reset-password`, request)
    .then(response => store.dispatch(accountActions.resetPasswordSuccess()))
    .catch(error => {
      store.dispatch(accountActions.resetPasswordFailed());
      throw error;
    });
};

const verifyEmail = (id, verificationKey) => {
  const request = {
    id,
    verificationKey
  };

  return api.post(`${accountEndpoint}/${id}/verify-email`, request).catch(error => {
    throw error;
  });
};

const getAccount = () => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve({
        companyName: 'Ventue Labs AB',
        contactName: 'Fredrik Sahlén',
        address1: 'Kvarnbyvallen 30',
        address2: 'c/o Sanja Sahlén',
        postalCode: '431 34',
        city: 'Mölndal',
        country: 'Sweden',
        vat: '555566663',
        email: 'sahlen@venturelabs.se',
        password: 'p@ssw0rd',
        timezone: 'Europe/Stockholm'
      });
    }, 500);
  });
};

const updateAccount = account => {
  console.log(account);
};

export {
  registerAccount,
  createAccount,
  forgotPassword,
  resetPassword,
  getAccount,
  updateAccount,
  verifyEmail
};
