import React from 'react';
import { string, func } from 'types';
import clsx from 'clsx';
import { B1, Button } from 'components';
import styles from './ForgotPasswordConfirmation.module.scss';

function ForgotPasswordConfirmation(props) {
  const { className, onConfirm } = props;
  const classNames = clsx(className, styles.root);

  return (
    <div className={classNames}>
      <B1 defaultText="Please check your email from Reword containing a reset password link.">
        Translation.Feature.ForgotPassword.ResetPasswordConfirmation.Confirmation.label
      </B1>
      <Button className={styles.confirmButton} onClick={onConfirm} defaultText="Ok">
        Translation.Feature.ForgotPassword.ResetPasswordConfirmation.Confirmation.Button.label
      </Button>
    </div>
  );
}

ForgotPasswordConfirmation.propTypes = {
  className: string,
  onConfirm: func.isRequired
};

ForgotPasswordConfirmation.defaultProps = {
  className: null
};

export default ForgotPasswordConfirmation;
