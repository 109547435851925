import React from 'react';
import { H3 } from 'components';
import { Login, Register } from 'features';
import styles from './LoginPage.module.scss';

function LoginPage() {
  return (
    <div className={styles.root}>
      <H3 className={styles.header} bold upperCase defaultText="Login">
        Translation.Page.Login.header
      </H3>
      <div className={styles.loginAndRegisterContainer}>
        <Login className={styles.login} />
        <Register className={styles.register} />
      </div>
    </div>
  );
}

export default LoginPage;
