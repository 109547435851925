import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { func, historyType, sessionStateType } from 'types';

const PrivateRoute = props => {
  const { render, history, sessionState, ...rest } = props;
  const { userLoggedIn, userRoles, cookieExpired } = sessionState;
  if (!userLoggedIn) {
    if (history.action !== 'REPLACE') {
      if (cookieExpired) {
        history.replace('/login');
      } else {
        history.replace('/');
      }
    }
    return null;
  }

  return <Route {...rest} render={() => render(userRoles)} />;
};

PrivateRoute.propTypes = {
  render: func.isRequired,
  history: historyType.isRequired,
  sessionState: sessionStateType.isRequired
};

const mapStateToProps = state => {
  return {
    sessionState: state.session
  };
};

export default connect(mapStateToProps)(PrivateRoute);
