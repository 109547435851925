import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare, faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import clsx from 'clsx';
import { B1 } from 'components';
import { func, bool, string, oneOfType, number } from 'types';
import styles from './CheckBox.module.scss';

function CheckBox(props) {
  const {
    className,
    checked,
    onChange,
    disabled,
    label,
    defaultLabel,
    translateLabel,
    labelClassName,
    checkBoxClassName,
    id
  } = props;
  const [_checked, setChecked] = useState(checked);
  const classNames = clsx(className, styles.root);
  const checkBoxClassNames = clsx(checkBoxClassName, styles.checkBox, disabled && styles.disabled);

  const _onChange = () => {
    setChecked(!_checked);
    if (onChange && !disabled) {
      onChange(!_checked);
    }
  };

  const renderLabel = label ? (
    <B1
      translate={translateLabel}
      defaultText={defaultLabel}
      className={clsx(styles.label, labelClassName)}
    >
      {label}
    </B1>
  ) : null;

  if (_checked) {
    return (
      <div className={classNames} key={id}>
        <FontAwesomeIcon icon={faCheckSquare} className={checkBoxClassNames} onClick={_onChange} />
        {renderLabel}
      </div>
    );
  }

  return (
    <div className={classNames} key={id}>
      <FontAwesomeIcon icon={faSquare} className={checkBoxClassNames} onClick={_onChange} />
      {renderLabel}
    </div>
  );
}

CheckBox.propTypes = {
  className: string,
  checked: bool,
  onChange: func,
  disabled: bool,
  label: string,
  defaultLabel: string,
  translateLabel: bool,
  labelClassName: string,
  checkBoxClassName: string,
  id: oneOfType([string, number])
};

CheckBox.defaultProps = {
  className: null,
  checked: false,
  onChange: null,
  disabled: false,
  label: null,
  defaultLabel: null,
  translateLabel: true,
  labelClassName: null,
  checkBoxClassName: null,
  id: null
};

export default CheckBox;
