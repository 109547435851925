import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import { S1, H3, Button } from 'components';
import { string, func, instanceOf } from 'types';
import styles from './ConfirmOrder.module.scss';

function ConfirmOrder(props) {
  const { className, orderReceived, onConfirm } = props;
  const intl = useIntl();
  const classNames = clsx(className, styles.root);

  return (
    <div className={classNames}>
      <H3 className={styles.header} bold upperCase defaultText="Order received">
        Translation.Feature.ConfirmOrder.header
      </H3>
      <S1 className={styles.subHeader} translate={false}>
        <FormattedMessage
          id="Translation.Feature.ConfirmOrder.subHeader"
          defaultMessage="Order received at <b>{orderReceived}</b>"
          values={{
            b: (...chunks) => <b>{chunks}</b>,
            orderReceived: `${intl.formatDate(orderReceived, {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric'
            })} ${intl.formatTime(orderReceived, {
              hour: 'numeric',
              minute: 'numeric'
            })}`
          }}
        />
      </S1>
      <S1
        className={styles.subHeader}
        defaultText="You will receive an email notification when the order is confirmed and another one when the
        translations are ready to download."
      >
        Translation.Feature.ConfirmOrder.Information.label
      </S1>
      <Button onClick={onConfirm} className={styles.confirmButton} defaultText="Go to orders">
        Translation.Feature.ConfirmOrder.Confirm.Button.label
      </Button>
    </div>
  );
}

ConfirmOrder.propTypes = {
  className: string,
  orderReceived: instanceOf(Date).isRequired,
  onConfirm: func.isRequired
};

ConfirmOrder.defaultProps = {
  className: null
};

export default ConfirmOrder;
