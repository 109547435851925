import React from 'react';
import { ensuredForwardRef } from 'react-use';
import { useIntl, FormattedMessage } from 'react-intl';
import { string } from 'types';
import clsx from 'clsx';
import { H3, S1 } from 'components';
import styles from './About.module.scss';

const About = ensuredForwardRef((props, ref) => {
  const { className } = props;
  const intl = useIntl();
  const classNames = clsx(className, styles.root);

  const renderAbout = () => {
    return [
      <H3 className={styles.header} bold upperCase defaultText="About reword" key="1">
        Translation.Feature.About.AboutReword.header
      </H3>,
      !!intl.messages['Translation.Feature.About.AboutReword.description'] && (
        <S1 translate={false} className={styles.paragraph} key="2">
          <FormattedMessage
            id="Translation.Feature.About.AboutReword.description"
            defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a <i>default text.</i>"
            values={{
              b: (...chunks) => <b>{chunks}</b>,
              i: (...chunks) => <i>{chunks}</i>,
              NL: <br />,
              nl: <br />,
              Nl: <br />,
            }}
          />
        </S1>
      ),
      !!intl.messages['Translation.Feature.About.AboutReword.description2'] && (
        <S1 translate={false} className={styles.paragraph} key="3">
          <FormattedMessage
            id="Translation.Feature.About.AboutReword.description2"
            defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a <i>default text.</i>"
            values={{
              b: (...chunks) => <b>{chunks}</b>,
              i: (...chunks) => <i>{chunks}</i>,
              NL: <br />,
              nl: <br />,
              Nl: <br />,
            }}
          />
        </S1>
      ),
      !!intl.messages['Translation.Feature.About.AboutReword.description3'] && (
        <S1 translate={false} className={styles.paragraph} key="4">
          <FormattedMessage
            id="Translation.Feature.About.AboutReword.description3"
            defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a <i>default text.</i>"
            values={{
              b: (...chunks) => <b>{chunks}</b>,
              i: (...chunks) => <i>{chunks}</i>,
              NL: <br />,
              nl: <br />,
              Nl: <br />,
            }}
          />
        </S1>
      ),
      !!intl.messages['Translation.Feature.About.AboutReword.description4'] && (
        <S1 translate={false} className={styles.paragraph} key="5">
          <FormattedMessage
            id="Translation.Feature.About.AboutReword.description4"
            defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a <i>default text.</i>"
            values={{
              b: (...chunks) => <b>{chunks}</b>,
              i: (...chunks) => <i>{chunks}</i>,
              NL: <br />,
              nl: <br />,
              Nl: <br />,
            }}
          />
        </S1>
      ),
      !!intl.messages['Translation.Feature.About.AboutReword.description5'] && (
        <S1 translate={false} className={styles.paragraph} key="6">
          <FormattedMessage
            id="Translation.Feature.About.AboutReword.description5"
            defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a <i>default text.</i>"
            values={{
              b: (...chunks) => <b>{chunks}</b>,
              i: (...chunks) => <i>{chunks}</i>,
              NL: <br />,
              nl: <br />,
              Nl: <br />,
            }}
          />
        </S1>
      ),
    ];
  };

  const renderBackground = () => {
    return [
      <H3
        className={clsx(styles.header, styles.marginTop)}
        bold
        upperCase
        defaultText="Background"
        key="7"
      >
        Translation.Feature.About.Background.header
      </H3>,
      !!intl.messages['Translation.Feature.About.Background.description'] && (
        <S1 translate={false} className={styles.paragraph} key="8">
          <FormattedMessage
            id="Translation.Feature.About.Background.description"
            defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a <i>default text.</i>"
            values={{
              b: (...chunks) => <b>{chunks}</b>,
              i: (...chunks) => <i>{chunks}</i>,
              NL: <br />,
              nl: <br />,
              Nl: <br />,
            }}
          />
        </S1>
      ),
      !!intl.messages['Translation.Feature.About.Background.description2'] && (
        <S1 translate={false} className={styles.paragraph} key="9">
          <FormattedMessage
            id="Translation.Feature.About.Background.description2"
            defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a <i>default text.</i>"
            values={{
              b: (...chunks) => <b>{chunks}</b>,
              i: (...chunks) => <i>{chunks}</i>,
              NL: <br />,
              nl: <br />,
              Nl: <br />,
            }}
          />
        </S1>
      ),
      !!intl.messages['Translation.Feature.About.Background.description3'] && (
        <S1 translate={false} className={styles.paragraph} key="10">
          <FormattedMessage
            id="Translation.Feature.About.Background.description3"
            defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a <i>default text.</i>"
            values={{
              b: (...chunks) => <b>{chunks}</b>,
              i: (...chunks) => <i>{chunks}</i>,
              NL: <br />,
              nl: <br />,
              Nl: <br />,
            }}
          />
        </S1>
      ),
      !!intl.messages['Translation.Feature.About.Background.description4'] && (
        <S1 translate={false} className={styles.paragraph} key="11">
          <FormattedMessage
            id="Translation.Feature.About.Background.description4"
            defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a <i>default text.</i>"
            values={{
              b: (...chunks) => <b>{chunks}</b>,
              i: (...chunks) => <i>{chunks}</i>,
              NL: <br />,
              nl: <br />,
              Nl: <br />,
            }}
          />
        </S1>
      ),
      !!intl.messages['Translation.Feature.About.Background.description5'] && (
        <S1 translate={false} className={styles.paragraph} key="12">
          <FormattedMessage
            id="Translation.Feature.About.Background.description5"
            defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a <i>default text.</i>"
            values={{
              b: (...chunks) => <b>{chunks}</b>,
              i: (...chunks) => <i>{chunks}</i>,
              NL: <br />,
              nl: <br />,
              Nl: <br />,
            }}
          />
        </S1>
      ),
    ];
  };

  const renderOffer = () => {
    return [
      <H3
        className={clsx(styles.header, styles.marginTop)}
        bold
        upperCase
        defaultText="Solution/offer"
        key="13"
      >
        Translation.Feature.About.Offer.header
      </H3>,
      !!intl.messages['Translation.Feature.About.Offer.description'] && (
        <S1 translate={false} className={styles.paragraph} key="14">
          <FormattedMessage
            id="Translation.Feature.About.Offer.description"
            defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a <i>default text.</i>"
            values={{
              b: (...chunks) => <b>{chunks}</b>,
              i: (...chunks) => <i>{chunks}</i>,
              NL: <br />,
              nl: <br />,
              Nl: <br />,
            }}
          />
        </S1>
      ),
      !!intl.messages['Translation.Feature.About.Offer.description2'] && (
        <S1 translate={false} className={styles.paragraph} key="15">
          <FormattedMessage
            id="Translation.Feature.About.Offer.description2"
            defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a <i>default text.</i>"
            values={{
              b: (...chunks) => <b>{chunks}</b>,
              i: (...chunks) => <i>{chunks}</i>,
              NL: <br />,
              nl: <br />,
              Nl: <br />,
            }}
          />
        </S1>
      ),
      !!intl.messages['Translation.Feature.About.Offer.description3'] && (
        <S1 translate={false} className={styles.paragraph} key="16">
          <FormattedMessage
            id="Translation.Feature.About.Offer.description3"
            defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a <i>default text.</i>"
            values={{
              b: (...chunks) => <b>{chunks}</b>,
              i: (...chunks) => <i>{chunks}</i>,
              NL: <br />,
              nl: <br />,
              Nl: <br />,
            }}
          />
        </S1>
      ),
      !!intl.messages['Translation.Feature.About.Offer.description4'] && (
        <S1 translate={false} className={styles.paragraph} key="17">
          <FormattedMessage
            id="Translation.Feature.About.Offer.description4"
            defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a <i>default text.</i>"
            values={{
              b: (...chunks) => <b>{chunks}</b>,
              i: (...chunks) => <i>{chunks}</i>,
              NL: <br />,
              nl: <br />,
              Nl: <br />,
            }}
          />
        </S1>
      ),
      !!intl.messages['Translation.Feature.About.Offer.description5'] && (
        <S1 translate={false} className={styles.paragraph} key="18">
          <FormattedMessage
            id="Translation.Feature.About.Offer.description5"
            defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a <i>default text.</i>"
            values={{
              b: (...chunks) => <b>{chunks}</b>,
              i: (...chunks) => <i>{chunks}</i>,
              NL: <br />,
              nl: <br />,
              Nl: <br />,
            }}
          />
        </S1>
      ),
      !!intl.messages['Translation.Feature.About.Offer.description6'] && (
        <S1 translate={false} className={styles.paragraph} key="19">
          <FormattedMessage
            id="Translation.Feature.About.Offer.description6"
            defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a <i>default text.</i>"
            values={{
              b: (...chunks) => <b>{chunks}</b>,
              i: (...chunks) => <i>{chunks}</i>,
              NL: <br />,
              nl: <br />,
              Nl: <br />,
            }}
          />
        </S1>
      ),
      !!intl.messages['Translation.Feature.About.Offer.description7'] && (
        <S1 translate={false} className={styles.paragraph} key="20">
          <FormattedMessage
            id="Translation.Feature.About.Offer.description7"
            defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a <i>default text.</i>"
            values={{
              b: (...chunks) => <b>{chunks}</b>,
              i: (...chunks) => <i>{chunks}</i>,
              NL: <br />,
              nl: <br />,
              Nl: <br />,
            }}
          />
        </S1>
      ),
      !!intl.messages['Translation.Feature.About.Offer.description8'] && (
        <S1 translate={false} className={styles.paragraph} key="21">
          <FormattedMessage
            id="Translation.Feature.About.Offer.description8"
            defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a <i>default text.</i>"
            values={{
              b: (...chunks) => <b>{chunks}</b>,
              i: (...chunks) => <i>{chunks}</i>,
              NL: <br />,
              nl: <br />,
              Nl: <br />,
            }}
          />
        </S1>
      ),
      !!intl.messages['Translation.Feature.About.Offer.description9'] && (
        <S1 translate={false} className={styles.paragraph} key="22">
          <FormattedMessage
            id="Translation.Feature.About.Offer.description9"
            defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a <i>default text.</i>"
            values={{
              b: (...chunks) => <b>{chunks}</b>,
              i: (...chunks) => <i>{chunks}</i>,
              NL: <br />,
              nl: <br />,
              Nl: <br />,
            }}
          />
        </S1>
      ),
      !!intl.messages['Translation.Feature.About.Offer.description10'] && (
        <S1 translate={false} className={styles.paragraph} key="23">
          <FormattedMessage
            id="Translation.Feature.About.Offer.description10"
            defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a <i>default text.</i>"
            values={{
              b: (...chunks) => <b>{chunks}</b>,
              i: (...chunks) => <i>{chunks}</i>,
              NL: <br />,
              nl: <br />,
              Nl: <br />,
            }}
          />
        </S1>
      ),
    ];
  };

  return (
    <div className={classNames} ref={ref}>
      {renderAbout()}
      {renderBackground()}
      {renderOffer()}
    </div>
  );
});

About.propTypes = {
  className: string,
};

About.defaultProps = {
  className: null,
};

export default About;
