import React from 'react';
import { string, func } from 'types';
import clsx from 'clsx';
import { B1, Button } from 'components';
import styles from './RegistrationConfirmation.module.scss';

function RegistrationConfirmation(props) {
  const { className, onConfirm } = props;
  const classNames = clsx(className, styles.root);

  return (
    <div className={classNames}>
      <B1
        defaultText="Thank you for registering your account. Please proceed to login page and enter your
        credentials."
      >
        Translation.Feature.CreateAccount.RegistrationConfirmation.Confirmation.label
      </B1>
      <Button className={styles.confirmButton} onClick={onConfirm} defaultText="Confirm">
        Translation.Feature.CreateAccount.RegistrationConfirmation.Confirmation.Button.label
      </Button>
    </div>
  );
}

RegistrationConfirmation.propTypes = {
  className: string,
  onConfirm: func.isRequired
};

RegistrationConfirmation.defaultProps = {
  className: null
};

export default RegistrationConfirmation;
