import { createAction } from '@reduxjs/toolkit';

export default {
  getAccountConfigurationRequest: createAction('configuration/getAccountConfigurationRequest'),
  getAccountConfigurationSuccess: createAction('configuration/getAccountConfigurationSuccess'),
  getAccountConfigurationFailed: createAction('configuration/getAccountConfigurationFailed'),
  getQuoteConfigurationRequest: createAction('configuration/getQuoteConfigurationRequest'),
  getQuoteConfigurationSuccess: createAction('configuration/getQuoteConfigurationSuccess'),
  getQuoteConfigurationFailed: createAction('configuration/getQuoteConfigurationFailed'),
  getApplicationConfigurationRequest: createAction(
    'configuration/getApplicationConfigurationRequest'
  ),
  getApplicationConfigurationSuccess: createAction(
    'configuration/getApplicationConfigurationSuccess'
  ),
  getApplicationConfigurationFailed: createAction(
    'configuration/getApplicationConfigurationFailed'
  ),
  changeLanguage: createAction('configuration/changeLanguage'),
  getOrderConfigurationRequest: createAction('configuration/getOrderConfigurationRequest'),
  getOrderConfigurationSuccess: createAction('configuration/getOrderConfigurationSuccess'),
  getOrderConfigurationFailed: createAction('configuration/getOrderConfigurationFailed'),
  getAdminConfigurationRequest: createAction('configuration/getAdminConfigurationRequest'),
  getAdminConfigurationSuccess: createAction('configuration/getAdminConfigurationSuccess'),
  getAdminConfigurationFailed: createAction('configuration/getAdminConfigurationFailed'),
  getStatsConfigurationSuccess: createAction('configuration/getStatsConfigurationSuccess'),
  getFaqConfigurationSuccess: createAction('configuration/getFaqConfigurationSuccess')
};
