import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { historyType, string, number } from 'types';
import { resetPassword } from 'services/account';
import ResetPassword from '.';

function ResetPasswordContainer(props) {
  const { history, id, resetPasswordKey } = props;
  const [showConfirmation, setShowConfirmation] = useState(false);

  const onResetPassword = (password, passwordRepeat) => {
    resetPassword(id, resetPasswordKey, password, passwordRepeat).then(() =>
      setShowConfirmation(true)
    );
  };

  const onConfirm = () => {
    setShowConfirmation(false);
  };

  const onClosedConfirmation = () => {
    history.push('/login');
  };

  return (
    <ResetPassword
      {...props}
      onResetPassword={onResetPassword}
      showConfirmation={showConfirmation}
      onConfirm={onConfirm}
      onClosedConfirmation={onClosedConfirmation}
    />
  );
}

ResetPasswordContainer.propTypes = {
  history: historyType.isRequired,
  id: number.isRequired,
  resetPasswordKey: string.isRequired
};

export default withRouter(ResetPasswordContainer);
