import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { H3, S1, Label, Input, Select, Button, Container } from 'components';
import {
  languagesType,
  languageType,
  string,
  func,
  number,
  arrayOf,
  translationSubjectType,
} from 'types';
import styles from './GetQuote.module.scss';

function GetQuotes(props) {
  const {
    className,
    onGetQuotes,
    sourceLanguages,
    targetLanguages,
    selectedSourceLanguage,
    selectedTargetLanguages,
    translationSubjects,
    selectedTranslationSubject,
    wordCount,
    wordCountLimit,
  } = props;
  const [_selectedSourceLanguage, setSelectedSourceLanguage] = useState(selectedSourceLanguage);
  const [_selectedTargetLanguages, setSelectedTargetLanguages] = useState(selectedTargetLanguages);
  const [_wordCount, setWordCount] = useState(wordCount);
  const [currentSourceLanguages, setCurrentSourceLanguages] = useState(sourceLanguages);
  const [currentTargetLanguages, setCurrentTargetLanguages] = useState(targetLanguages);
  const [_selectedTranslationSubject, setSelectedTranslationSubject] = useState(
    selectedTranslationSubject || translationSubjects.find((ts) => ts.value === '1')
  );
  const [canGetQuotes, setCanGetQuotes] = useState(false);

  const classNames = clsx(className, styles.root);

  useEffect(() => {
    if (selectedSourceLanguage) {
      filterTargetLanguages(selectedSourceLanguage);
    }

    if (selectedTargetLanguages.length > 0) {
      filterSourceLanguages(selectedTargetLanguages);
    }
  }, []);

  useEffect(() => {
    setCanGetQuotes(
      _selectedSourceLanguage &&
        _selectedTargetLanguages.length > 0 &&
        _wordCount &&
        _selectedTranslationSubject
    );
  }, [_selectedSourceLanguage, _selectedTargetLanguages, _wordCount, _selectedTranslationSubject]);

  const filterSourceLanguages = (targetLangs) => {
    const selectedTargetLanguageValues = targetLangs ? targetLangs.map((lang) => lang.value) : [];
    let filteredSourceLanguages = sourceLanguages;
    filteredSourceLanguages = filteredSourceLanguages.filter(
      (lang) => selectedTargetLanguageValues.indexOf(lang.value) === -1
    );

    const filteredSelectedSourceLanguage =
      _selectedTargetLanguages &&
      _selectedSourceLanguage &&
      selectedTargetLanguageValues.indexOf(_selectedSourceLanguage.value) !== -1
        ? null
        : _selectedSourceLanguage;

    setCurrentSourceLanguages(filteredSourceLanguages);
    setSelectedSourceLanguage(filteredSelectedSourceLanguage);
  };

  const filterTargetLanguages = (sourceLanguage) => {
    let filteredTargetLanguages = targetLanguages;
    filteredTargetLanguages = filteredTargetLanguages.filter(
      (lang) => lang.value !== sourceLanguage.value
    );
    const filteredSelectedTargetLanguages = _selectedTargetLanguages.filter(
      (lang) => lang.value !== sourceLanguage
    );

    setCurrentTargetLanguages(filteredTargetLanguages);
    setSelectedTargetLanguages(filteredSelectedTargetLanguages);
  };

  const sourceLanguageChanged = (value) => {
    filterTargetLanguages(value);
    setSelectedSourceLanguage(value);
  };

  const targetLanguagesChanged = (value) => {
    filterSourceLanguages(value);
    if (!value) {
      setSelectedTargetLanguages([]);
    } else {
      setSelectedTargetLanguages(value);
    }
  };

  const wordCountChanged = (value) => {
    setWordCount(parseInt(value, 10));
  };

  const translationSubjectChanged = (value) => {
    setSelectedTranslationSubject(value);
  };

  const getQuotes = () => {
    const request = {
      sourceLanguage: _selectedSourceLanguage,
      targetLanguages: _selectedTargetLanguages,
      wordCount: _wordCount,
      translationSubject: _selectedTranslationSubject,
    };

    onGetQuotes(request);
  };

  return (
    <div className={classNames}>
      <H3 className={styles.header} bold upperCase defaultText="Get a translation quote">
        Translation.Feature.GetQuotes.Header.label
      </H3>
      <S1
        className={styles.subHeader}
        defaultText="Select the langauage of the source documents and one or more languages you would like it
        translated into."
      >
        Translation.Feature.GetQuotes.SubHeader.label
      </S1>
      <Container>
        <div>
          <div className={styles.translateFromContainer}>
            <Label defaultText="Translate from">
              Translation.Feature.GetQuotes.TranslateFrom.label
            </Label>
            <Select
              options={currentSourceLanguages}
              onChange={sourceLanguageChanged}
              value={_selectedSourceLanguage}
            />
          </div>
          <div className={styles.translateToContainer}>
            <Label defaultText="Translate to">
              Translation.Feature.GetQuotes.TranslateTo.label
            </Label>
            <Select
              options={currentTargetLanguages}
              isMulti
              onChange={targetLanguagesChanged}
              value={_selectedTargetLanguages}
            />
          </div>
          <div className={styles.wordCountContainer}>
            <Label defaultText="Total number of words in documents">
              Translation.Feature.GetQuotes.TotalNumberOfWords.label
            </Label>
            <Input
              className={styles.wordCountInput}
              onChange={wordCountChanged}
              integer
              maxValue={wordCountLimit}
              value={_wordCount || ''}
              enterAction={() => canGetQuotes && getQuotes()}
            />
          </div>
          <div className={styles.subjectContainer}>
            <Label defaultText="Subject field">Translation.Feature.GetQuotes.Subject.label</Label>
            <Select
              options={translationSubjects}
              onChange={translationSubjectChanged}
              value={_selectedTranslationSubject}
            />
          </div>
          <Button secondary onClick={getQuotes} disabled={!canGetQuotes} defaultText="Get quote">
            Translation.Feature.GetQuotes.GetQuote.Button.label
          </Button>
        </div>
      </Container>
    </div>
  );
}

GetQuotes.propTypes = {
  className: string,
  onGetQuotes: func.isRequired,
  sourceLanguages: languagesType.isRequired,
  targetLanguages: languagesType.isRequired,
  selectedSourceLanguage: languageType,
  selectedTargetLanguages: languagesType,
  wordCount: number,
  wordCountLimit: number.isRequired,
  translationSubjects: arrayOf(translationSubjectType).isRequired,
  selectedTranslationSubject: translationSubjectType,
};

GetQuotes.defaultProps = {
  className: null,
  selectedSourceLanguage: null,
  selectedTargetLanguages: [],
  wordCount: null,
  selectedTranslationSubject: null,
};

export default GetQuotes;
