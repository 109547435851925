import React from 'react';
import clsx from 'clsx';
import { string, bool } from 'types';
import styles from './Logo.module.scss';

function Logo(props) {
  const { className, useDefault, ...others } = props;
  const classNames = clsx(
    className,
    styles.root,
    useDefault ? styles.defaultLogo : styles.secondaryLogo
  );

  return <div className={classNames} {...others} />;
}

Logo.propTypes = {
  className: string,
  useDefault: bool
};

Logo.defaultProps = {
  className: null,
  useDefault: true
};

export default Logo;
