import React, { useRef, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useWindowSize, useWindowScroll } from 'react-use';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleUp, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope as farEnvelope } from '@fortawesome/free-regular-svg-icons';
import { connect } from 'react-redux';
import { H2, H5, S1, B1, BT, Button, Link } from 'components';
import { Navbar, Services, About, AvailableLanguages, FAQ } from 'features';
import { Logo, PoliciesPdf, IntegrityPdf } from 'assets';
import {
  historyType,
  orderActionsType,
  quoteConfigurationStateType,
  statsConfigurationStateType,
  faqType,
  sessionStateType,
  arrayOf,
} from 'types';
import {
  getQuoteConfiguration,
  getStatsConfiguration,
  getFaqConfiguration,
} from 'services/configuration';
import isMobile from 'utils/mobileCheck';
import orderActions from 'services/order/actions';
import styles from './HomePage.module.scss';

function HomePage(props) {
  const {
    history,
    actions,
    quoteConfigurationState,
    statsConfigurationState,
    faqConfigurationState,
    sessionState,
  } = props;
  const { sourceLanguages } = quoteConfigurationState;
  const { userLoggedIn } = sessionState;
  const { location } = history;
  const { hash } = location;
  const { height } = useWindowSize();
  const { y } = useWindowScroll();
  const servicesSectionRef = useRef();
  const aboutSectionRef = useRef();
  const availableLanguagesSectionRef = useRef();
  const faqSectionRef = useRef();
  const servicesRef = useRef();
  const aboutRef = useRef();
  const languagesRef = useRef();
  const faqRef = useRef();
  const [sectionHeights, setSectionHeights] = useState({
    servicesSectionHeight: height,
    aboutSectionHeight: height,
    languagesSectionHeight: height,
    faqSectionHeight: height,
    isSet: false,
  });

  useEffect(() => {
    window.addEventListener('popstate', listener);

    if (quoteConfigurationState.sourceLanguages.length === 0) {
      getQuoteConfiguration();
    }

    if (!statsConfigurationState.prices) {
      getStatsConfiguration();
    }

    if (faqConfigurationState.length === 0) {
      getFaqConfiguration();
    }

    return () => {
      window.removeEventListener('popstate', listener);
    };
  }, []);

  useEffect(() => {
    if (servicesRef.current && aboutRef.current && languagesRef.current && faqRef.current) {
      calculateSectionHeights();
    }
  }, [servicesRef, aboutRef, languagesRef, faqRef]);

  const listener = () => {
    if (window.location.hash === '#services' && servicesSectionRef.current) {
      servicesSectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'center',
      });
    } else if (window.location.hash === '#about' && aboutSectionRef.current) {
      aboutSectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'center',
      });
    } else if (window.location.hash === '#languages' && availableLanguagesSectionRef.current) {
      availableLanguagesSectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'center',
      });
    } else if (window.location.hash === '#faq' && faqSectionRef.current) {
      faqSectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'center',
      });
    }
  };

  useEffect(() => {
    if (sectionHeights.isSet) {
      setTimeout(
        () => {
          if (hash === '#services' && servicesSectionRef.current) {
            servicesSectionRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'center',
            });
          } else if (hash === '#about' && aboutSectionRef.current) {
            aboutSectionRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'center',
            });
          } else if (hash === '#languages' && availableLanguagesSectionRef.current) {
            availableLanguagesSectionRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'center',
            });
          } else if (hash === '#faq' && faqSectionRef.current) {
            faqSectionRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'center',
            });
          }
        },
        isMobile() ? 400 : 300
      );
    }
  }, [sectionHeights]);

  const calculateSectionHeights = () => {
    const _sectionHeights = {
      servicesSectionHeight: height,
      aboutSectionHeight: height,
      languagesSectionHeight: height,
      faqSectionHeight: height,
      isSet: true,
    };

    if (servicesRef.current.clientHeight > height) {
      _sectionHeights.servicesSectionHeight = servicesRef.current.clientHeight;
    }

    if (aboutRef.current.clientHeight > height) {
      _sectionHeights.aboutSectionHeight = aboutRef.current.clientHeight;
    }

    if (languagesRef.current.clientHeight > height) {
      _sectionHeights.languagesSectionHeight = languagesRef.current.clientHeight;
    }

    if (faqRef.current.clientHeight > height) {
      _sectionHeights.faqSectionHeight = faqRef.current.clientHeight;
    }

    setSectionHeights(_sectionHeights);
  };

  const navigateToGetQuotePage = () => {
    actions.clearCurrentOrder();
    history.push('/get-quotes');
  };

  const supportedLanguages = sourceLanguages.map((sl) => ({
    label: sl.label,
    defaultLabel: sl.value,
    value: sl.value,
  }));

  const scrollToTop = () => {
    if (y > 200) {
      const c = document.documentElement.scrollTop || document.body.scrollTop;
      if (c > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, c - c / 8);
      }
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.background} />
      <div className={styles.outerContentContainer}>
        <Navbar useDefault={false} />
        <div className={styles.contentContainer}>
          <H5
            className={styles.header}
            useDefault={false}
            upperCase
            bold
            defaultText="A modern translation agency"
          >
            Translation.Page.Home.Header.label
          </H5>
          <H2
            className={styles.subHeader}
            useDefault={false}
            upperCase
            bold
            defaultText="Instant quote"
          >
            Translation.Page.Home.SubHeader.label
          </H2>
          <S1
            className={styles.paragraph}
            useDefault={false}
            defaultText="In less than a minute you will have not only a fixed price but also a guaranteed
            delivery date."
          >
            Translation.Page.Home.Paragraph.label
          </S1>
          <div className={styles.buttonContainer}>
            <Button white onClick={navigateToGetQuotePage} defaultText="Get quote">
              Translation.Page.Home.GetQuote.Button.label
            </Button>
          </div>
        </div>
        <div className={styles.bottomMargin}>
          <a href="#services" className={styles.aboutLink}>
            <span className={styles.mouse}>
              <span className={styles.mouseDot} />
            </span>
          </a>
        </div>
      </div>
      <section ref={aboutSectionRef} className={styles.sectionRef} />
      <section
        className={styles.aboutSectionContainer}
        style={{ height: sectionHeights.aboutSectionHeight }}
      >
        <About ref={aboutRef} />
      </section>
      <section ref={servicesSectionRef} className={styles.sectionRef} />
      <section
        className={styles.servicesSectionContainer}
        style={{ height: sectionHeights.servicesSectionHeight }}
      >
        <Services ref={servicesRef} />
      </section>
      <section ref={availableLanguagesSectionRef} className={styles.sectionRef} />
      <section
        className={styles.availableLanguagesSectionContainer}
        style={{ height: sectionHeights.languagesSectionHeight }}
      >
        <AvailableLanguages languages={supportedLanguages} ref={languagesRef} />
      </section>
      <section ref={faqSectionRef} className={styles.sectionRef} />
      <section
        className={styles.faqSectionContainer}
        style={{ height: sectionHeights.faqSectionHeight }}
      >
        <FAQ faqs={faqConfigurationState} ref={faqRef} />
      </section>
      <div
        role="presentation"
        onClick={scrollToTop}
        className={clsx(styles.scrollToTopButton, y > 200 ? styles.scrollToTopButtonVisible : null)}
      >
        <FontAwesomeIcon icon={faAngleDoubleUp} className={styles.scrollToTopIcon} />
      </div>
      <div className={styles.footerContainer}>
        <div className={styles.footerContactContainer}>
          <div className={styles.footerColumn}>
            <Logo className={styles.footerLogo} useDefault onClick={scrollToTop} />
          </div>
          <div className={styles.footerColumn}>
            <ul className={styles.menuList}>
              <li>
                <BT
                  className={clsx(styles.menuItemText, styles.noTopMargin)}
                  onClick={() =>
                    servicesSectionRef.current.scrollIntoView({
                      behavior: 'smooth',
                      block: 'start',
                      inline: 'center',
                    })
                  }
                  useDefault
                  bold
                  upperCase
                  defaultText="Our services"
                >
                  Translation.Feature.Navbar.MenuItem.OurServices.label
                </BT>
                <BT
                  className={styles.menuItemText}
                  onClick={() =>
                    aboutSectionRef.current.scrollIntoView({
                      behavior: 'smooth',
                      block: 'start',
                      inline: 'center',
                    })
                  }
                  useDefault
                  bold
                  upperCase
                  defaultText="About us"
                >
                  Translation.Feature.Navbar.MenuItem.AboutUs.label
                </BT>
                <BT
                  className={styles.menuItemText}
                  onClick={() =>
                    availableLanguagesSectionRef.current.scrollIntoView({
                      behavior: 'smooth',
                      block: 'start',
                      inline: 'center',
                    })
                  }
                  useDefault
                  bold
                  upperCase
                  defaultText="Languages"
                >
                  Translation.Feature.Navbar.MenuItem.Languages.label
                </BT>
                <BT
                  className={styles.menuItemText}
                  onClick={() =>
                    faqSectionRef.current.scrollIntoView({
                      behavior: 'smooth',
                      block: 'start',
                      inline: 'center',
                    })
                  }
                  useDefault
                  bold
                  upperCase
                  defaultText="Faq"
                >
                  Translation.Feature.Navbar.MenuItem.FAQ.label
                </BT>
              </li>
            </ul>
          </div>
          <div className={styles.footerColumn}>
            <ul className={styles.menuList}>
              <li>
                <BT
                  className={clsx(styles.menuItemText, styles.noTopMargin)}
                  onClick={() => {
                    history.push('/login');
                  }}
                  useDefault
                  bold
                  upperCase
                  defaultText="Login & Signup"
                >
                  Translation.Feature.Navbar.MenuItem.LoginAndSignup.label
                </BT>
                <BT
                  className={styles.menuItemText}
                  onClick={() => {
                    history.push('/get-quotes');
                  }}
                  useDefault
                  bold
                  upperCase
                  defaultText="Get quote"
                >
                  Translation.Feature.Navbar.MenuItem.GetQuote.label
                </BT>
                {userLoggedIn && (
                  <BT
                    className={styles.menuItemText}
                    onClick={() => {
                      history.push('/order-dashboard');
                    }}
                    useDefault
                    bold
                    upperCase
                    defaultText="Orders"
                  >
                    Translation.Feature.Navbar.MenuItem.Orders.label
                  </BT>
                )}
                <BT
                  className={styles.menuItemText}
                  onClick={() => {
                    window.open(PoliciesPdf, '_blank');
                  }}
                  useDefault
                  bold
                  upperCase
                  defaultText="Terms and conditions"
                >
                  Translation.Feature.Navbar.MenuItem.TermsAndConditions.label
                </BT>
                <BT
                  className={styles.menuItemText}
                  onClick={() => {
                    window.open(IntegrityPdf, '_blank');
                  }}
                  useDefault
                  bold
                  upperCase
                  defaultText="Integrity policy"
                >
                  Translation.Feature.Navbar.MenuItem.IntegrityPolicy.label
                </BT>
              </li>
            </ul>
          </div>
          <div className={styles.footerColumn}>
            <ul className={styles.menuList}>
              <li>
                <B1
                  useDefault
                  translate={false}
                  className={clsx(styles.addressLabel, styles.noTopMargin)}
                >
                  Reword Sweden AB
                </B1>
              </li>
              <li>
                <B1 useDefault translate={false} className={styles.addressLabel}>
                  Ängelholmsvägen 28
                </B1>
              </li>
              <li>
                <B1 useDefault translate={false} className={styles.addressLabel}>
                  269 31 Båstad
                </B1>
              </li>
            </ul>
            <div className={styles.contactContainer}>
              <a href="mailto:hello@reword.se" className={styles.mailLink}>
                <FontAwesomeIcon icon={farEnvelope} className={styles.contactIcon} />
                hello@reword.se
              </a>
              <a href="tel:+46 709 10 07 07" className={styles.phoneLink}>
                <FontAwesomeIcon icon={faPhoneAlt} className={styles.contactIcon} />
                +46 709 10 07 07
              </a>
            </div>
          </div>
        </div>
        <hr className={styles.divider} />
        <div className={styles.footerLinkContainer}>
          <B1 defaultText="A service by" className={styles.serviceByLabel}>
            Translation.Page.Home.ServiceBy.label
          </B1>
          &nbsp;
          <B1 translate={false} className={styles.companyName} bold onClick={scrollToTop}>
            Reword Sweden AB
          </B1>
          .
        </div>
      </div>
    </div>
  );
}

const mapDispatch = (dispatch) => ({
  actions: bindActionCreators({ ...orderActions }, dispatch),
});

const mapStateToProps = (state) => ({
  adminOrderState: state.admin.adminOrder,
  quoteConfigurationState: state.configuration.quote,
  orderConfigurationState: state.configuration.order,
  statsConfigurationState: state.configuration.stats,
  faqConfigurationState: state.configuration.faq,
  sessionState: state.session,
});

HomePage.propTypes = {
  history: historyType.isRequired,
  actions: orderActionsType.isRequired,
  quoteConfigurationState: quoteConfigurationStateType.isRequired,
  statsConfigurationState: statsConfigurationStateType.isRequired,
  faqConfigurationState: arrayOf(faqType).isRequired,
  sessionState: sessionStateType.isRequired,
};

export default connect(mapStateToProps, mapDispatch)(HomePage);
