import React from 'react';
import { GetQuotes } from 'features';
import styles from './GetQuotes.module.scss';

function GetQuotesPage() {
  return (
    <div className={styles.root}>
      <GetQuotes />
    </div>
  );
}

export default GetQuotesPage;
