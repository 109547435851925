import React, { useEffect } from 'react';
import { applicationConfigurationStateType } from 'types';
import Routes from 'routes';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import '@formatjs/intl-relativetimeformat/polyfill';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import { getApplicationConfiguration } from 'services/configuration';
import styles from './Main.module.scss';

const supportsHistory = 'pushState' in window.history;

function Main(props) {
  const { applicationConfiguration } = props;
  const { busy, selectedLanguage, currentTranslations } = applicationConfiguration;

  useEffect(() => {
    getApplicationConfiguration();
  }, []);

  if (busy) {
    return null;
  }

  return (
    <IntlProvider locale={selectedLanguage} messages={currentTranslations}>
      <div className={styles.contentRoot}>
        <ToastContainer />
        <BrowserRouter forceRefresh={!supportsHistory}>
          <Routes />
        </BrowserRouter>
      </div>
    </IntlProvider>
  );
}

const mapStateToProps = state => {
  return {
    applicationConfiguration: state.configuration.application
  };
};

Main.propTypes = {
  applicationConfiguration: applicationConfigurationStateType.isRequired
};

export default connect(mapStateToProps)(Main);
