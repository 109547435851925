import React from 'react';
import { string, bool, arrayOf, menuItemType } from 'types';
import clsx from 'clsx';
import { BT } from 'components';
import styles from './Menu.module.scss';

function Menu(props) {
  const { className, items, useDefault } = props;
  const classNames = clsx(className, styles.root);

  const renderMenuItems = () => {
    return items.map(item => {
      return (
        <li className={styles.menuItem} key={item.key}>
          {item.anchor ? (
            <a href={item.anchor} className={styles.menuItemAnchor}>
              <BT
                className={styles.menuItemText}
                useDefault={useDefault}
                bold
                upperCase
                defaultText={item.defaultText}
              >
                {item.name}
              </BT>
            </a>
          ) : (
            <BT
              className={styles.menuItemText}
              onClick={item.action}
              useDefault={useDefault}
              bold
              upperCase
              defaultText={item.defaultText}
            >
              {item.name}
            </BT>
          )}
        </li>
      );
    });
  };

  return (
    <div className={classNames}>
      <ul className={styles.menuList}>{renderMenuItems()}</ul>
    </div>
  );
}

Menu.propTypes = {
  className: string,
  items: arrayOf(menuItemType),
  useDefault: bool
};

Menu.defaultProps = {
  className: null,
  items: [],
  useDefault: true
};

export default Menu;
