import React from 'react';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { Loader, NUMBER_FORMAT } from 'assets';
import { H4, B1, B2, C, Button, Container } from 'components';
import { quoteType, string, bool, func } from 'types';
import styles from './Quote.module.scss';

function Quote(props) {
  const { className, quote, selectQuote, onSelectQuote, showLogin, onLogin } = props;
  const { currency } = quote;
  const intl = useIntl();
  const classNames = clsx(className, styles.root);

  const getQuoteTypeInformation = () => {
    if (quote.translationType.toLowerCase() === 'standard') {
      return {
        defaultHeader: 'Standard',
        header: 'Translation.Feature.Quote.Standard.Header.label',
        descriptions: [
          {
            translationKey: 'Translation.Feature.Quote.Standard.Description1.label',
            defaultText: 'TRANSLATION BY A PROFESSIONAL MOTHER-TONGUE TRANSLATOR',
          },
          {
            translationKey: 'Translation.Feature.Quote.Standard.Description2.label',
            defaultText: 'FINAL QUALITY CONTROL BY A PROJECT MANAGER',
          },
        ],
      };
    }
    if (quote.translationType.toLowerCase() === 'professional') {
      return {
        defaultHeader: 'Professional',
        header: 'Translation.Feature.Quote.Professional.Header.label',
        descriptions: [
          {
            translationKey: 'Translation.Feature.Quote.Professional.Description1.label',
            defaultText: 'TRANSLATION BY A PROFESSIONAL MOTHER-TONGUE TRANSLATOR',
          },
          {
            translationKey: 'Translation.Feature.Quote.Professional.Description2.label',
            defaultText: 'QUALITY EVALUATION BY A SECOND PROFESSIONAL MOTHER-TONGUE TRANSLATOR',
          },
          {
            translationKey: 'Translation.Feature.Quote.Professional.Description3.label',
            defaultText: 'FINAL QUALITY CONTROL BY PROJECT MANAGER',
          },
          {
            translationKey: 'Translation.Feature.Quote.Professional.Description4.label',
            defaultText: 'TRANSLATION MEMORY',
          },
        ],
        backgroundColor: styles.grayBrightColor,
        borderColor: styles.grayColor,
      };
    }
    if (quote.translationType.toLowerCase() === 'pro express') {
      return {
        defaultHeader: 'Pro express',
        header: 'Translation.Feature.Quote.ProExpress.Header.label',
        descriptions: [
          {
            translationKey: 'Translation.Feature.Quote.ProExpress.Description1.label',
            defaultText: 'TRANSLATION BY A PROFESSIONAL MOTHER-TONGUE TRANSLATOR',
          },
          {
            translationKey: 'Translation.Feature.Quote.ProExpress.Description2.label',
            defaultText: 'FULL REVISION BY A SECOND PROFESSIONAL MOTHER-TONGUE TRANSLATOR',
          },
          {
            translationKey: 'Translation.Feature.Quote.ProExpress.Description3.label',
            defaultText: 'FINAL QUALITY CONTROL BY A PROJECT MANAGER',
          },
          {
            translationKey: 'Translation.Feature.Quote.ProExpress.Description4.label',
            defaultText: 'TRANSLATION MEMORY',
          },
          {
            translationKey: 'Translation.Feature.Quote.ProExpress.Description5.label',
            defaultText: 'EXPRESS DELIVERY',
          },
        ],
      };
    }

    return { header: '', descriptions: [] };
  };

  const quoteTypeInformation = getQuoteTypeInformation();

  const renderQuoteDetails = () => {
    if ((quote.id === undefined || quote.id === null) && !quote.error) {
      return [<Loader key="2" />];
    }

    if (quote.error) {
      return [
        <B1
          bolder
          className={styles.errorLabel}
          defaultText={quote.error.defaultTranslation}
          key="7"
        >
          {quote.error.translationKey}
        </B1>,
      ];
    }

    const deliveryDate = new Date(quote.delivery_date);
    const now = new Date();
    const daysUntilDelivery = Math.ceil(
      (deliveryDate.getTime() - now.getTime()) / (1000 * 3600 * 24)
    );

    return [
      <div key="3">
        <B1 bolder upperCase className={styles.currencyLabel} translate={false}>
          {currency}
        </B1>
        <B1 bolder className={styles.priceLabel} translate={false}>
          {intl.formatNumber(quote.total, NUMBER_FORMAT)}
        </B1>
        <B2 className={styles.wordsLabel} inline defaultText="for">
          Translation.Feature.Quote.SelectQuote.WordCount.For.label
        </B2>
        &nbsp;
        <B2 className={styles.wordsLabel} translate={false} inline>
          {intl.formatNumber(quote.words, NUMBER_FORMAT)}
        </B2>
        &nbsp;
        <B2 className={styles.wordsLabel} inline defaultText="words">
          Translation.Feature.Quote.SelectQuote.WordCount.Words.label
        </B2>
      </div>,
      quote.delivery_date
        ? [
            <div key="4" className={styles.deliveryContainer}>
              <B2 className={styles.deliveryLabel} defaultText="DELIVERY">
                Translation.Feature.Quote.SelectQuote.DeliveryDate.label
              </B2>
              <div className={styles.daysUntilDeliveryContainer}>
                <B1
                  bolder
                  upperCase
                  className={clsx(
                    styles.deliveryDaysLabel,
                    styles.numberOfDeliveryDaysLabel,
                    quote.translationType.toLowerCase() === 'pro express' && styles.redColor
                  )}
                  translate={false}
                >
                  {daysUntilDelivery}
                </B1>
                <B1
                  bolder
                  upperCase
                  className={clsx(
                    styles.deliveryDaysLabel,
                    quote.translationType.toLowerCase() === 'pro express' && styles.redColor
                  )}
                  defaultText={daysUntilDelivery === 1 ? 'Day' : 'Days'}
                >
                  {daysUntilDelivery === 1
                    ? 'Translation.Feature.Quote.SelectQuote.DeliveryDateDay.label'
                    : 'Translation.Feature.Quote.SelectQuote.DeliveryDateDays.label'}
                </B1>
              </div>
              <div className={styles.dateDeliveryContainer}>
                <B2 upperCase className={styles.dateLabel} translate={false}>
                  {intl.formatDate(quote.delivery_date, { weekday: 'short' })}
                </B2>
                <B2 upperCase className={styles.dateLabel} translate={false}>
                  {intl.formatDate(quote.delivery_date, { month: 'short' })}
                </B2>
                <B2 upperCase className={styles.dateLabel} translate={false}>
                  {intl.formatDate(quote.delivery_date, { day: '2-digit' })}
                </B2>
                <B2 upperCase className={styles.dateLabel} translate={false}>
                  {intl.formatTime(quote.delivery_date, { hour: 'numeric', minute: 'numeric' })}
                </B2>
                <B2 className={styles.cetLabel} translate={false}>
                  CET
                </B2>
              </div>
            </div>,
            selectQuote ? (
              <Button
                key="5"
                onClick={() => onSelectQuote(quote)}
                preRightArrow
                defaultText="Select quote"
              >
                Translation.Feature.Quote.SelectQuote.Button.label
              </Button>
            ) : null,
            showLogin ? (
              <>
                <B1 defaultText="Price and delivery time are not reserved until you log in.">
                  Translation.Feature.Quote.SelectQuote.Login.label
                </B1>
                <Button
                  key="6"
                  className={styles.loginButton}
                  preRightArrow
                  onClick={onLogin}
                  defaultText="Login"
                >
                  Translation.Feature.Quote.SelectQuote.Login.Button.label
                </Button>
              </>
            ) : null,
          ]
        : null,
    ];
  };

  const renderContent = () => {
    return [
      <H4
        key="1"
        bold
        upperCase
        className={clsx(
          styles.header,
          quoteTypeInformation.defaultHeader === 'Pro express' && styles.redColor
        )}
        defaultText={quoteTypeInformation.defaultHeader}
      >
        {quoteTypeInformation.header}
      </H4>,
      renderQuoteDetails()
        .flat()
        .filter((child) => child !== null),
      <div key="6">
        {quoteTypeInformation.descriptions.map((d) => {
          return (
            <C
              key={d.translationKey}
              defaultText={d.defaultText}
              className={styles.quoteDescriptionLabel}
            >
              {d.translationKey}
            </C>
          );
        })}
      </div>,
    ];
  };
  return (
    <Container
      key={quote.translationType}
      className={classNames}
      backgroundColor={quoteTypeInformation.backgroundColor}
      borderColor={quoteTypeInformation.borderColor}
      separators
    >
      {renderContent()
        .flat()
        .filter((child) => child !== null)}
    </Container>
  );
}

Quote.propTypes = {
  className: string,
  quote: quoteType.isRequired,
  selectQuote: bool,
  onSelectQuote: func,
  showLogin: bool,
  onLogin: func,
};

Quote.defaultProps = {
  className: null,
  selectQuote: false,
  onSelectQuote: null,
  showLogin: false,
  onLogin: null,
};

export default Quote;
