import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { orderStateType, sessionStateType, quoteConfigurationStateType } from 'types';
import { getQuotes } from 'services/order';
import { getQuoteConfiguration } from 'services/configuration';
import GetQuotes from '.';

function GetQuotesContainer(props) {
  const {
    orderState: {
      currentOrder: {
        getQuotesCriteria: { sourceLanguage, targetLanguages, wordCount, translationSubject }
      }
    },
    sessionState: { userLoggedIn },
    quoteConfiguration
  } = props;

  const [navigationUrl, setNavigationUrl] = useState();

  useEffect(() => {
    if (quoteConfiguration.sourceLanguages.length === 0 && !quoteConfiguration.busy) {
      getQuoteConfiguration();
    }
  }, [quoteConfiguration]);

  const onGetQuotes = request => {
    if (userLoggedIn) {
      setNavigationUrl('/select-quote');
    } else {
      setNavigationUrl('/view-quotes');
    }

    getQuotes(request);
  };

  if (navigationUrl) {
    return <Redirect to={navigationUrl} />;
  }

  return quoteConfiguration.sourceLanguages.length > 0 ? (
    <GetQuotes
      onGetQuotes={onGetQuotes}
      sourceLanguages={quoteConfiguration.sourceLanguages}
      targetLanguages={quoteConfiguration.targetLanguages}
      selectedSourceLanguage={sourceLanguage}
      selectedTargetLanguages={targetLanguages}
      wordCount={wordCount}
      wordCountLimit={quoteConfiguration.validation.wordCountLimit}
      selectedTranslationSubject={translationSubject}
      translationSubjects={quoteConfiguration.translationSubjects}
    />
  ) : null;
}

const mapStateToProps = state => {
  return {
    orderState: state.order,
    sessionState: state.session,
    quoteConfiguration: state.configuration.quote
  };
};

GetQuotesContainer.propTypes = {
  orderState: orderStateType.isRequired, // eslint-disable-line react/no-unused-prop-types
  sessionState: sessionStateType.isRequired, // eslint-disable-line react/no-unused-prop-types
  quoteConfiguration: quoteConfigurationStateType.isRequired
};

export default connect(mapStateToProps)(GetQuotesContainer);
