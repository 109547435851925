import React, { useState } from 'react';
import { string, bool, func, number } from 'types';
import clsx from 'clsx';
import { H4, B1, Label, Input, Button, Container, Modal } from 'components';
import isEmailValid from 'utils/emailValidator';
import { RegisterConfirmation, RegisterFailed } from './components';
import styles from './Register.module.scss';

function Register(props) {
  const { className, onSignUp, showConfirmation, onConfirm, onLogin, busy } = props;
  const [email, setEmail] = useState(null);
  const [emailValid, setEmailValid] = useState(null);
  const classNames = clsx(className, styles.root);

  const emailChanged = value => {
    setEmail(value);
  };

  const _onSignUp = () => {
    if (validateEmail()) {
      onSignUp(email);
    }
  };

  const validateEmail = () => {
    const validEmail = isEmailValid(email);
    setEmailValid(validEmail);
    return validEmail;
  };

  let modalContent = null;
  let modalDefaultTitle = null;
  let modalTitle = null;

  if (showConfirmation === 1) {
    modalContent = <RegisterConfirmation onConfirm={onConfirm} />;
    modalDefaultTitle = 'Registration link successfully sent';
    modalTitle = 'Translation.Feature.Register.Confirmation.Modal.title';
  }

  if (showConfirmation === 2) {
    modalContent = <RegisterFailed onLogin={onLogin} />;
    modalDefaultTitle = 'Failed to create account';
    modalTitle = 'Translation.Feature.Register.Failed.Modal.title';
  }

  return [
    <Container key="1" className={classNames}>
      <div>
        <H4 className={styles.header} bold upperCase defaultText="Create an account">
          Translation.Feature.Register.header
        </H4>
        <B1
          className={styles.bodyText}
          defaultText="Please enter your email address to create an account."
        >
          Translation.Feature.Register.subHeader
        </B1>
        <form autoComplete="on">
          <Label defaultText="Email address">Translation.Feature.Register.Email.label</Label>
          <Input
            className={styles.emailInput}
            placeHolder="Translation.Feature.Register.Email.Input.placeholder"
            defaultPlaceHolder="Enter your email address"
            onChange={emailChanged}
            isValid={emailValid}
            validationMessage="Translation.Feature.Register.Email.Input.validationMessage"
            defaultValidationMessage="Please provide a valid email"
            enterAction={_onSignUp}
            name="email"
            id="email"
            type="email"
            maxValue={50}
          />
          <Button onClick={_onSignUp} defaultText="Sign up" disabled={!isEmailValid(email)}>
            Translation.Feature.Register.SignUp.Button.label
          </Button>
        </form>
      </div>
    </Container>,
    <Modal
      key="2"
      isOpen={showConfirmation !== 0}
      defaultTitle={modalDefaultTitle}
      title={modalTitle}
      hideOnBackDropClick={false}
      busy={busy}
    >
      {modalContent}
    </Modal>
  ];
}

Register.propTypes = {
  className: string,
  onSignUp: func.isRequired,
  showConfirmation: number.isRequired,
  onConfirm: func.isRequired,
  onLogin: func.isRequired,
  busy: bool
};

Register.defaultProps = {
  className: null,
  busy: false
};

export default Register;
