import React from 'react';
import { AddDocuments } from 'features';
import styles from './AddDocuments.module.scss';

function AddDocumentsPage() {
  return (
    <div className={styles.root}>
      <AddDocuments />
    </div>
  );
}

export default AddDocumentsPage;
