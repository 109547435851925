import api from '../api';
import store from '../../store';
import sessionActions from './actions';

const authenticateEndpoint = 'authenticate';

function login(email, password) {
  store.dispatch(sessionActions.loginRequested());

  return api
    .post(`${authenticateEndpoint}`, null, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      params: {
        username: email,
        password
      }
    })
    .then(response => {
      store.dispatch(sessionActions.loginSucceeded());
    })
    .catch(error => {
      store.dispatch(sessionActions.loginFailed(error));
      throw error;
    });
}

function logout() {
  store.dispatch(sessionActions.logoutRequested());

  return api
    .get('logout')
    .then(response => {
      store.dispatch(sessionActions.logoutSucceeded());
    })
    .catch(error => {
      store.dispatch(sessionActions.logoutFailed(error));
      throw error;
    });
}

export { login, logout };
