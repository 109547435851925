import { createAction } from '@reduxjs/toolkit';

export default {
  loginRequested: createAction('session/loginRequested'),
  loginSucceeded: createAction('session/loginSucceeded'),
  loginFailed: createAction('session/loginFailed'),
  logoutRequested: createAction('session/logoutRequested'),
  logoutSucceeded: createAction('session/logoutSucceeded'),
  logoutFailed: createAction('session/logoutFailed')
};
