import { createAction } from '@reduxjs/toolkit';

export default {
  getOrdersRequest: createAction('admin/order/getOrdersRequest'),
  getOrdersSuccess: createAction('admin/order/getOrdersSuccess'),
  getOrdersFailed: createAction('admin/order/getOrdersFailed'),
  getSettingsRequest: createAction('admin/setting/getSettingsRequest'),
  getSettingsSuccess: createAction('admin/setting/getSettingsSuccess'),
  getSettingsFailed: createAction('admin/setting/getSettingsFailed'),
  getAccountsRequest: createAction('admin/account/getAccountsRequest'),
  getAccountsSuccess: createAction('admin/account/getAccountsSuccess'),
  getAccountsFailed: createAction('admin/account/getAccountsFailed'),
  updateSettingsRequest: createAction('admin/setting/updateSettingsRequest'),
  updateSettingsSuccess: createAction('admin/setting/updateSettingsSuccess'),
  updateSettingsFailed: createAction('admin/setting/updateSettingsFailed'),
  approveOrderRequest: createAction('admin/order/approveRequest'),
  approveOrderSuccess: createAction('admin/order/approveSuccess'),
  approveOrderFailed: createAction('admin/order/approveFailed'),
  rejectOrderRequest: createAction('admin/order/rejectRequest'),
  rejectOrderSuccess: createAction('admin/order/rejectSuccess'),
  rejectOrderFailed: createAction('admin/order/rejectFailed'),
  setOrderInvoicedRequest: createAction('admin/order/setInvoicedRequest'),
  setOrderInvoicedSuccess: createAction('admin/order/setInvoicedSuccess'),
  setOrderInvoicedFailed: createAction('admin/order/setInvoicedFailed'),
  setOrderPaidRequest: createAction('admin/order/setPaidRequest'),
  setOrderPaidSuccess: createAction('admin/order/setPaidSuccess'),
  setOrderPaidFailed: createAction('admin/order/setPaidFailed'),
};
