import React, { useState, useEffect, useRef } from 'react';
import { string, bool, func, oneOfType, number } from 'types';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import styles from './Input.module.scss';

function Input({ className, ...props }) {
  const {
    bold,
    bolder,
    disabled,
    upperCase,
    onChange,
    integer,
    value,
    placeHolder,
    defaultPlaceHolder,
    translatePlaceHolder,
    password,
    isValid,
    validationMessage,
    defaultValidationMessage,
    translateValidationMessage,
    validationClassName,
    onValidate,
    enterAction,
    autoFocus,
    type,
    maxValue,
    ...other
  } = props;

  const [privateValue, setPrivateValue] = useState(value || '');
  const [_isValid, setIsValid] = useState(isValid);
  const inputRef = useRef();
  const intl = useIntl();

  const classNames = clsx(
    className,
    styles.root,
    bold && styles.bold,
    bolder && styles.bolder,
    disabled && styles.disabled,
    upperCase && styles.upperCase,
    _isValid === false ? styles.warning : null
  );

  let _placeHolder = placeHolder;

  useEffect(() => {
    setIsValid(isValid);

    if (autoFocus && inputRef.current) {
      const input = inputRef.current;
      setTimeout(() => input.focus(), 400);
    }
  }, [isValid, autoFocus]);

  const onValueChange = (evt) => {
    if (integer) {
      const isInteger =
        !isNaN(evt.target.value) &&
        evt.target.value.indexOf('.') === -1 &&
        evt.target.value.indexOf(',') === -1;
      if (isInteger) {
        setPrivateValue(maxValue && maxValue < evt.target.value ? maxValue : evt.target.value);
        if (onChange) {
          onChange(maxValue && maxValue < evt.target.value ? maxValue : evt.target.value);
        }
      }
    } else if (maxValue === null || evt.target.value.length <= maxValue) {
      setPrivateValue(evt.target.value);
      if (onChange) {
        onChange(evt.target.value);
      }
    }

    if (onValidate) {
      setIsValid(onValidate());
    }
  };

  if (_placeHolder && translatePlaceHolder) {
    _placeHolder = intl.formatMessage({ id: _placeHolder, defaultMessage: defaultPlaceHolder });
  }

  const handleKeyPress = (evt) => {
    if (enterAction && evt.key.toLowerCase() === 'enter') {
      enterAction(evt);
      evt.preventDefault();
    }
  };

  const renderContent = () => {
    if (validationMessage) {
      return (
        <div>
          <input
            className={classNames}
            onChange={onValueChange}
            value={privateValue}
            placeholder={_placeHolder}
            type={type || (password ? 'password' : null)}
            onKeyPress={handleKeyPress}
            ref={inputRef}
            {...other}
          />
          {_isValid === false ? (
            <p className={clsx(styles.validationMessage, validationClassName)}>
              {translateValidationMessage
                ? intl.formatMessage({
                    id: validationMessage,
                    defaultMessage: defaultValidationMessage,
                  })
                : validationMessage}
            </p>
          ) : null}
        </div>
      );
    }
    return (
      <input
        className={classNames}
        onChange={onValueChange}
        value={privateValue}
        placeholder={_placeHolder}
        type={type || (password ? 'password' : null)}
        onKeyPress={handleKeyPress}
        ref={inputRef}
        {...other}
      />
    );
  };

  return renderContent();
}

Input.propTypes = {
  className: string,
  disabled: bool,
  bold: bool,
  bolder: bool,
  upperCase: bool,
  onChange: func,
  integer: bool,
  value: oneOfType([string, number]),
  placeHolder: string,
  defaultPlaceHolder: string,
  translatePlaceHolder: bool,
  password: bool,
  isValid: bool,
  validationMessage: string,
  defaultValidationMessage: string,
  translateValidationMessage: bool,
  validationClassName: string,
  onValidate: func,
  enterAction: func,
  autoFocus: bool,
  type: string,
  maxValue: number,
};

Input.defaultProps = {
  className: null,
  disabled: false,
  bold: false,
  bolder: false,
  upperCase: false,
  onChange: null,
  integer: false,
  value: '',
  placeHolder: null,
  defaultPlaceHolder: '',
  translatePlaceHolder: true,
  password: false,
  isValid: null,
  validationMessage: null,
  defaultValidationMessage: '',
  translateValidationMessage: true,
  validationClassName: null,
  onValidate: null,
  enterAction: null,
  autoFocus: false,
  type: null,
  maxValue: null,
};

export default Input;
