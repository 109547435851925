import React from 'react';
import { string, func } from 'types';
import clsx from 'clsx';
import { B1, Button } from 'components';
import styles from './ResetPasswordConfirmation.module.scss';

function ResetPasswordConfirmation(props) {
  const { className, onConfirm } = props;
  const classNames = clsx(className, styles.root);

  return (
    <div className={classNames}>
      <B1 defaultText="Your password has been successfully reset. Please proceed to the login page.">
        Translation.Feature.ResetPassword.ResetPasswordConfirmation.Confirmation.label
      </B1>
      <Button className={styles.confirmButton} onClick={onConfirm} defaultText="Login">
        Translation.Feature.ResetPassword.ResetPasswordConfirmation.Confirmation.Button.label
      </Button>
    </div>
  );
}

ResetPasswordConfirmation.propTypes = {
  className: string,
  onConfirm: func.isRequired
};

ResetPasswordConfirmation.defaultProps = {
  className: null
};

export default ResetPasswordConfirmation;
