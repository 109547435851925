import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import { node, string, bool, func } from 'types';
import clsx from 'clsx';
import styles from './Button.module.scss';

function translateChildren(children, defaultText, fn) {
  return React.Children.map(children, child => {
    let currentChild = child;

    if (typeof child === 'string') {
      return <FormattedMessage id={currentChild} defaultMessage={defaultText} />;
    }

    if (!React.isValidElement(currentChild)) {
      return currentChild;
    }

    if (currentChild.props.children) {
      currentChild = React.cloneElement(currentChild, {
        children: translateChildren(currentChild.props.children, defaultText, fn)
      });
    }

    return currentChild;
  });
}

function Button({ className, children, ...props }) {
  const {
    translate,
    defaultText,
    secondary,
    transparent,
    white,
    bold,
    bolder,
    disabled,
    upperCase,
    onClick,
    preRightArrow,
    preLeftArrow,
    preUpArrow,
    preDownArrow,
    small,
    ...other
  } = props;
  const classNames = clsx(
    className,
    styles.root,
    styles.rootPrimary,
    bold && styles.bold,
    bolder && styles.bolder,
    disabled && styles.disabled,
    upperCase && styles.upperCase,
    secondary && styles.secondary,
    transparent && styles.transparent,
    white && styles.white,
    small && styles.small
  );

  const _onClick = () => {
    if (onClick && !disabled) {
      onClick();
    }
  };

  return (
    <button type="button" className={classNames} onClick={_onClick} {...other}>
      {preRightArrow ? <FontAwesomeIcon icon={faChevronRight} className={styles.preIcon} /> : null}
      {preLeftArrow ? <FontAwesomeIcon icon={faChevronLeft} className={styles.preIcon} /> : null}
      {preUpArrow ? <FontAwesomeIcon icon={faChevronUp} className={styles.preIcon} /> : null}
      {preDownArrow ? <FontAwesomeIcon icon={faChevronDown} className={styles.preIcon} /> : null}
      {translate ? translateChildren(children, defaultText) : children}
    </button>
  );
}

Button.propTypes = {
  className: string,
  children: node,
  translate: bool,
  secondary: bool,
  transparent: bool,
  white: bool,
  disabled: bool,
  bold: bool,
  bolder: bool,
  upperCase: bool,
  onClick: func,
  preRightArrow: bool,
  preLeftArrow: bool,
  preUpArrow: bool,
  preDownArrow: bool,
  defaultText: string,
  small: bool
};

Button.defaultProps = {
  className: null,
  children: null,
  translate: true,
  secondary: false,
  transparent: false,
  white: false,
  disabled: false,
  bold: false,
  bolder: true,
  upperCase: true,
  onClick: null,
  preRightArrow: false,
  preLeftArrow: false,
  preUpArrow: false,
  preDownArrow: false,
  defaultText: '',
  small: false
};

export default Button;
