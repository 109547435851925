import React from 'react';
import { ensuredForwardRef } from 'react-use';
import { useIntl, FormattedHTMLMessage } from 'react-intl';
import Collapsible from 'react-collapsible';
import clsx from 'clsx';
import { H3, C } from 'components';
import { string, faqType, arrayOf } from 'types';
import styles from './FAQ.module.scss';

const FAQ = ensuredForwardRef((props, ref) => {
  const { className, faqs } = props;
  const intl = useIntl();
  const classNames = clsx(className, styles.root);

  const createText = (textObject) => {
    let translatedText = intl.formatMessage({
      id: textObject.translationKey,
      defaultMessage: textObject.defaultTranslation,
    });

    translatedText = translatedText
      .replace(/<NL>/g, '\n')
      .replace(/<nl>/g, '\n')
      .replace(/<Nl>/g, '\n');

    let linkIndex = translatedText.indexOf('<LINK>');

    while (linkIndex !== -1) {
      const lastLinkIndex = translatedText.indexOf('</LINK>');
      const link = translatedText.substring(linkIndex + 6, lastLinkIndex);
      translatedText = translatedText.replace(
        `<LINK>${link}</LINK>`,
        `<a href="${link}" class="${styles.link}" target="_blank">${link}</a>`
      );

      linkIndex = translatedText.indexOf('<LINK>');
    }

    return translatedText;
  };

  return (
    <div className={classNames} ref={ref}>
      <H3 className={styles.header} bold upperCase defaultText="Frequently asked questions">
        Translation.Feature.FAQ.header
      </H3>
      <div className={styles.faqContainer}>
        {faqs &&
          faqs.map((faq) => {
            const answerText = createText(faq.answer);

            return (
              <Collapsible
                key={faq.question.translationKey}
                trigger={intl.formatMessage({
                  id: faq.question.translationKey,
                  defaultMessage: faq.question.defaultTranslation,
                })}
                transitionTime={300}
                className={styles.collapsible}
                triggerClassName={styles.collapsibleTrigger}
                openedClassName={styles.collapsible}
                triggerOpenedClassName={styles.collapsibleTriggerOpen}
                contentOuterClassName={styles.contentOuter}
              >
                <C className={styles.answerText} translate={false}>
                  <FormattedHTMLMessage id={answerText} />
                </C>
              </Collapsible>
            );
          })}
      </div>
    </div>
  );
});

FAQ.propTypes = {
  className: string,
  faqs: arrayOf(faqType).isRequired,
};

FAQ.defaultProps = {
  className: null,
};

export default FAQ;
