import axios from 'axios';
import store from 'store';
import sessionActions from 'services/session/actions';
// import { toastError } from 'utils/Toaster';

function getBaseUrl() {
  //return 'https://reword-test.azurewebsites.net/api';
  return process.env.NODE_ENV === 'production' ? window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + '/api' : 'https://localhost:8443/api';
}

const api = axios.create({
  baseURL: getBaseUrl()
  // ,
  // headers: {
  //   Authorization:
  //     'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJzZWN1cmUtYXBpIiwiYXVkIjoic2VjdXJlLWFwcCIsInN1YiI6ImFkbWluIiwiZXhwIjoxNTgxNzE0OTM0LCJyb2wiOlsiQURNSU4iLCJVU0VSIl0sInRtIjoiQUJDIiwidHoiOiJFdXJvcGUvU3RvY2tob2xtIn0.gexZ-2VkQiF-1trJxs_IDa7WZ5gWJT8Px8UXOgOojLxqnV4nGOxvGla_ViSw2RqoG9dfOtPaX4JTpD6_VhOGIw'
  // }
});

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (!error.response) return Promise.reject(error);

    if (error.response.status === 403) {
      store.dispatch(sessionActions.logoutSucceeded(true));
      return new Promise(() => {});
    }

    // if (
    //   error.response.data &&
    //   error.response.data.translationKey &&
    //   error.response.data.defaultTranslation
    // ) {
    //   toastError(
    //     error.response.data.translationKey,
    //     error.response.data.defaultTranslation.toUpperCase(),
    //     null,
    //     true,
    //     4000
    //   );
    // }
    return Promise.reject(error.response);
  }
);

export default api;
