import React from 'react';
import 'array-flat-polyfill';
import { Provider } from 'react-redux';
import store from './store';
import Main from './Main';

function App() {
  return (
    <div>
      <Provider store={store}>
        <Main />
      </Provider>
    </div>
  );
}

export default App;
