import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { gsap } from 'gsap';
import queryString from 'query-string';
import { TransitionGroup, Transition } from 'react-transition-group';
import {
  HomePage,
  GetQuotesPage,
  SelectQuotePage,
  AddDocumentsPage,
  OrderSummaryPage,
  NotFoundPage,
  LoginPage,
  CreateAccountPage,
  ManageAccountPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  OrderDashboardPage,
  AdminOrdersPage,
  AdminAccountsPage,
  AdminSettingsPage,
  TermsAndConditionsPage,
  EmailVerifiedPage,
  asSubRoutePage
} from 'pages';
import PrivateRoute from './privateRoute';

const getDefaultTimeline = (node, leave) => {
  const timeline = gsap.timeline();
  if (leave) {
    timeline.to(node, 0.3, { opacity: 0, display: 'none' });
  } else {
    timeline.fromTo(
      node,
      0.3,
      { display: 'none', opacity: 0, delay: 0.3 },
      { display: 'block', opacity: 1, delay: 0.3 }
    );
  }

  return timeline;
};

const play = (node, leave) => {
  if (!node) {
    return;
  }
  const timeline = getDefaultTimeline(node, leave);
  timeline.play();
};

let previousLocation;

function Routes(props) {
  return (
    <Route
      render={route => {
        const { history, location } = route;
        const { key } = location;
        const queryStringValues = queryString.parse(location.search);
        const { id, verificationKey, resetPasswordKey } = queryStringValues;

        let shouldAnimate = false;

        if (!previousLocation) {
          shouldAnimate = true;
        } else if (
          previousLocation.pathname === '/' &&
          !location.hash &&
          location.pathname !== '/'
        ) {
          shouldAnimate = true;
        } else if (previousLocation.pathname !== '/') {
          shouldAnimate = true;
        }

        previousLocation = location;

        return (
          <TransitionGroup component={null}>
            <Transition
              key={key}
              appear
              onEntering={node => {
                if (shouldAnimate) {
                  play(node);
                }
              }}
              onExit={node => {
                if (shouldAnimate) {
                  play(node, true);
                }
              }}
              timeout={{ enter: 300, exit: 300 }}
            >
              <Switch location={location}>
                <Route exact path="/" component={withRouter(HomePage)} />
                <Route exact path="/get-quotes" render={() => asSubRoutePage(GetQuotesPage)} />
                <Route exact path="/view-quotes" render={() => asSubRoutePage(SelectQuotePage)} />
                {/* <Route
                  exact
                  path="/terms-and-conditions"
                  render={() => asSubRoutePage(TermsAndConditionsPage)}
                /> */}
                <PrivateRoute
                  exact
                  path="/select-quote"
                  render={() => asSubRoutePage(SelectQuotePage)}
                  history={history}
                />
                <PrivateRoute
                  exact
                  path="/add-documents"
                  render={() => asSubRoutePage(AddDocumentsPage)}
                  history={history}
                />
                <PrivateRoute
                  exact
                  path="/order-summary"
                  render={() => asSubRoutePage(OrderSummaryPage)}
                  history={history}
                />
                <Route exact path="/login" render={() => asSubRoutePage(LoginPage)} />
                {id && verificationKey ? (
                  <Route
                    exact
                    path="/create-account"
                    render={(params => {
                      return () => asSubRoutePage(CreateAccountPage, params);
                    })({ id: parseInt(id, 10), verificationKey })}
                  />
                ) : null}
                {id && verificationKey ? (
                  <Route
                    exact
                    path="/email-verified"
                    render={(params => {
                      return () => asSubRoutePage(EmailVerifiedPage, params);
                    })({ id: parseInt(id, 10), verificationKey, history })}
                  />
                ) : null}
                <PrivateRoute
                  exact
                  path="/account"
                  render={() => asSubRoutePage(ManageAccountPage)}
                  history={history}
                />
                <PrivateRoute
                  exact
                  path="/order-dashboard"
                  render={() => asSubRoutePage(OrderDashboardPage)}
                  history={history}
                />
                <PrivateRoute
                  exact
                  path="/admin/orders"
                  render={userRoles => {
                    if (userRoles.includes('admin')) {
                      return asSubRoutePage(AdminOrdersPage, null, true);
                    }
                    return asSubRoutePage(NotFoundPage);
                  }}
                  history={history}
                />
                <PrivateRoute
                  exact
                  path="/admin/settings"
                  render={userRoles => {
                    if (userRoles.includes('admin')) {
                      return asSubRoutePage(AdminSettingsPage, null, true);
                    }
                    return asSubRoutePage(NotFoundPage);
                  }}
                  history={history}
                />
                <PrivateRoute
                  exact
                  path="/admin/accounts"
                  render={userRoles => {
                    if (userRoles.includes('admin')) {
                      return asSubRoutePage(AdminAccountsPage, null, true);
                    }
                    return asSubRoutePage(NotFoundPage);
                  }}
                  history={history}
                />
                <Route
                  exact
                  path="/forgot-password"
                  render={() => asSubRoutePage(ForgotPasswordPage)}
                />
                {id && resetPasswordKey ? (
                  <Route
                    exact
                    path="/reset-password"
                    render={(params => {
                      return () => asSubRoutePage(ResetPasswordPage, params);
                    })({ id: parseInt(id, 10), resetPasswordKey })}
                  />
                ) : null}
                <Route render={() => asSubRoutePage(NotFoundPage)} />
              </Switch>
            </Transition>
          </TransitionGroup>
        );
      }}
    />
  );
}

export default Routes;
