import React from 'react';
import { H3 } from 'components';
import { AdminAccounts } from 'features';
import styles from './AdminAccountsPage.module.scss';

function AdminAccountsPage() {
  return (
    <div className={styles.root}>
      <H3 className={styles.header} bold upperCase defaultText="Accounts">
        Translation.Page.AdminAccounts.header
      </H3>
      <AdminAccounts />
    </div>
  );
}

export default AdminAccountsPage;
