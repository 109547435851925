import React from 'react';
import { string, bool, arrayOf, adminSelectOptionType } from 'types';
import clsx from 'clsx';
import { Select } from 'components';
import styles from './AdminSelect.module.scss';

function AdminSelect(props) {
  const { className, options, useDefault, placeHolder, pathname } = props;
  const classNames = clsx(className, styles.root);
  const path = pathname.substring(pathname.lastIndexOf('/') + 1).toUpperCase();

  const customStyles = {
    option: (provided, state) => {
      const adminFunction = state.data.defaultLabel.toUpperCase();

      return {
        ...provided,
        color: path === adminFunction ? styles.whiteColor : styles.primaryColor,
        backgroundColor: path === adminFunction ? styles.secondaryColor : styles.whiteColor,
        padding: 10,
        '&:active': {
          background: state.isFocused ? styles.secondaryBrightColor : null
        },
        '&:hover': {
          opacity: 0.7
        },
        cursor: 'pointer'
      };
    },
    control: (provided, state) => ({
      ...provided,
      boxShadow: 'none',
      border: 'none',
      background: 'transparent',
      cursor: 'pointer',
      '&:hover': {
        '& div div': {
          color: styles.secondaryColor
        }
      },
      width: 100
    }),
    singleValue: (provided, state) => {
      return {
        ...provided,
        fontSize: 12,
        opacity: state.isDisabled ? 0.5 : 1,
        transition: 'opacity 300ms',
        color: useDefault ? styles.primaryColor : styles.whiteColor
      };
    },
    multiValue: (provided, { data }) => {
      return {
        ...provided,
        backgroundColor: styles.grayColor
      };
    },
    multiValueLabel: (provided, { data }) => ({
      ...provided,
      color: styles.primaryColor
    }),
    multiValueRemove: (provided, { data }) => ({
      ...provided,
      color: styles.primaryColor,
      ':hover': {
        backgroundColor: styles.darkGrayColor
      }
    }),
    indicatorSeparator: (provided, { data }) => ({
      ...provided,
      display: 'none'
    }),
    placeholder: (provided, { data }) => ({
      ...provided,
      color: useDefault ? styles.primaryColor : styles.whiteColor,
      fontWeight: 600,
      letterSpacing: 2,
      fontSize: 12,
      height: 14,
      transition: 'all 0.3s ease 0s'
    })
  };

  const onSelectedOption = option => {
    option.action();
  };

  return (
    <Select
      className={classNames}
      customStyles={customStyles}
      options={options}
      onChange={onSelectedOption}
      placeholder={placeHolder}
      isSearchable={false}
    />
  );
}

AdminSelect.propTypes = {
  className: string,
  options: arrayOf(adminSelectOptionType).isRequired,
  useDefault: bool,
  placeHolder: string,
  pathname: string.isRequired
};

AdminSelect.defaultProps = {
  className: null,
  useDefault: true,
  placeHolder: undefined
};

export default AdminSelect;
