import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  orderStateType,
  orderConfigurationStateType,
  quoteConfigurationStateType,
  sessionStateType,
} from 'types';
import { getExistingOrders, deleteAllOrderDocuments, updateTranslationFile } from 'services/order';
import { getOrderConfiguration, getQuoteConfiguration } from 'services/configuration';
import { toastSuccess, toastError } from 'utils/Toaster';
import { FEATURES } from 'assets';
import OrderDashboard from '.';

function OrderDashboardContainer(props) {
  const { orderState, orderConfigurationState, quoteConfigurationState, sessionState } = props;
  const { sourceLanguages, targetLanguages } = quoteConfigurationState;
  const { existingOrders } = orderState;
  useEffect(() => {
    getOrderConfiguration();
    getQuoteConfiguration();
    getExistingOrders();
  }, []);

  const busy = existingOrders.busy || orderConfigurationState.busy || quoteConfigurationState.busy;

  if (busy) {
    return null;
  }

  const onDeleteAllOrderDocuments = (orderId) => {
    deleteAllOrderDocuments(orderId)
      .then(() => {
        getOrderConfiguration();
        getQuoteConfiguration();
        getExistingOrders();
        toastSuccess(
          'Translation.Feature.OrderDashboard.DeleteAllOrderDocumentsSuccess.toasLabel',
          'ORDER DOCUMENTS DELETED'
        );
      })
      .catch((error) => {
        toastError(
          'Translation.Feature.OrderDashboard.DeleteAllOrderDocumentsFailes.toasLabel',
          'FAILED TO DELETE ORDER DOCUMENTS'
        );
      });
  };

  const onUpdateTranslationFile = (quoteId, fileId, language, file) => {
    return updateTranslationFile(quoteId, fileId, language, file)
      .then(() => {
        getOrderConfiguration();
        getQuoteConfiguration();
        getExistingOrders();
        toastSuccess(
          'Translation.Feature.OrderDashboard.UpdateTranslationFileSuccess.toastLabel',
          'TRANSLATION UPDATED'
        );
      })
      .catch((error) => {
        toastError(
          'Translation.Feature.OrderDashboard.UpdateTranslationFileFailed.toastLabel',
          'FAILED TO UPDATE TRANSLATION'
        );
      });
  };

  return (
    <OrderDashboard
      languages={[...sourceLanguages, ...targetLanguages]}
      filters={orderConfigurationState.orderFilterStatus}
      orders={existingOrders.orders}
      busy={busy}
      orderStatuses={orderConfigurationState.orderStatus}
      onDeleteAllOrderDocuments={onDeleteAllOrderDocuments}
      canDeleteOrderDocuments={sessionState.userFeatures.includes(
        FEATURES.deleteTranslationDocuments
      )}
      onUpdateTranslationFile={onUpdateTranslationFile}
    />
  );
}

OrderDashboardContainer.propTypes = {
  orderState: orderStateType.isRequired, // eslint-disable-line react/no-unused-prop-types
  orderConfigurationState: orderConfigurationStateType.isRequired,
  quoteConfigurationState: quoteConfigurationStateType.isRequired,
  sessionState: sessionStateType.isRequired,
};

const mapStateToProps = (state) => ({
  orderState: state.order,
  orderConfigurationState: state.configuration.order,
  quoteConfigurationState: state.configuration.quote,
  sessionState: state.session,
});

export default connect(mapStateToProps)(OrderDashboardContainer);
