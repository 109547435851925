import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { string, func } from 'types';
import clsx from 'clsx';
import { H3, H4, B1, Link, Label, Input, Button, Container } from 'components';
import isPhoneNumberValid from 'utils/phoneNumberValidator';
import { PoliciesPdf } from 'assets';
import styles from './AccountDetails.module.scss';

function AccountDetails(props) {
  const { className, onPreviousStep, onRegister, onShowAgreement } = props;
  const intl = useIntl();
  const classNames = clsx(className, styles.root);
  const [accountDetails, setAccountDetails] = useState({});
  const [validation, setValidation] = useState({});

  const _onPreviousStep = () => {
    onPreviousStep();
  };

  const _onRegister = () => {
    if (validate()) {
      onRegister(accountDetails);
    }
  };

  const companyNameChanged = (value) => {
    setAccountDetails({ ...accountDetails, companyName: value });
  };

  const nameChanged = (value) => {
    setAccountDetails({ ...accountDetails, customerName: value });
  };

  const organisationNumberChanged = (value) => {
    setAccountDetails({ ...accountDetails, organisationNumber: value });
  };

  const phoneChanged = (value) => {
    setAccountDetails({ ...accountDetails, phone: value });
  };

  const validate = () => {
    const currentValidation = {
      allValid: false,
      companyNameValid: false,
      customerNameValid: false,
      organisationNumberValid: false,
      phoneValid: false,
    };

    if (accountDetails.companyName) {
      currentValidation.companyNameValid = true;
    }

    if (accountDetails.customerName) {
      currentValidation.customerNameValid = true;
    }

    if (accountDetails.organisationNumber) {
      currentValidation.organisationNumberValid = true;
    }

    if (accountDetails.phone && isPhoneNumberValid(accountDetails.phone)) {
      currentValidation.phoneValid = true;
    }

    currentValidation.allValid =
      currentValidation.companyNameValid &&
      currentValidation.customerNameValid &&
      currentValidation.organisationNumberValid &&
      currentValidation.phoneValid;

    setValidation(currentValidation);

    return currentValidation.allValid;
  };

  return (
    <div className={classNames}>
      <H3 className={styles.header} bold upperCase defaultText="Register - Step 2(2)">
        Translation.Feature.CreateAccount.AccountDetails.RegisterStep.header
      </H3>
      <Container className={styles.container}>
        <div>
          <H4 className={styles.subHeader} bold upperCase defaultText="Create an account">
            Translation.Feature.CreateAccount.AccountDetails.Create.header
          </H4>
          <form autoComplete="on">
            <Label defaultText="Company name">
              Translation.Feature.CreateAccount.AccountDetails.CompanyName.label
            </Label>
            <Input
              className={styles.input}
              placeHolder="Translation.Feature.CreateAccount.AccountDetails.CompanyName.Input.placeholder"
              defaultPlaceHolder="Enter name of your company"
              onChange={companyNameChanged}
              isValid={validation.companyNameValid}
              validationMessage="Translation.Feature.CreateAccount.AccountDetails.CompanyName.Input.validationMessage"
              defaultValidationMessage="Please provide a company name"
              enterAction={_onRegister}
              name="organization"
              id="organization"
              maxValue={70}
            />
            <Label defaultText="Your name">
              Translation.Feature.CreateAccount.AccountDetails.Name.label
            </Label>
            <Input
              className={styles.input}
              placeHolder="Translation.Feature.CreateAccount.AccountDetails.Name.Input.placeholder"
              defaultPlaceHolder="Enter your name"
              onChange={nameChanged}
              isValid={validation.nameValid}
              validationMessage="Translation.Feature.CreateAccount.AccountDetails.Name.Input.validationMessage"
              defaultValidationMessage="Please provide a name"
              enterAction={_onRegister}
              name="name"
              id="name"
              maxValue={50}
            />
            <Label defaultText="Organisation number">
              Translation.Feature.CreateAccount.AccountDetails.OrganisationNumber.label
            </Label>
            <Input
              className={styles.input}
              placeHolder="Translation.Feature.CreateAccount.AccountDetails.OrganisationNumber.Input.placeholder"
              defaultPlaceHolder="Enter organisation number for your company"
              onChange={organisationNumberChanged}
              isValid={validation.organisationNumberValid}
              validationMessage="Translation.Feature.CreateAccount.AccountDetails.OrganisationNumber.Input.validationMessage"
              defaultValidationMessage="Please provide a valid organisation number"
              enterAction={_onRegister}
              maxValue={50}
            />
            <Label defaultText="Phone">
              Translation.Feature.CreateAccount.AccountDetails.Phone.label
            </Label>
            <Input
              className={styles.input}
              placeHolder="Translation.Feature.CreateAccount.AccountDetails.Phone.Input.placeholder"
              defaultPlaceHolder="Enter phone number"
              onChange={phoneChanged}
              isValid={validation.phoneValid}
              validationMessage="Translation.Feature.CreateAccount.AccountDetails.Phone.Input.validationMessage"
              defaultValidationMessage="Please provide a valid phone number"
              enterAction={_onRegister}
              name="tel"
              id="tel"
              maxValue={30}
            />
            <B1
              className={styles.bodyText}
              defaultText="By submitting the registration, you agree to our"
            >
              Translation.Feature.CreateAccount.AccountDetails.Agreement.label
            </B1>
            &nbsp;
            <Link
              bolder
              className={styles.link}
              defaultText="terms and conditions."
              // onClick={onShowAgreement}
              href={PoliciesPdf}
              target="_blank"
            >
              Translation.Feature.CreateAccount.AccountDetails.Agreement.link
            </Link>
            <p
              className={clsx(
                styles.validationText,
                validation.agreeToTermsAndConditions === false ? styles.show : null
              )}
            >
              {intl.formatMessage({
                id: 'Translation.Feature.CreateAccount.AccountDetails.Agreement.validationMessage',
                defaultMessage: 'Please agree to the terms and conditions',
              })}
            </p>
            <div className={styles.buttonContainer}>
              <Button
                className={styles.goBackButton}
                onClick={_onPreviousStep}
                preLeftArrow
                defaultText="Previous step"
              >
                Translation.Feature.CreateAccount.AccountDetails.Previous.Button.label
              </Button>
              <Button
                className={styles.registrationButton}
                secondary
                onClick={_onRegister}
                defaultText="Submit registration"
              >
                Translation.Feature.CreateAccount.AccountDetails.SubmitRegistration.Button.label
              </Button>
            </div>
          </form>
        </div>
      </Container>
    </div>
  );
}

AccountDetails.propTypes = {
  className: string,
  onPreviousStep: func.isRequired,
  onRegister: func.isRequired,
  onShowAgreement: func.isRequired,
};

AccountDetails.defaultProps = {
  className: null,
};

export default AccountDetails;
