import { createAction } from '@reduxjs/toolkit';

export default {
  getQuotesRequest: createAction('order/getQuotesRequest'),
  getQuotesSuccess: createAction('order/getQuotesSuccess'),
  getQuotesFailed: createAction('order/getQuotesFailed'),
  selectQuote: createAction('order/selectQuote'),
  uploadDocumentsRequest: createAction('order/uploadDocumentsRequest'),
  uploadDocumentsSuccess: createAction('order/uploadDocumentsSuccess'),
  uploadDocumentsFailed: createAction('order/uploadDocumentsFailed'),
  updateDocumentUploadPercentage: createAction('order/updateDocumentUploadPercentage'),
  removeDocumentRequest: createAction('order/removeDocumentRequest'),
  removeDocumentSuccess: createAction('order/removeDocumentSuccess'),
  removeDocumentFailed: createAction('order/removeDocumentFailed'),
  clearCurrentOrder: createAction('order/clearCurrentOrder'),
  getExistingOrdersRequest: createAction('order/getExistingOrdersRequest'),
  getExistingOrdersSuccess: createAction('order/getExistingOrdersSuccess'),
  getExistingOrdersFailed: createAction('order/getExistingOrdersFailed'),
  placeOrderRequest: createAction('order/placeOrderRequest'),
  placeOrderSuccess: createAction('order/placeOrderSuccess'),
  placeOrderFailed: createAction('order/placeOrderFailed'),
  changeTranslationInstructions: createAction('order/changeTranslationInstructions'),
  changeReference: createAction('order/changeReference'),
};
