import React from 'react';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusSquare, faBan } from '@fortawesome/free-solid-svg-icons';
import { NUMBER_FORMAT } from 'assets';
import { H3, S1, B1, Button, Container, ProgressBar } from 'components';
import { func, string, bool, number, arrayOf, fileType } from 'types';
import styles from './AddDocuments.module.scss';

function AddDocuments(props) {
  const {
    className,
    documents,
    onSelectedDocuments,
    wordCount,
    onRemoveDocument,
    onCancelUploadDocument,
    onGoBack,
    onOrderSummary,
    busy,
    acceptedFileTypes
  } = props;
  const intl = useIntl();
  const classNames = clsx(className, styles.root);

  const renderDocuments = () => {
    let content = null;
    if (documents.length > 0) {
      content = (
        <div>
          <B1 defaultText="Received documents">
            Translation.Feature.AddDocuments.ReceivedDocuments.header
          </B1>
          <ul className={styles.documentList}>
            {documents.map(document => {
              let documentSize =
                Math.round((document.size * 100 * (document.uploadPercentage / 100)) / 1024) / 100;
              let documentSizeUnit = 'kB';

              if (documentSize > 99) {
                documentSize = Math.round((documentSize * 100) / 1024) / 100;
                documentSizeUnit = 'MB';
              }

              return (
                <li key={document.path + document.lastModified}>
                  <div className={styles.documentContainer}>
                    <B1 translate={false} className={styles.documentNameLabel}>
                      {document.name}
                    </B1>
                    <div className={styles.documentActionContainer}>
                      <B1 translate={false} className={styles.documentSizeLabel}>
                        ({`${documentSize} ${documentSizeUnit}`})
                      </B1>
                      {document.uploadPercentage !== 100 ? (
                        <FontAwesomeIcon
                          icon={faBan}
                          className={styles.removeDocumentIcon}
                          onClick={() => onCancelUploadDocument(document)}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faMinusSquare}
                          className={styles.removeDocumentIcon}
                          onClick={() => onRemoveDocument(document)}
                        />
                      )}
                    </div>
                  </div>
                  <ProgressBar
                    className={styles.uploadProgressBar}
                    value={document.uploadPercentage}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      );
    }

    return content;
  };

  return (
    <div className={classNames}>
      <H3 className={styles.header} bold upperCase defaultText="Add documents">
        Translation.Feature.AddDocuments.header
      </H3>
      <S1 className={styles.subHeader} translate={false}>
        {intl.formatMessage(
          {
            id: 'Translation.Feature.AddDocuments.Upload.header',
            defaultMessage: 'Upload document(s) for the {wordCount} words to be translated.'
          },
          { wordCount: wordCount ? intl.formatNumber(wordCount, NUMBER_FORMAT) : '#' }
        )}
      </S1>
      <Container>
        <Dropzone
          onDrop={onSelectedDocuments}
          // accept={acceptedFileTypes}
          minSize={1}
          maxSize={25165824}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div className={styles.dropZoneContainer} {...getRootProps()}>
                <input {...getInputProps()} />
                <Button preUpArrow disabled={busy} defaultText="Upload new document(s)">
                  Translation.Feature.AddDocuments.Upload.Button.label
                </Button>
                <div className={styles.dragAndDropContainer}>
                  <B1 className={styles.dragAndDropLabel} defaultText="Or drag them here">
                    Translation.Feature.AddDocuments.DragArea.label
                  </B1>
                </div>
              </div>
              {renderDocuments()}
            </section>
          )}
        </Dropzone>
      </Container>
      <S1
        className={styles.subHeader}
        defaultText="The documents will be securely uploaded and stored on our servers, using the same security
        standards as banks."
      >
        Translation.Feature.AddDocuments.Upload.Information.label
      </S1>
      <div className={styles.buttonContainer}>
        <Button
          preLeftArrow
          className={busy ? styles.backButtonNoHover : styles.backButton}
          onClick={onGoBack}
          disabled={busy}
          defaultText="Go back and edit"
        >
          Translation.Feature.AddDocuments.GoBack.Button.label
        </Button>
        <Button
          preRightArrow
          secondary
          className={styles.nextButton}
          onClick={onOrderSummary}
          disabled={busy || documents.length === 0}
          defaultText="Order summary"
        >
          Translation.Feature.AddDocuments.OrderSummary.Button.label
        </Button>
      </div>
    </div>
  );
}

AddDocuments.propTypes = {
  className: string,
  documents: arrayOf(fileType),
  onSelectedDocuments: func.isRequired,
  wordCount: number,
  onRemoveDocument: func.isRequired,
  onCancelUploadDocument: func.isRequired,
  onGoBack: func.isRequired,
  onOrderSummary: func.isRequired,
  busy: bool,
  acceptedFileTypes: arrayOf(string)
};

AddDocuments.defaultProps = {
  className: null,
  documents: [],
  wordCount: null,
  busy: false,
  acceptedFileTypes: null
};

export default AddDocuments;
