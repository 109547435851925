import React, { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { string, bool, number, func, oneOfType } from 'types';
import clsx from 'clsx';
import styles from './TextArea.module.scss';

function TextArea({ className, ...props }) {
  const {
    bold,
    bolder,
    disabled,
    upperCase,
    onChange,
    value,
    enterAction,
    autoFocus,
    maxValue,
    placeholder,
    defaultPlaceholder,
    ...other
  } = props;

  const [privateValue, setPrivateValue] = useState(value);
  const textAreaRef = useRef();
  const intl = useIntl();

  const classNames = clsx(
    className,
    styles.root,
    bold && styles.bold,
    bolder && styles.bolder,
    disabled && styles.disabled,
    upperCase && styles.upperCase
  );

  useEffect(() => {
    if (autoFocus && textAreaRef.current) {
      const input = textAreaRef.current;
      setTimeout(() => input.focus(), 400);
    }
  }, [autoFocus]);

  const onValueChange = (evt) => {
    if (maxValue === null || evt.target.value.length <= maxValue) {
      setPrivateValue(evt.target.value);
      if (onChange) {
        onChange(evt.target.value);
      }
    }
  };

  const handleKeyPress = (evt) => {
    if (enterAction && evt.key.toLowerCase() === 'enter') {
      enterAction(evt);
    }
  };

  const renderContent = () => {
    return (
      <textarea
        className={classNames}
        onChange={onValueChange}
        value={privateValue}
        onKeyPress={handleKeyPress}
        ref={textAreaRef}
        placeholder={
          placeholder && intl.formatMessage({ id: placeholder, defaultMessage: defaultPlaceholder })
        }
        {...other}
      />
    );
  };

  return renderContent();
}

TextArea.propTypes = {
  className: string,
  disabled: bool,
  bold: bool,
  bolder: bool,
  upperCase: bool,
  onChange: func,
  value: oneOfType([string, number]),
  enterAction: func,
  autoFocus: bool,
  maxValue: number,
};

TextArea.defaultProps = {
  className: null,
  disabled: false,
  bold: false,
  bolder: false,
  upperCase: false,
  onChange: null,
  value: '',
  enterAction: null,
  autoFocus: false,
  maxValue: null,
};

export default TextArea;
