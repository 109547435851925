import axios from 'axios';
import api from '../api';
import store from '../../store';
import orderActions from './actions';

const quoteEndpoint = 'quotes';
const orderEndpoint = 'orders';

const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const getQuotes = (request) => {
  store.dispatch(orderActions.getQuotesRequest(request));
  const req = {
    sourceLanguage: request.sourceLanguage.value,
    targetLanguages: request.targetLanguages.map((tl) => tl.value),
    wordCount: request.wordCount,
    translationSubject: request.translationSubject.value,
  };
  return api
    .post(`${quoteEndpoint}`, req)
    .then((response) => store.dispatch(orderActions.getQuotesSuccess(response.data)))
    .catch(() => store.dispatch(orderActions.getQuotesFailed()));
};

let cancelTokens = [];
const uploading = [];

const uploadDocuments = (request, id) => {
  store.dispatch(orderActions.uploadDocumentsRequest(request));
  request.forEach((document) => {
    uploading[document.name] = true;
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    cancelTokens.push({
      source,
      document,
    });
    const formData = new FormData();
    formData.append('file', document);
    const config = {
      cancelToken: source.token,
      headers: {
        'content-type': 'multipart/form-data',
      },
      onUploadProgress: async (progressEvent) => {
        const uploadPercentage = (progressEvent.loaded / progressEvent.total) * 100;
        let fakeUploadPercentage;
        if (uploadPercentage > 90 && !fakeUploadPercentage) {
          fakeUploadPercentage = 90;
          while (fakeUploadPercentage < 99 && uploading[document.name]) {
            store.dispatch(
              orderActions.updateDocumentUploadPercentage({
                document,
                uploadPercentage: fakeUploadPercentage,
              })
            );
            fakeUploadPercentage += 0.1;
            // eslint-disable-next-line no-await-in-loop
            await sleep(500);
          }
        } else if (!fakeUploadPercentage) {
          store.dispatch(
            orderActions.updateDocumentUploadPercentage({
              document,
              uploadPercentage,
            })
          );
        }
      },
    };
    return api
      .post(`${quoteEndpoint}/${id}/files`, formData, config)
      .then((response) => {
        delete uploading[document.name];
        store.dispatch(
          orderActions.uploadDocumentsSuccess({ response: response.data, document, quoteId: id })
        );
        cancelTokens = cancelTokens.filter(
          (ct) =>
            !(
              ct.document.path === document.path &&
              ct.document.name === document.name &&
              ct.document.size === document.size
            )
        );
      })
      .catch((error) => {
        delete uploading[document.name];
        store.dispatch(orderActions.uploadDocumentsFailed(document));
        throw error;
      });
  });
};

const cancelUploadDocument = (document) => {
  const cancelToken = cancelTokens.find(
    (ct) =>
      ct.document.path === document.path &&
      ct.document.name === document.name &&
      ct.document.size === document.size
  );

  if (cancelToken) {
    cancelToken.source.cancel('Upload canceled by the user.');
    cancelTokens = cancelTokens.filter(
      (ct) =>
        !(
          ct.document.path === document.path &&
          ct.document.name === document.name &&
          ct.document.size === document.size
        )
    );
    store.dispatch(orderActions.removeDocumentSuccess(document));
  }
};

const removeDocument = (document) => {
  store.dispatch(orderActions.removeDocumentRequest());

  return api
    .delete(`${quoteEndpoint}/${document.quoteId}/files/${document.id}`)
    .then((response) => {
      store.dispatch(orderActions.removeDocumentSuccess(document));
    })
    .catch((error) => {
      store.dispatch(orderActions.removeDocumentFailed(error));
      throw error;
    });
};

const placeOrder = (currentOrder) => {
  store.dispatch(orderActions.placeOrderRequest());

  const request = {
    id: currentOrder.selectedQuote.id,
    translationInstructions: currentOrder.translationInstructions,
    oldQuoteIds: currentOrder.oldQuoteIds,
    buyersReference: currentOrder.reference,
  };

  return api
    .put(`${quoteEndpoint}/${currentOrder.selectedQuote.id}`, request)
    .then((response) => {
      store.dispatch(orderActions.placeOrderSuccess());
    })
    .catch((error) => {
      store.dispatch(orderActions.placeOrderFailed(error));
      throw error;
    });
};

const getExistingOrders = () => {
  store.dispatch(orderActions.getExistingOrdersRequest());

  return api
    .get(`${orderEndpoint}`)
    .then((response) => store.dispatch(orderActions.getExistingOrdersSuccess(response.data)))
    .catch(() => store.dispatch(orderActions.getExistingOrdersFailed()));
};

const clearCurrentOrder = () => {
  store.dispatch(orderActions.clearCurrentOrder());
};

const deleteAllOrderDocuments = (orderId) => {
  return api.delete(`${quoteEndpoint}/${orderId}/files`);
};

const updateTranslationFile = (quoteId, fileId, language, file) => {
  const formData = new FormData();
  formData.append('file', file);
  return api.put(`${quoteEndpoint}/${quoteId}/files/${fileId}/upload/${language}`, formData);
};

export {
  getQuotes,
  uploadDocuments,
  removeDocument,
  placeOrder,
  getExistingOrders,
  cancelUploadDocument,
  clearCurrentOrder,
  deleteAllOrderDocuments,
  updateTranslationFile,
};
