import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { placeOrder, clearCurrentOrder } from 'services/order';
import orderActions from 'services/order/actions';
import { orderStateType, orderActionsType } from 'types';
import OrderSummary from '.';

function OrderSummaryContainer(props) {
  const {
    orderState: { currentOrder },
    actions,
  } = props;

  const [showOrderReceived, setShowOrderReceived] = useState(false);
  const [busy, setBusy] = useState(false);
  const [orderConfirmed, setOrderConfirmed] = useState(false);
  const [navigationUrl, setNavigationUrl] = useState();

  useEffect(() => {
    if (currentOrder.documents.length === 0 && !orderConfirmed) {
      setNavigationUrl('/get-quotes');
    }
  }, []);

  useEffect(() => {
    if (orderConfirmed) {
      clearCurrentOrder();
    }
  }, [orderConfirmed]);

  const onGoBack = () => {
    setNavigationUrl('/add-documents');
  };

  const onPlaceOrder = () => {
    setBusy(true);
    placeOrder(currentOrder).then(() => {
      setBusy(false);
      setShowOrderReceived(true);
    });
  };

  const onConfirmOrder = () => {
    setOrderConfirmed(true);
    setNavigationUrl('/order-dashboard');
  };

  const onTranslationInstructionsChanged = (text) => {
    actions.changeTranslationInstructions(text);
  };

  if (navigationUrl || (currentOrder.documents.length === 0 && !orderConfirmed)) {
    return <Redirect to={navigationUrl} />;
  }

  const onReferenceChanged = (text) => {
    actions.changeReference(text);
  };

  if (navigationUrl || (currentOrder.documents.length === 0 && !orderConfirmed)) {
    return <Redirect to={navigationUrl} />;
  }

  return (
    <OrderSummary
      onGoBack={onGoBack}
      onPlaceOrder={onPlaceOrder}
      order={currentOrder}
      showOrderReceived={showOrderReceived}
      onTranslationInstructionsChanged={onTranslationInstructionsChanged}
      onReferenceChanged={onReferenceChanged}
      onConfirmOrder={onConfirmOrder}
      busy={busy}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    orderState: state.order,
  };
};

const mapDispatch = (dispatch) => ({
  actions: bindActionCreators({ ...orderActions }, dispatch),
});

OrderSummaryContainer.propTypes = {
  orderState: orderStateType.isRequired, // eslint-disable-line react/no-unused-prop-types,
  actions: orderActionsType.isRequired,
};

export default connect(mapStateToProps, mapDispatch)(OrderSummaryContainer);
