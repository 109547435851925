import React from 'react';
import { OrderSummary } from 'features';
import styles from './OrderSummary.module.scss';

function OrderSummaryPage() {
  return (
    <div className={styles.root}>
      <OrderSummary />
    </div>
  );
}

export default OrderSummaryPage;
