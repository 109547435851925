import React from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { faFileAlt as farFileAlt } from '@fortawesome/free-regular-svg-icons';
import { string, number } from 'types';
import { C } from 'components';
import styles from './FileTypeIcon.module.scss';

const FileTypeIcon = props => {
  const { height, width, className, fileType, color } = props;
  const classNames = clsx(styles.root, className);

  const getFileTypeIconColor = () => {
    if (color) {
      return color;
    }

    if (!fileType) {
      return styles.defaultColor;
    }

    if (fileType.toLowerCase() === 'pdf') {
      return styles.pdfColor;
    }

    if (fileType.toLowerCase().startsWith('xl') || fileType.toLowerCase() === 'csv') {
      return styles.excelColor;
    }

    if (fileType.toLowerCase().startsWith('doc')) {
      return styles.wordColor;
    }

    if (fileType.toLowerCase() === 'txt') {
      return styles.textColor;
    }

    return styles.defaultColor;
  };

  return (
    <div className={classNames}>
      <FontAwesomeIcon
        icon={fileType.toLowerCase() === 'txt' ? farFileAlt : faFileAlt}
        className={styles.fileIcon}
        style={{ width: `${width}px`, height: `${height}px`, color: getFileTypeIconColor() }}
      />
      {fileType ? (
        <C className={styles.fileTypeText} translate={false}>
          {fileType}
        </C>
      ) : null}
    </div>
  );
};

FileTypeIcon.propTypes = {
  className: string,
  fileType: string,
  width: number,
  height: number,
  color: string
};

FileTypeIcon.defaultProps = {
  className: null,
  fileType: null,
  width: 35,
  height: 35,
  color: null
};

export default FileTypeIcon;
