import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { H3 } from 'components';
import styles from './TermsAndConditionsPage.module.scss';

function TermsAndConditionsPage() {
  const intl = useIntl();

  return (
    <div className={styles.root}>
      <H3 className={styles.header} bold upperCase defaultText="Terms and Conditions">
        Translation.Page.TermsAndConditions.header
      </H3>
      <div className={styles.termsAndConditionsContainer}>
        {!!intl.messages['Translation.Page.TermsAndConditions.text'] && (
          <FormattedMessage
            id="Translation.Page.TermsAndConditions.text"
            defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a default text."
            values={{
              b: (...chunks) => <b>{chunks}</b>,
              NL: <br />,
              nl: <br />,
              Nl: <br />
            }}
          />
        )}
        {!!intl.messages['Translation.Page.TermsAndConditions2.text'] && (
          <FormattedMessage
            id="Translation.Page.TermsAndConditions2.text"
            defaultMessage={null}
            values={{
              b: (...chunks) => <b>{chunks}</b>,
              NL: <br />,
              nl: <br />,
              Nl: <br />
            }}
          />
        )}
        {!!intl.messages['Translation.Page.TermsAndConditions3.text'] && (
          <FormattedMessage
            id="Translation.Page.TermsAndConditions3.text"
            defaultMessage=""
            values={{
              b: (...chunks) => <b>{chunks}</b>,
              NL: <br />,
              nl: <br />,
              Nl: <br />
            }}
          />
        )}
        {!!intl.messages['Translation.Page.TermsAndConditions4.text'] && (
          <FormattedMessage
            id="Translation.Page.TermsAndConditions4.text"
            defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a default text."
            values={{
              b: (...chunks) => <b>{chunks}</b>,
              NL: <br />,
              nl: <br />,
              Nl: <br />
            }}
          />
        )}
        {!!intl.messages['Translation.Page.TermsAndConditions5.text'] && (
          <FormattedMessage
            id="Translation.Page.TermsAndConditions5.text"
            defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a default text."
            values={{
              b: (...chunks) => <b>{chunks}</b>,
              NL: <br />,
              nl: <br />,
              Nl: <br />
            }}
          />
        )}
        {!!intl.messages['Translation.Page.TermsAndConditions6.text'] && (
          <FormattedMessage
            id="Translation.Page.TermsAndConditions6.text"
            defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a default text."
            values={{
              b: (...chunks) => <b>{chunks}</b>,
              NL: <br />,
              nl: <br />,
              Nl: <br />
            }}
          />
        )}
        {!!intl.messages['Translation.Page.TermsAndConditions7.text'] && (
          <FormattedMessage
            id="Translation.Page.TermsAndConditions7.text"
            defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a default text."
            values={{
              b: (...chunks) => <b>{chunks}</b>,
              NL: <br />,
              nl: <br />,
              Nl: <br />
            }}
          />
        )}
        {!!intl.messages['Translation.Page.TermsAndConditions8.text'] && (
          <FormattedMessage
            id="Translation.Page.TermsAndConditions8.text"
            defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a default text."
            values={{
              b: (...chunks) => <b>{chunks}</b>,
              NL: <br />,
              nl: <br />,
              Nl: <br />
            }}
          />
        )}
        {!!intl.messages['Translation.Page.TermsAndConditions9.text'] && (
          <FormattedMessage
            id="Translation.Page.TermsAndConditions9.text"
            defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a default text."
            values={{
              b: (...chunks) => <b>{chunks}</b>,
              NL: <br />,
              nl: <br />,
              Nl: <br />
            }}
          />
        )}
        {!!intl.messages['Translation.Page.TermsAndConditions10.text'] && (
          <FormattedMessage
            id="Translation.Page.TermsAndConditions10.text"
            defaultMessage="Please <b>add translation for this text</b>.{NL}This is just a default text."
            values={{
              b: (...chunks) => <b>{chunks}</b>,
              NL: <br />,
              nl: <br />,
              Nl: <br />
            }}
          />
        )}
      </div>
    </div>
  );
}

export default TermsAndConditionsPage;
