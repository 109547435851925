import React from 'react';
import { ensuredForwardRef } from 'react-use';
import { useIntl } from 'react-intl';
import { string, languageType, arrayOf } from 'types';
import clsx from 'clsx';
import { H3, B1, C, Container } from 'components';
import styles from './AvailableLanguages.module.scss';

const AvailableLanguages = ensuredForwardRef((props, ref) => {
  const { className, languages } = props;
  const classNames = clsx(className, styles.root);
  const intl = useIntl();

  const renderLanguages = () => {
    const languageRenders = languages.map(l => (
      <C key={l.value} defaultText={l.defaultLabel} className={styles.languageLabel}>
        {l.label}
      </C>
    ));

    const languageGroups = [];
    const groupSize = Math.ceil(languageRenders.length / 6);

    for (let index = 0; index < groupSize; index += 1) {
      const languagesToRender = languageRenders.slice(index * groupSize, (index + 1) * groupSize);
      if (languagesToRender.length > 0) {
        languageGroups.push(
          <Container
            key={`Languages${index}`}
            className={styles.languages}
            borderColor={styles.transparent}
          >
            {languagesToRender}
          </Container>
        );
      }
    }

    return languageGroups;
  };

  return (
    <div className={classNames} ref={ref}>
      <H3 className={styles.header} bold upperCase defaultText="Available languages">
        Translation.Feature.AvailableLanguages.header
      </H3>
      <div className={styles.bodyTextContainer}>
        <B1 className={styles.bodyText} defaultText="We currently support" inline>
          Translation.Feature.AvailableLanguages.subHeader1
        </B1>
        &nbsp;
        <B1 className={styles.numberOfLanguagesText} inline translate={false}>
          {intl.formatMessage(
            {
              id: 'Translation.Feature.AvailableLanguages.NumberOfLanguages.label',
              defaultMessage: '{numberOfLanguages} languages'
            },
            {
              numberOfLanguages: languages.length
            }
          )}
        </B1>
        <B1 className={styles.bodyText} inline translate={false}>
          ,
        </B1>
        &nbsp;
        <B1
          className={styles.bodyText}
          defaultText="which makes it possible for us to work with a large variety of language pairs."
          inline
        >
          Translation.Feature.AvailableLanguages.subHeader2
        </B1>
      </div>

      <div className={styles.languageContainer}>{renderLanguages()}</div>
    </div>
  );
});

AvailableLanguages.propTypes = {
  className: string,
  languages: arrayOf(languageType).isRequired
};

AvailableLanguages.defaultProps = {
  className: null
};

export default AvailableLanguages;
