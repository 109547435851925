import React, { useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import { func, string, arrayOf, orderType, bool, translationItemType, languageType } from 'types';
import clsx from 'clsx';
import { Loader, FileTypeIcon } from 'assets';
import {
  S1,
  B1,
  B2,
  H3,
  Container,
  CheckBox,
  Link,
  Modal,
  Button,
  Select,
  Label,
} from 'components';
import styles from './OrderDashboard.module.scss';

function OrderDashboard(props) {
  const {
    className,
    orders,
    busy,
    orderStatuses,
    languages,
    filters,
    onDeleteAllOrderDocuments,
    canDeleteOrderDocuments,
    onUpdateTranslationFile,
  } = props;
  const intl = useIntl();
  const [filter, setFilter] = useState({ ongoing: false, ready: false, cancelled: false });
  const [
    showDeleteAllOrderDocumentsConfirmation,
    setShowDeleteAllOrderDocumentsConfirmation,
  ] = useState(false);
  const [showUpdateTranslation, setShowUpdateTranslation] = useState(false);
  const [currentOrderId, setCurrentOrderId] = useState();
  const [currentOrder, setCurrentOrder] = useState();
  const [updateTranslationFileInformation, setUpdateTranslationFileInformation] = useState(null);
  const [updatingTranslationFile, setUpdatingTranslationFile] = useState(false);
  const classNames = clsx(className, styles.root);
  const inputFile = useRef(null);

  const filterChanged = (currentFilter) => {
    setFilter({ ...filter, ...currentFilter });
  };

  const countNumberOfTranslations = (order) => {
    let numberOfTranslations = 0;

    order.translations.forEach((t) => {
      numberOfTranslations += t.targets.length;
    });

    return numberOfTranslations;
  };

  const filterOrders = () => {
    if (Object.keys(filter).reduce((a, b) => a || filter[b], false)) {
      return orders.filter((o) => filter[o.filterStatus]);
    }
    return orders;
  };

  const getLanguageTranslation = (value) => {
    return languages.find((l) => l.value === value);
  };

  let filteredOrders = filterOrders();
  filteredOrders = filteredOrders.slice().sort((a, b) => {
    return b.placed - a.placed;
  });

  const renderOrders = filteredOrders.map((o) => {
    const numberOfTranslations = countNumberOfTranslations(o);
    const orderStatus = orderStatuses.find((os) => os.value === o.status);

    return (
      <Container key={o.id} className={styles.orderContainer} fullWidth>
        <div className={styles.orderHeaderContainer}>
          <H3 className={styles.headerLabel} defaultText="Order">
            Translation.Feature.OrderDashboard.Order.header
          </H3>
          &nbsp;
          <H3 className={styles.headerLabel} translate={false}>
            #
          </H3>
          &nbsp;
          <H3 className={styles.headerLabel} translate={false}>
            {o.id}
          </H3>
          &nbsp; &nbsp; &nbsp;
          <S1 className={styles.headerLabel} defaultText="placed">
            Translation.Feature.OrderDashboard.OrderPlaced.header
          </S1>
          &nbsp;
          <S1 className={styles.headerLabel} translate={false}>
            {intl.formatDate(o.placed)}
          </S1>
          &nbsp;
          <S1 className={styles.headerLabel} translate={false}>{`(${intl.formatMessage(
            {
              id:
                numberOfTranslations === 1
                  ? 'Translation.Feature.OrderDashboard.NumberOfTranslationsSingular.header'
                  : 'Translation.Feature.OrderDashboard.NumberOfTranslationsPlural.header',
              defaultMessage:
                numberOfTranslations === 1
                  ? '{numberOfTranslations} translation'
                  : '{numberOfTranslations} translations',
            },
            { numberOfTranslations }
          )})`}</S1>
        </div>
        {o.translations.map((t, translationIndex) => {
          const fileType = t.source.documentName.substring(
            t.source.documentName.lastIndexOf('.') + 1
          );
          const sourceLanguage = getLanguageTranslation(t.source.language);

          return t.targets.map((target, index) => {
            const targetLanguage = getLanguageTranslation(target.language);
            return (
              <div
                key={t.source.documentName + targetLanguage.label}
                className={clsx(
                  styles.translationRow,
                  index === t.targets.length - 1 &&
                    o.translations.length > 1 &&
                    translationIndex !== o.translations.length - 1 &&
                    styles.marginBottom
                )}
              >
                <Container
                  className={clsx(
                    styles.translationContainer,
                    index !== 0 && styles.hidden,
                    o.allDocumentsDeleted && styles.disabled
                  )}
                >
                  <FileTypeIcon fileType={fileType} />
                  <div className={styles.textContainer}>
                    <B1 bold translate={false} className={styles.documentNameLabel}>
                      {t.source.documentName}
                    </B1>
                    <B1 className={styles.languageLabel} defaultText={sourceLanguage.defaultLabel}>
                      {sourceLanguage.label}
                    </B1>
                  </div>
                  {t.source.link && !o.allDocumentsDeleted ? (
                    <div className={styles.downloadLinkContainer}>
                      <Link
                        bolder
                        className={styles.downloadLink}
                        defaultText="Download"
                        href={t.source.link}
                        download
                      >
                        Translation.Feature.OrderDashboard.DownloadTranslation.label
                      </Link>
                    </div>
                  ) : null}
                </Container>
                <Container
                  className={clsx(
                    styles.translationContainer,
                    (!target.link || o.allDocumentsDeleted) && styles.disabled
                  )}
                >
                  <FileTypeIcon fileType={fileType} />
                  <div className={styles.textContainer}>
                    <B1 bold translate={false} className={styles.documentNameLabel}>
                      {target.documentName}
                    </B1>
                    <div className={styles.targetLanguageContainer}>
                      <B1
                        className={styles.languageLabel}
                        defaultText={targetLanguage.defaultLabel}
                      >
                        {targetLanguage.label}
                      </B1>
                      {target.userUploaded && (
                        <B1 bolder className={styles.correctedLabel} defaultText="Updated">
                          Translation.Feature.OrderDashboard.TargetUpdated.label
                        </B1>
                      )}
                    </div>
                  </div>
                  <div className={styles.downloadLinkContainer}>
                    {target.link && !o.allDocumentsDeleted ? (
                      <Link
                        bolder
                        className={styles.downloadLink}
                        defaultText="Download"
                        href={target.link}
                        download
                      >
                        Translation.Feature.OrderDashboard.DownloadTranslation.label
                      </Link>
                    ) : (
                      <B1 className={styles.translationDeliveryDate} translate={false}>
                        {intl.formatDate(target.deliveryDate, {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                        })}
                        &nbsp;
                        {intl.formatTime(target.deliveryDate, {
                          hour: 'numeric',
                          minute: 'numeric',
                        })}
                      </B1>
                    )}
                  </div>
                </Container>
              </div>
            );
          });
        })}
        <div className={styles.footerContainer}>
          <S1 className={styles.footerLabel} defaultText="Words:">
            Translation.Feature.OrderDashboard.Words.footer
          </S1>
          &nbsp;
          <S1 className={styles.footerLabel} bold translate={false}>
            {o.wordCount}
          </S1>
          <S1 className={clsx(styles.footerLabel, styles.pushRight)} defaultText="Status:">
            Translation.Feature.OrderDashboard.Status.footer
          </S1>
          &nbsp;
          <S1 className={styles.footerLabel} bold defaultText={orderStatus.defaultTranslation}>
            {orderStatus.translationKey}
          </S1>
        </div>
        {o.allDocumentsDeleted ? (
          <S1 className={styles.allDocumentsDeletedLabel} bold defaultText="All documents deleted">
            Translation.Feature.OrderDashboard.AllDocumentsDeleted.label
          </S1>
        ) : (
          <>
            {canDeleteOrderDocuments && (
              <Link
                bolder
                className={styles.deleteAllOrderDocumentsLink}
                defaultText="Delete all"
                onClick={() => {
                  setCurrentOrderId(o.id);
                  setShowDeleteAllOrderDocumentsConfirmation(true);
                }}
              >
                Translation.Feature.OrderDashboard.DeleteAllOrderDocuments.footer
              </Link>
            )}
            <Link
              bolder
              className={o.downloadAll ? styles.downloadAllLink : styles.downloadAllLinkDisabled}
              defaultText="Download all"
              disabled={o.downloadAll === null}
              href={o.downloadAll}
              download
            >
              Translation.Feature.OrderDashboard.DownloadAll.footer
            </Link>
            <Link
              bolder
              className={
                o.downloadAll ? styles.updateTranslationLink : styles.updateTranslationLinkDisabled
              }
              disabled={o.downloadAll === null}
              onClick={() => {
                setCurrentOrder(o);
                setShowUpdateTranslation(true);
              }}
              defaultText="Update translation"
            >
              Translation.Feature.OrderDashboard.UpdateTranslation.footer
            </Link>
          </>
        )}
      </Container>
    );
  });

  const renderFilters = (
    <div className={styles.filterContainer}>
      <S1 defaultText="Filter" className={styles.noMargin}>
        Translation.Feature.OrderDashboard.Filter.label
      </S1>
      <S1 translate={false} className={styles.noMargin}>
        :
      </S1>
      <div className={styles.checkBoxContainer}>
        {filters.map((f) => (
          <div key={f.value} className={styles.checkBox}>
            <CheckBox
              id={f.value}
              label={f.translationKey}
              defaultLabel={f.defaultTranslation}
              checked={filter.ongoing}
              onChange={(checked) => filterChanged({ [f.value]: checked })}
            />
          </div>
        ))}
      </div>
    </div>
  );

  if (busy) {
    return <Loader className={styles.loader} />;
  }

  const currentOrderSourceDocuments =
    currentOrder?.translations.map((t) => ({
      label: t.source.documentName,
      value: t.id,
      translate: false,
    })) ?? [];

  const selectedSourceDocumentOnCurrentOrder = currentOrder?.translations.find(
    (t) => t.id === updateTranslationFileInformation?.originalFileOption?.value
  );

  const currentOrderSourceDocumentTargetLanguages =
    selectedSourceDocumentOnCurrentOrder?.targets.map((t) => {
      const language = getLanguageTranslation(t.language);

      return {
        label: language.label,
        defaultLabel: language.defaultLabel,
        value: language.value,
      };
    }) ?? [];

  return (
    <>
      <Container className={classNames} fullWidth>
        {renderFilters}
        {filteredOrders.length === 0 ? (
          <S1 defaultText="No orders to display">
            Translation.Feature.OrderDashboard.NoOrders.label
          </S1>
        ) : (
          renderOrders
        )}
      </Container>
      <Modal
        isOpen={showDeleteAllOrderDocumentsConfirmation}
        title="Translation.Feature.OrderDashboard.DeleteAllOrderDocuments.Modal.title"
        defaultTitle="Delete all order documents"
        hideOnBackDropClick={false}
      >
        <B2
          className={styles.noMargin}
          defaultText="Are you sure you want to delete all documents related to this order?"
        >
          Translation.Feature.OrderDashboard.DeleteAllOrderDocuments.Modal.text
        </B2>
        <B2 className={styles.noMargin} defaultText="This can not be undone.">
          Translation.Feature.OrderDashboard.DeleteAllOrderDocuments.Modal.text2
        </B2>
        <div className={styles.modalButtonContainer}>
          <Button
            defaultText="Cancel"
            className={styles.modalDeleteAllOrderDocumentsCancelButton}
            onClick={() => {
              setCurrentOrderId(null);
              setShowDeleteAllOrderDocumentsConfirmation(false);
            }}
          >
            Translation.Feature.OrderDashboard.DeleteAllOrderDocuments.Modal.CancelButton.label
          </Button>
          <Button
            defaultText="Delete"
            className={styles.modalDeleteAllOrderDocumentsButton}
            onClick={() => {
              onDeleteAllOrderDocuments(currentOrderId);
              setCurrentOrderId(null);
              setShowDeleteAllOrderDocumentsConfirmation(false);
            }}
          >
            Translation.Feature.OrderDashboard.DeleteAllOrderDocuments.Modal.SaveButton.label
          </Button>
        </div>
      </Modal>
      <Modal
        isOpen={showUpdateTranslation}
        title="Translation.Feature.OrderDashboard.UpdateTranslation.Modal.title"
        defaultTitle="Add document"
        className={styles.updateTranslationModal}
        hideOnBackDropClick={false}
      >
        <div
          className={clsx(
            styles.updateTranslationModalBackdrop,
            updatingTranslationFile && styles.updateTranslationModalBackdropEnabled
          )}
        >
          <Loader
            className={clsx(
              styles.updateTranslationModalLoader,
              updatingTranslationFile && styles.updateTranslationModalLoaderEnabled
            )}
          />
        </div>
        <div
          className={clsx(
            styles.updateTranslationModalContent,
            updatingTranslationFile && styles.updateTranslationModalContentDimmed
          )}
        >
          <Label
            bold
            defaultText="Select original file"
            className={styles.updateTranslationFileLabel}
          >
            Translation.Feature.OrderDashboard.UpdateTranslation.Modal.SelectOriginalFileLabel
          </Label>
          <Select
            options={currentOrderSourceDocuments}
            onChange={(value) => {
              setUpdateTranslationFileInformation({
                ...updateTranslationFileInformation,
                originalFileOption: value,
                languageOption: null,
              });
            }}
            value={updateTranslationFileInformation?.originalFileOption}
            className={styles.updateTranslationFileOriginalFileSelect}
          />
          <Label bold defaultText="Select translation language">
            Translation.Feature.OrderDashboard.UpdateTranslation.Modal.SelectLanguageLabel
          </Label>
          <Select
            options={currentOrderSourceDocumentTargetLanguages}
            onChange={(value) => {
              setUpdateTranslationFileInformation({
                ...updateTranslationFileInformation,
                languageOption: value,
              });
            }}
            value={updateTranslationFileInformation?.languageOption}
          />
          <div className={styles.modalFooterContainer}>
            <div className={styles.browseFileContainer}>
              <Button
                defaultText="Browse"
                className={styles.browseButton}
                disabled={
                  !(
                    updateTranslationFileInformation?.originalFileOption &&
                    updateTranslationFileInformation?.languageOption
                  )
                }
                onClick={() => {
                  inputFile.current.click();
                }}
                secondary
              >
                Translation.Feature.OrderDashboard.UpdateTranslation.Modal.BrowseButton.label
              </Button>
              {updateTranslationFileInformation?.updateFile ? (
                <B2 bolder className={styles.updateTranslationFile} translate={false}>
                  {intl.formatMessage({
                    id:
                      'Translation.Feature.OrderDashboard.UpdateTranslation.Modal.SelectedFile.label',
                    defaultMessage: 'Selected file',
                  })}
                  : {updateTranslationFileInformation.updateFile.name}
                </B2>
              ) : (
                <B2
                  bolder
                  className={styles.updateTranslationFile}
                  defaultText="No translation file selected"
                >
                  Translation.Feature.OrderDashboard.UpdateTranslation.Modal.NoTranslationFileSelected.label
                </B2>
              )}
            </div>

            <div className={styles.updateTranslationFileModalButtonContainer}>
              <input
                type="file"
                ref={inputFile}
                style={{ display: 'none' }}
                onChange={(event) => {
                  setUpdateTranslationFileInformation({
                    ...updateTranslationFileInformation,
                    updateFile: event.target.files[0],
                  });
                }}
              />
              <Button
                defaultText="Cancel"
                onClick={() => {
                  setUpdatingTranslationFile(false);
                  setUpdateTranslationFileInformation(null);
                  setCurrentOrder(null);
                  setShowUpdateTranslation(false);
                }}
              >
                Translation.Feature.OrderDashboard.UpdateTranslation.Modal.CancelButton.label
              </Button>
              <Button
                defaultText="Add"
                className={styles.updateTranslationFileAddButton}
                disabled={
                  !(
                    updateTranslationFileInformation?.originalFileOption &&
                    updateTranslationFileInformation?.languageOption &&
                    updateTranslationFileInformation?.updateFile
                  )
                }
                onClick={() => {
                  setUpdatingTranslationFile(true);
                  onUpdateTranslationFile(
                    currentOrder.id,
                    updateTranslationFileInformation.originalFileOption.value,
                    updateTranslationFileInformation.languageOption.value,
                    updateTranslationFileInformation.updateFile
                  );
                }}
                secondary
              >
                Translation.Feature.OrderDashboard.UpdateTranslation.Modal.AddButton.label
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

OrderDashboard.propTypes = {
  className: string,
  busy: bool.isRequired,
  orders: arrayOf(orderType).isRequired,
  languages: arrayOf(languageType).isRequired,
  filters: arrayOf(translationItemType).isRequired,
  orderStatuses: arrayOf(translationItemType).isRequired,
  onDeleteAllOrderDocuments: func.isRequired,
  canDeleteOrderDocuments: bool.isRequired,
  onUpdateTranslationFile: func.isRequired,
};

OrderDashboard.defaultProps = {
  className: null,
};

export default OrderDashboard;
