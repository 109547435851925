import { FEATURES_ARRAY } from 'assets';
import api from '../api';
import store from '../../store';
import adminActions from './actions';

const adminOrdersEndpoint = 'admin/orders';
const adminSettingsEndpoint = 'admin/settings';
const adminAccountsEndpoint = 'admin/accounts';

const getOrders = () => {
  store.dispatch(adminActions.getOrdersRequest());

  return api
    .get(`${adminOrdersEndpoint}`)
    .then((response) => store.dispatch(adminActions.getOrdersSuccess(response.data)))
    .catch((error) => store.dispatch(adminActions.getOrdersFailed(error)));
};

const getSettings = () => {
  store.dispatch(adminActions.getSettingsRequest());

  return api
    .get(`${adminSettingsEndpoint}`)
    .then((response) => store.dispatch(adminActions.getSettingsSuccess(response.data)))
    .catch((error) => store.dispatch(adminActions.getSettingsFailed(error)));
};

const getAccounts = () => {
  store.dispatch(adminActions.getAccountsRequest());

  return api
    .get(`${adminAccountsEndpoint}`)
    .then((response) => store.dispatch(adminActions.getAccountsSuccess(response.data)))
    .catch((error) => store.dispatch(adminActions.getAccountsFailed(error)));
};

const updateSettings = (settings) => {
  store.dispatch(adminActions.updateSettingsRequest());

  return api
    .put(`${adminSettingsEndpoint}`, settings)
    .then((response) => store.dispatch(adminActions.updateSettingsSuccess(settings)))
    .catch((error) => {
      throw error;
    });
};

const approveOrder = (orderId) => {
  store.dispatch(adminActions.approveOrderRequest());

  return api
    .put(`${adminOrdersEndpoint}/${orderId}/approve`)
    .then((response) => store.dispatch(adminActions.approveOrderSuccess(response.data)))
    .catch((error) => {
      store.dispatch(adminActions.approveOrderFailed());
      throw error;
    });
};

const rejectOrder = (orderId) => {
  store.dispatch(adminActions.rejectOrderRequest());

  return api
    .put(`${adminOrdersEndpoint}/${orderId}/reject`)
    .then((response) => store.dispatch(adminActions.rejectOrderSuccess(response.data)))
    .catch((error) => {
      store.dispatch(adminActions.rejectOrderFailed());
      throw error;
    });
};

const setOrderInvoiced = (orderId) => {
  store.dispatch(adminActions.setOrderInvoicedRequest());

  return api
    .put(`${adminOrdersEndpoint}/${orderId}/invoiced`)
    .then((response) => store.dispatch(adminActions.setOrderInvoicedSuccess(response.data)))
    .catch((error) => {
      store.dispatch(adminActions.setOrderInvoicedFailed());
      throw error;
    });
};

const setOrderPaid = (orderId) => {
  store.dispatch(adminActions.setOrderPaidRequest());

  return api
    .put(`${adminOrdersEndpoint}/${orderId}/paid`)
    .then((response) => store.dispatch(adminActions.setOrderPaidSuccess(response.data)))
    .catch((error) => {
      store.dispatch(adminActions.setOrderPaidFailed());
      throw error;
    });
};

const getOrderDetails = (orderId) => {
  return api.get(`${adminOrdersEndpoint}/${orderId}`);
};

const lockAccount = (accountId) => {
  return api.post(`${adminAccountsEndpoint}/${accountId}/lock`);
};

const unlockAccount = (accountId) => {
  return api.delete(`${adminAccountsEndpoint}/${accountId}/lock`);
};

const deleteAccount = (accountId) => {
  return api.delete(`${adminAccountsEndpoint}/${accountId}`);
};

const changeOrderDocumentStatus = (orderId, documentId, reviewed) => {
  if (reviewed === true) {
    return api.delete(`${adminOrdersEndpoint}/${orderId}/files/${documentId}/reviewed`);
  }

  return api.put(`${adminOrdersEndpoint}/${orderId}/files/${documentId}/reviewed`);
};

const updateAccountFeatures = (accountId, features) => {
  return new Promise((resolve, reject) => {
    try {
      // Add features
      features.forEach((feature) => {
        api.put(`${adminAccountsEndpoint}/${accountId}/feature?name=${feature}`).catch((error) => {
          reject(error);
        });
      });

      // Remove features
      const deletedFeatures = FEATURES_ARRAY().filter((f) => !features.includes(f));

      deletedFeatures.forEach((deletedFeature) => {
        api
          .delete(`${adminAccountsEndpoint}/${accountId}/feature?name=${deletedFeature}`)
          .catch((error) => {
            reject(error);
          });
      });

      resolve();
    } catch (exception) {
      reject();
    }
  });
};

const connectProject = (orderId, projectId) => {
  return api.put(`${adminOrdersEndpoint}/${orderId}/connect/${projectId}`);
};

export {
  getOrders,
  getSettings,
  getAccounts,
  updateSettings,
  approveOrder,
  rejectOrder,
  getOrderDetails,
  lockAccount,
  unlockAccount,
  deleteAccount,
  changeOrderDocumentStatus,
  setOrderInvoiced,
  setOrderPaid,
  updateAccountFeatures,
  connectProject,
};
