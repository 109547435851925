import { createReducer } from '@reduxjs/toolkit';
import configurationActions from './actions';

const initialState = {
  account: {
    busy: false,
    countries: [],
    timezones: []
  },
  quote: {
    busy: false,
    sourceLanguages: [],
    targetLanguages: [],
    translationSubjects: [],
    validation: {
      wordCountLimit: 1000000
    }
  },
  application: {
    busy: false,
    languages: [],
    allTranslations: {},
    currentTranslations: {},
    selectedLanguage: localStorage.getItem('lang') || 'sv'
  },
  order: {
    busy: false,
    orderStatus: [],
    orderFilterStatus: []
  },
  admin: {
    busy: false
  },
  stats: {},
  faq: []
};

const configurationReducer = createReducer(initialState, {
  [configurationActions.getApplicationConfigurationRequest]: (state, action) => {
    const configuration = state;
    configuration.application.busy = true;
  },
  [configurationActions.getApplicationConfigurationSuccess]: (state, action) => {
    const configuration = state;
    configuration.application.busy = false;
    configuration.application.error = null;
    configuration.application.languages = action.payload.languages;
    configuration.application.allTranslations = action.payload.applicationTexts;
    configuration.application.currentTranslations =
      configuration.application.allTranslations[configuration.application.selectedLanguage];
  },
  [configurationActions.getApplicationConfigurationFailed]: (state, action) => {
    const configuration = state;
    configuration.application.busy = false;
    configuration.application.error = action.payload;
    console.log('GET APPLICATION CONFIGURATION FAILED', action.payload);
  },
  [configurationActions.getAccountConfigurationRequest]: (state, action) => {
    const configuration = state;
    configuration.account.busy = true;
  },
  [configurationActions.getAccountConfigurationSuccess]: (state, action) => {
    const configuration = state;
    configuration.account.busy = false;
    configuration.account.countries = action.payload.countries.map(c => {
      return { label: c.translationKey, value: c.value };
    });
    configuration.account.timezones = action.payload.timezones.map(tz => {
      return { label: tz.translationKey, value: tz.value };
    });
    configuration.account.error = null;
  },
  [configurationActions.getAccountConfigurationFailed]: (state, action) => {
    const configuration = state;
    configuration.account.busy = false;
    configuration.account.error = action.payload;
    console.log('GET ACCOUNT CONFIGURATION FAILED', action.payload);
  },
  [configurationActions.getQuoteConfigurationRequest]: (state, action) => {
    const configuration = state;
    configuration.quote.busy = true;
  },
  [configurationActions.getQuoteConfigurationSuccess]: (state, action) => {
    const configuration = state;
    configuration.quote.busy = false;
    configuration.quote.error = null;
    configuration.quote.sourceLanguages = action.payload.sourceLanguages.map(sl => {
      return {
        label: sl.translationKey,
        value: sl.value,
        sorting: sl.sorting
      };
    });
    configuration.quote.targetLanguages = action.payload.targetLanguages.map(tl => {
      return { label: tl.translationKey, value: tl.value, sorting: tl.sorting };
    });
    configuration.quote.translationSubjects = action.payload.translationSubject.map(ts => {
      return {
        label: ts.translationKey,
        value: ts.value,
        defaultTranslation: ts.defaultTranslation
      };
    });
    configuration.quote.validation = action.payload.validation;
  },
  [configurationActions.getQuoteConfigurationFailed]: (state, action) => {
    const configuration = state;
    configuration.quote.busy = false;
    configuration.quote.error = action.payload;
    console.log('GET QUOTE CONFIGURATION FAILED', action.payload);
  },
  [configurationActions.changeLanguage]: (state, action) => {
    const configuration = state;
    configuration.application.selectedLanguage = action.payload;
    configuration.application.currentTranslations =
      configuration.application.allTranslations[action.payload];
    localStorage.setItem('lang', action.payload);
  },
  [configurationActions.getOrderConfigurationRequest]: (state, action) => {
    const configuration = state;
    configuration.order.busy = true;
  },
  [configurationActions.getOrderConfigurationSuccess]: (state, action) => {
    const configuration = state;
    configuration.order.busy = false;
    configuration.order.orderStatus = action.payload.orderStatus;
    configuration.order.orderFilterStatus = action.payload.orderFilterStatus;
    configuration.order.error = null;
  },
  [configurationActions.getOrderConfigurationFailed]: (state, action) => {
    const configuration = state;
    configuration.order.busy = false;
    configuration.order.error = action.payload;
    console.log('GET ORDER CONFIGURATION FAILED', action.payload);
  },
  [configurationActions.getAdminConfigurationRequest]: (state, action) => {
    const { admin } = state;
    admin.busy = true;
  },
  [configurationActions.getAdminConfigurationSuccess]: (state, action) => {
    const configurationState = state;
    configurationState.admin = { ...action.payload, busy: false };
  },
  [configurationActions.getAdminConfigurationFailed]: (state, action) => {
    const { admin } = state;
    admin.busy = false;
    console.log('GET ADMIN CONFIGURATION FAILED', action.payload);
  },
  [configurationActions.getStatsConfigurationSuccess]: (state, action) => {
    const configurationState = state;
    configurationState.stats = action.payload;
  },
  [configurationActions.getFaqConfigurationSuccess]: (state, action) => {
    const configurationState = state;
    configurationState.faq = action.payload;
  }
});

export default configurationReducer;
