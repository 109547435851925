import PropTypes from 'prop-types';

export const {
  shape,
  func,
  bool,
  string,
  node,
  arrayOf,
  number,
  oneOfType,
  instanceOf,
} = PropTypes;

export const selectOptionType = shape({
  label: string.isRequired,
  defaultLabel: string,
  value: oneOfType([string, number]).isRequired,
  translate: bool,
  sorting: number,
});

export const countryType = shape({
  label: string.isRequired,
  value: string.isRequired,
});

export const menuItemType = shape({
  key: string.isRequired,
  name: string.isRequired,
  action: func,
  anchor: string,
});

export const historyType = shape({
  push: func.isRequired,
  replace: func.isRequired,
});

export const locationType = shape({
  search: string,
});

export const accountType = shape({
  busy: bool.isRequired,
});

export const quoteType = shape({
  id: number,
  total: number,
  words: number,
  translationType: string.isRequired,
  message: string,
  delivery_date: string,
});

export const languageType = shape({
  label: string.isRequired,
  defaultLabel: string,
  value: string.isRequired,
});

export const languagesType = arrayOf(languageType);

export const translationDocumentType = shape({
  documentName: string.isRequired,
  link: string,
  language: string.isRequired,
  deliveryDate: instanceOf(Date),
});

export const fileType = shape({
  size: number.isRequired,
  path: string.isRequired,
  lastModified: number.isRequired,
  name: string.isRequired,
});

export const orderActionsType = shape({
  selectQuote: func.isRequired,
  changeTranslationInstructions: func.isRequired,
});

export const timezoneType = shape({
  label: string.isRequired,
  value: string.isRequired,
});

export const translationType = shape({
  id: number.isRequired,
  source: translationDocumentType.isRequired,
  targets: arrayOf(translationDocumentType).isRequired,
});

export const orderType = shape({
  id: number.isRequired,
  wordCount: number.isRequired,
  translations: arrayOf(translationType),
  status: string.isRequired,
  filterStatus: string.isRequired,
  placed: instanceOf(Date).isRequired,
});

export const translationItemType = shape({
  translationKey: string.isRequired,
  value: string.isRequired,
  defaultTranslation: string.isRequired,
});

export const adminSelectOptionType = shape({
  label: string.isRequired,
  defaultLabel: string.isRequired,
  action: func.isRequired,
  value: number.isRequired,
});

export const featureType = shape({
  deleteTranslations: bool.isRequired,
});

export const adminAccountType = shape({
  id: number.isRequired,
  username: string.isRequired,
  accountExpired: instanceOf(Date),
  accountLocked: bool,
  credentialsExpired: bool,
  enabled: bool.isRequired,
  verificationKey: string.isRequired,
  resetPasswordKey: string,
  translationMemory: string,
  timezone: string,
  companyName: string,
  organisationNumber: string,
  customerName: string,
  // address1: string,
  // address2: string,
  // postalCode: string,
  // city: string,
  // country: string,
  admin: bool.isRequired,
  features: featureType.isRequired,
});

export const pricesType = shape({
  currency: string,
  p: number,
  r: number,
  t: number,
});

export const faqType = shape({
  question: shape({
    translationKey: string.isRequired,
    defaultTranslation: string.isRequired,
  }),
  answer: shape({
    translationKey: string.isRequired,
    defaultTranslation: string.isRequired,
  }),
});

export const adminOrderDetailsType = shape({});

export const translationSubjectType = shape({
  value: string.isRequired,
  translationKey: string.isRequired,
  defaultTranslation: string.isRequired,
});

/* Redux state types */
export const sessionStateType = shape({
  busy: bool.isRequired,
  userLoggedIn: bool.isRequired,
  loginFailed: bool.isRequired,
  userFeatures: arrayOf(string),
  accountName: string,
});

export const applicationConfigurationStateType = shape({
  languages: arrayOf(
    shape({
      translationKey: string.isRequired,
      value: string.isRequired,
    })
  ).isRequired,
  selectedLanguage: string.isRequired,
  currentTranslations: shape({}).isRequired,
  busy: bool.isRequired,
});

export const accountConfigurationStateType = shape({
  busy: bool.isRequired,
  countries: arrayOf(countryType).isRequired,
  timezones: arrayOf(timezoneType).isRequired,
  error: string,
});

export const quoteConfigurationStateType = shape({
  busy: bool.isRequired,
  error: string,
  sourceLanguages: arrayOf(languageType).isRequired,
  targetLanguages: arrayOf(languageType).isRequired,
  translationSubjects: arrayOf(shape({})).isRequired,
  validation: shape({
    wordCountLimit: number.isRequired,
  }),
});

export const orderConfigurationStateType = shape({
  busy: bool.isRequired,
  orderStatus: arrayOf(translationItemType).isRequired,
  orderFilterStatus: arrayOf(translationItemType).isRequired,
});

export const adminConfigurationStateType = shape({
  busy: bool.isRequired,
  orderStatus: arrayOf(translationItemType).isRequired,
});

export const currentOrderStateType = shape({
  busy: bool.isRequired,
  quotes: arrayOf(quoteType).isRequired,
  getQuotesCriteria: shape({
    sourceLanguage: languageType,
    targetLanguages: arrayOf(languageType),
    wordCount: number,
  }).isRequired,
  selectedQuote: quoteType,
  documents: arrayOf(fileType).isRequired,
  reference: string,
});

export const existingOrdersStateType = shape({
  busy: bool.isRequired,
  orders: arrayOf(orderType).isRequired,
});

export const orderStateType = shape({
  currentOrder: currentOrderStateType.isRequired,
  existingOrders: existingOrdersStateType.isRequired,
});

export const adminOrderStateType = shape({
  busy: bool.isRequired,
  orders: arrayOf(shape({})).isRequired, // TODO Fix shape
});

export const adminAccountStateType = shape({
  busy: bool.isRequired,
  accounts: arrayOf(adminAccountType).isRequired, // TODO Fix shape
});

export const adminMailSettingsType = shape({
  apiKey: string.isRequired,
  fromMail: string.isRequired,
  loginUrl: string.isRequired,
  verificationUrl: string.isRequired,
  welcomeTemplate: string.isRequired,
  registrationTemplate: string.isRequired,
});

export const adminRewordSettingsType = shape({
  priceMarkup: number.isRequired,
  deliveryMarkup: number.isRequired,
});

export const adminTranslationSettingsType = shape({
  sandbox: bool.isRequired,
  baseUrl: string.isRequired,
  user: string.isRequired,
  password: string.isRequired,
});

export const adminSettingsStateType = shape({
  busy: bool.isRequired,
  mail: adminMailSettingsType,
  reword: adminRewordSettingsType,
  translation: adminTranslationSettingsType,
});

export const statsConfigurationStateType = shape({
  prices: pricesType,
  customers: number,
  projects: number,
  words: number,
});

/* ***************** */
