import React from 'react';
import { H2 } from 'components';
import styles from './NotFoundPage.module.scss';

function NotFoundPage() {
  return (
    <div className={styles.root}>
      <H2 bolder upperCase className={styles.notFoundLabel} defaultText="Page not found">
        Translation.Pages.PageNotFound.Header.label
      </H2>
    </div>
  );
}

export default NotFoundPage;
