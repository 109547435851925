import React, { useState, useEffect } from 'react';
import { string, bool, node, func } from 'types';
import clsx from 'clsx';
import styles from './HamburgerMenu.module.scss';

function HamburgerMenu(props) {
  const { className, useDefault, children, open, onToggle } = props;
  const [menuOpen, setMenuOpen] = useState(open);
  const classNames = clsx(className, styles.root);

  useEffect(() => {
    setMenuOpen(open);
  }, [open]);

  const _onToggle = () => {
    if (onToggle) {
      onToggle();
    } else {
      setMenuOpen(!menuOpen);
    }
  };

  return (
    <div className={classNames}>
      <div
        className={clsx(styles.barContainer, menuOpen && styles.change)}
        role="presentation"
        onClick={_onToggle}
      >
        <div className={clsx(useDefault ? styles.bar1_primary : styles.bar1_secondary)} />
        <div className={clsx(useDefault ? styles.bar2_primary : styles.bar2_secondary)} />
        <div className={clsx(useDefault ? styles.bar3_primary : styles.bar3_secondary)} />
      </div>
      <div className={clsx(styles.menuContainer, menuOpen && styles.menuOpen)}>
        <div className={styles.menuContentContainer}>{children}</div>
      </div>
    </div>
  );
}

HamburgerMenu.propTypes = {
  className: string,
  useDefault: bool,
  children: node,
  open: bool,
  onToggle: func
};

HamburgerMenu.defaultProps = {
  className: null,
  useDefault: false,
  children: null,
  open: false,
  onToggle: null
};

export default HamburgerMenu;
