import React from 'react';
import { H3 } from 'components';
import { AdminOrders } from 'features';
import styles from './AdminOrdersPage.module.scss';

function AdminOrdersPage() {
  return (
    <div className={styles.root}>
      <H3 className={styles.header} bold upperCase defaultText="Orders">
        Translation.Page.AdminOrders.header
      </H3>
      <AdminOrders />
    </div>
  );
}

export default AdminOrdersPage;
